/** Programs */
import { container, append } from '@/common/helperUtils';

const appendName = append;

export default [
  {
    path: '',
    name: 'startup',
    redirect: {
      path: '/contest'
      // path: 'apply',
      // query: {
      //   with: 'cover', // Cover 표시
      // },
    },
  },
  /** Programs > kstartup 웹 공모 지원하기 */
  {
    path: 'apply',
    redirect: {
      path: '/contest'
    }
    // meta: {
    //   layout: container,
    //   title: 'K - STARTUP TO THE WORLD',
    // },
    // component: () => import(`@/views/programs/${appendName}Apply.vue`),
  },
  /** Programs > kstartup 웹 공모 지원하기 */
  {
    path: 'applyweb',
    redirect: {
      path: '/contest'
    }
    // meta: {
    //   layout: container,
    //   title: 'K - STARTUP TO THE WORLD',
    // },
    // component: () => import(`@/views/programs/${appendName}Apply.vue`),
  },
  // {
  //   path: 'applymboileDetail',
  //   name: 'applymboileDetail',
  //   meta: {
  //     layout: container,
  //     title: 'K - STARTUP TO THE WORLD',
  //   },
  //   props: true,
  //   component: () => import(`@/views/programs/${appendName}ApplymboileDetail.vue`),
  // },
];
