/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  alert: {
    txt01: 'Required values were reset due to page loading. <br> You will be directed to the initial screen.',
    txt02: 'Temporarily saved.',
    txt03: 'An error occurred while saving data.',
    txt04: 'Do you want to cancel your application?',
    txt05: 'Business name is required.',
    txt06: 'Business name in English is required.',
    txt07: 'Year of Establishment is required.',
    txt08: 'Month of Establishment is required.',
    txt09: 'Current number of employees is required.',
    txt10: 'Number of new employees to be hired is required.',
    txt11: 'CEO is required.',
    txt12: 'Member name is required.',
    txt13: 'Employment type is required.',
    txt14: 'Position is required.',
    txt15: 'Major career/educational background is required.',
    txt16: 'There is no Startup Program start date.',
    txt17: 'There is no Startup Program end date.',
    txt18: 'End date cannot be earlier than start date.',
    txt19: 'Service name is required.',
    txt20: 'Service introduction is required.',
    txt21: 'Distinctive service features is required.',
    txt22: 'Business registration attachment is mandatory.',
    txt23: 'Shareholder list attachment is required.',
    txt24: 'Certified copy of registration attachment is required.',
    txt25: 'Venture business registration certificate attachment is required.',
    txt26: 'Intellectual property rights copies attachment is required.',
    txt27: 'Other files attachment is required.',
    txt28: 'Cancelled. You will be directed to the main screen.',
    txt29: 'Creating a new application will delete the saved draft.<br> Do you want to proceed?',
    txt30: 'Cancelled.',
    txt31: '지원영역은 필수 항목입니다.',
    txt32: '신청구분은 필수 항목입니다.',
    txt33: '맴버구성은 필수 항목입니다.',
    txt34: '맴버구성의 팀 인원은 필수입력 항목입니다.',
    txt35: '휴대폰 번호는 필수입력 항목입니다.',
    txt36: 'It is not the application period.',
    txt37: '투자유치 현황은 필수 항목입니다.',
    txt38: '지원경로는 필수 항목입니다.',
    txt39: '대표자 이메일은 필수 항목입니다.',
    txt39_1: '대표자 이메일 정보가 옳바르지 않습니다.',
    txt39_2: '이메일 정보가 옳바르지 않습니다.',
    txt40: '대표자 최종학력은 필수 항목입니다.',
    txt41: '대표자 주요이력은 필수 항목입니다.',
    txt42: '연락처는 필수 항목입니다.',
    txt43: '이메일은 필수 항목입니다.',
    txt44: '지원동기 및 포부는 필수 항목입니다.',
    txt45: '이랜드리테일과의 제휴 아이디어나 제안은 필수 항목입니다.',
    txt46: 'Program participation agreement is required.',
    txt47: '\'{0}\' is required.',
    txt48: '\'{0}\' is required.',
  },
  Common: {
    txt01: 'Healthcare Acceleration Program Qualifications',
    txt02: 'Benefits',
    txt03: 'Accelerating Program',
    txt04: 'Business Development Support',
    txt05: 'Investment and global business development Support',
    txt06: 'Investment Support',
    txt07: 'Network Support',
    txt08: 'How to Apply',
    txt09: '※ Detailed schedule will post separately.',
    txt10: 'Application Submission',
    txt11: 'Application screening',
    txt12: 'Onsite in-person interview',
    txt13: 'Announcement of successful applicants',
    txt14: 'Testimonials',
    txt15: 'If you want to get acceleration support from DREAMPLUS or have questions, please send an e-mail with your business portfolio to <a href="mailto:accelerating@dreamplus.asia">accelerating@dreamplus.asia</a>',
    txt16: 'How to Apply',
    txt17: 'Program',
    txt18: 'Benefits',
    txt19: 'Event Program',
    txt20: 'Application period',
    txt21: 'Announcement of acceptance',
    txt22: 'See more',
    txt23: '해당 항목은 입력할 내용이 없습니다.',
    txt24: '다음 단계로 진행해주세요.',
    txt25: 'See the application',
    txt26: '현재 \'{0}\'에 지원이 불가능합니다.',
    txt27: 'Would you like to apply for \'{0}\'?',
    txt28: 'Do you want to cancel your application?',
    txt29: 'All time',
    txt30: 'Apply',
    txt31: 'Detailed information',
    txt32: 'DREAMPLUS seeks to play a leading role in building a virtuous startup ecosystem',
    txt33: 'with all companies, startups, and partners who want to start open innovation.',
    txt34: 'Start your adventure to achieve your dreams with DREAMPLUS Accelerating Program.',
    txt35: 'Opens on {0}',
    txt36: 'Application Open',
    txt37: 'Application Closed',
    txt38: 'Application Closed',
    txt39: 'Apply for program',
    txt40: 'No Program',
  },
  Fintech: {
    txt01: 'We provide the opportunity to get funding and develop your business<br/> case with the help from Hanwha financial affiliates.<br/> Get the chance to create a synergy effect with the Fintech,<br/> insurance and financial industry.',
    txt01_1: 'We provide the opportunity to get funding and develop your business case with the help from Hanwha financial affiliates. Get the chance to create a synergy effect with the Fintech, insurance and financial industry.',
    txt02: 'Fintech',
    txt03: '&lt;DREAMPLUS Fintech Acceleration Program&gt; provides support for developing<br/> business case linked with Hanwha\'s financial affiliates for startups which can<br/> create a synergy effect with the Fintech, insurance and financial industry. We<br/> provide chosen startups with the opportunities to develop and grow their<br/> <span class="point">businesses with guidance from Hanwha financial affiliates. The startups are<br/> helped with investor funding, networking, global market entry and given access to<br/> DREAMPLUS\' infrastructure (such as office and Media Center).</span>',
    txt03_1: '&lt;DREAMPLUS Fintech Acceleration Program&gt; provides support for developing business case linked with Hanwha\'s financial affiliates for startups which can create a synergy effect with the Fintech, insurance and financial industry. We provide chosen startups with the opportunities to develop and grow their <span class="point">businesses with guidance from Hanwha financial affiliates. The startups are helped with  investor funding, networking, global market entry and given access to DREAMPLUS\' infrastructure (such as office and Media Center).</span>',
    txt04: 'Fintech startups',
    txt05: 'Big data, AI, blockchain, simple payment, stock information and etc.',
    txt06: 'Startups with the technology and services that can create synergy with insurance businesses',
    txt06_1: 'Digital Healthcare, Wellness & Financial Life Innovators',
    txt07: 'Connect with Hanwha affiliates',
    txt08: 'Matching startups with dedicated colleagues from Hanwha\'s financial affiliates',
    txt09: 'Identify business opportunities through regular meetings',
    txt10: 'Test bed',
    txt11: 'Run a pilot project with Hanwha\'s affiliates',
    txt12: 'Infrastructure',
    txt13: 'Support available for select offices at DREAMPLUS 63',
    txt14: 'Gain the opportunity to join the DREAMPLUS 63\'s support programs for business growth',
    txt15: 'Financial support from Hanwha\'s affiliates and outside network of venture capitals',
    txt16: 'Access markets overseas through the GEP (Global Expansion Program), a DREAMPLUS\' support programs for global entry',
    txt17: 'QARA, established in 2014, is',
    txt18: 'a robo-advisor Fintech company that uses financial AI deep-learning technology<br/> to analyze the financial environment in the past and develop and provide solutions<br/> predicting the future.',
    txt19: 'QARA attracted investment from the UK and Korea\'s top venture<br/> capitals. QARA is the Asia-leading deep learning robo-advisor start<br/> up which cooperate with Asian financial companies.',
    txt18_1: 'a robo-advisor Fintech company that uses financial AI deep-learning technology to analyze the financial environment in the past and develop and provide solutions predicting the future.',
    txt19_1: 'QARA attracted investment from the UK and Korea\'s top venture capitals. QARA is the Asia-leading deep learning robo-advisor start up which cooperate with Asian financial companies.',
    txt20: 'Established in 2014, the Institute for Sustainable Development (ISD) is',
    txt21: 'a startup of big data and machine learning. ISD provides service named<br/> "Who\'s Good," a robo-analyst that analyzes business\' non-financial risk. ',
    txt22: 'ISD provides a reliable report about business\' non-financial information<br/> regarding environment, society and governance through AI-based analysis.',
    txt21_1: 'a startup of big data and machine learning. ISD provides service named "Who\'s Good," a robo-analyst that analyzes business\' non-financial risk. ',
    txt22_1: 'ISD provides a reliable report about business\' non-financial information regarding environment, society and governance through AI-based analysis.',
    txt23: 'Investment Cooperation / <br/> Support for Overseas<br/> Business Development',
    txt24: 'The service Hanfa affiliated company <br/> Pilot progress',
    txt25: 'Access markets overseas through the GEP<br/> (Global Expansion Program), a DREAMPLUS\' support programs for global entry',
    txt26: `Kitten Planet is a company providing brushing education service through augmented reality. <br/> <strong> In collaboration with Hanwha Life, we launched a health-promoting insurance product in combination with Brush Monster <br/> Life + Children's Insurance. </strong>
    App has become a hot topic with a new concept health promotion insurance products using digital devices.`,
    txt27: `Milo (Current Routine) is a platform for pre-subscribing to hobby / activity based classes. You can get a lot of information, or you can purchase each class separately without having to purchase a membership separately. <br/>
    <strong> We are collaborating with Hanwha Group to conclude annual contracts for welfare products. </ strong>`,
  },
  Lifestyle: {
    Health: {
      txt01: 'We help with job placement or starting a business in lifestyle sectors and can match you with industry experts who can mentor you.',
      txt02: 'Health care',
      txt03: 'Education',
      txt04: 'F&B',
      txt05: 'Content Creator Academy',
      txt06: 'Fashion & Goods',
      txt07: `&lt;DREAMPLUS Healthcare Acceleration Program&gt; can help you get connected with Hanwha affiliates and other startups in the health care industry.<br>
      The DREAMPLUS Healthcare Acceleration Program is managed by <Synpath DHN>, a professional accelerator. In the program, every startup will be assigned a dedicated accelerator for 6 months to<span class="point">undergo training in health care. Each startup will also be paired with a  specialist who can provide mentorship.</span>`,
      txt08: 'Startups in the digital health care service sector',
      txt09: 'Wellness & Fitness startups',
      txt10: 'Startups with focusing on the technology and services related to healthcare',
      txt11: 'Artificial intelligence, smart health, medical services, and etc.',
      txt12: 'Close support from professional accelerators for 6 months',
      txt13: 'Health care Startups Program : Specialized training, regulations, investment, industry trend and etc.',
      txt14: 'Health care specialist mentors : Synpath DHN, NEWFLIGHT, Hyunjin (licensed) doctors\' advisory group, and etc.',
      txt15: 'DREAMPLUS GANGNAM Select startups will be provided with offices for at least 6 months',
      txt16: 'DREAMPLUS GANGNAM Digital Media Center',
      txt17: 'Opportunity to use Hanwha Group\'s infrastructure as test bed',
      txt18: 'Legal · tax · accounting · PR service',
      txt19: 'DREAMPLUS GEP (Global Expansion Program) provides the opportunity to enter the global market ',
      txt20: 'Investors and dedicated funding',
      txt21: 'Provide the support of linking startups with Hanwha Group\'s support and outside venture capital firms.',
      txt22: 'Demo Day',
      txt23: 'Cooperation with Hanwha Group\'s innovation business',
      txt24: 'Digital Content',
      txt25: 'Digital Content Creator',
    },
    Education: {
      txt01: '&lt;DREAMPLUS Education Acceleration Program&gt; provides support for startups in the edutech and education sectors.<br> We’ve partnered with <Kyowon Group>, the biggest education & culture company in Korea to offer this programs. Each selected startup will be assigned a dedicated accelerator for 6 months. Through the programs, startups can receive support on business cooperation projects ,<span class="point">legal matters, building, PR, entering markets overseas and getting funding from local and overseas venture capital firms.</span>',
      txt02: 'Startups focusing on education can get connected and network with companies within their industry',
      txt03: 'Startups with on/offline education content based on the respect for diversity   and first-hand experience',
      txt04: 'Edutech startups focusing on VR/AR, AI, Robotics, Big Data, and IoT.',
      txt05: 'Startups seeking to disrupt the education industry',
      txt06: 'Assigned accelerators providing close support and guidance',
      txt07: 'Get support for planning and developing cooperation projects (education course, service development and etc.)',
      txt08: 'Get the opportunity of global entry through the GEP (Global Expansion Program), a DREAMPLUS\' support programs for global entry',
      txt09: 'Access to the DREAMPLUS Gangnam Digital Media Center',
      txt10: 'Legal and PR services & support',
      txt11: 'Attract investors and gain funding opportunities through Hanwha (Hanwha Investment & Securities, Hanwha Asset Management), Kyowon (Kyowon Investment) Group and external venture capital firms',
      txt12: 'Networking support through business infrastructure of Hanwha Group and Kyowon Group',
      txt13: 'Networking support through education specialists home and abroad',
    },
    FAndB: {
      txt01: '&lt;DREAMPLUS X Chang Jinwoo F&B Startup Academy&gt;is a specialized training programs designed to nurture entrepreneurs and help startups become successful. <br> This programs offers training on idea/concept planning, storytelling, branding, creating a business name, building an identity/vision, interior design, sound design, business tax & laws, customer service and public relations (PR). The courses in the programs are designed to help entrepreneurs realize their dreams of becoming successful restauranteurs.<span class="point">Be able to attend special lectures given by successful professionals with first-hand experience in different industries.</span>',
      txt02: 'Entrepreneurship for beginning entrepreneurs with dreams of starting a restaurant business',
      txt03: 'Learn through special lectures and workshops on idea/concept formation, storytelling, branding, creating a business name, building an identity, interior, sound, business tax & laws, service and public relations (PR)',
      txt04: 'Start a new business by leveraging the vast resources provided by the Hanwha Group and its affiliates',
      txt05: 'The Global Expansion Program enables you to gain entry into foreign markets',
      txt06: 'Participants can invest in outstanding companies within Chang Jinwoo Co. Ltd. with the education fees they paid for the programs.Chang Jinwoo Co. Ltd., can help startups seeking funding, get connected with outstanding companies that are seeking investment opportunities through the collected education fees',
      txt07: 'Support from Hanwha Group\'s Gourmet 494 and Hotel & Resort business (FC business division)',
      txt08: '(50 applicants will be selected on a first-come-first-serve basis)',
    },
    Contents: {
      txt01: '&lt;DREAMPLUS Travel Content Writer Program&gt;  is an internship for university students interested in the travel industry. This programs is for content creators who can contribute to Hanwha’s corporate social responsibility activities.<br> We cover travel expenses in Korea and give you the opportunity to attend content production and special mentor lectures by renowned professionals and organizations such as Yanolja, Urban Play, Yoon jimin, Chang Jinwoo and others. <span class="point">Student participants will be exempt from the first-round application screening if they apply for the Hanwha Group.</span>',
      txt02: 'Qualifications: any student up to junior year at an accredited university and is enrolled full-time, on a leave of absence, or studying abroad (any major qualifies)',
      txt03: 'University students who enjoy traveling and have great interest in building travel content',
      txt04: 'University students who have keen interests in discovering hidden gems in local areas and developing new travel programs or guide',
      txt05: 'University students with the aptitude for digital content development and design (e.g. video, blog, card news and in various formats) pertaining to travel',
      txt06: 'University students who want first-hand experience in digital content (planning, creating and distribution) in business marketing',
      txt07: 'Attend special/main lectures/workshops on idea/vision creation, storytelling, branding, creating a business name, building an identity, interior, sound, business tax & laws, customer service and public relations (PR)',
      txt08: 'Spend 4 nights and 5 days at picturesque Hanwha Resort locations that can serve as rich content sources',
      txt09: 'Get the opportunity to develop content at the Digital Media Center of DREAMPLUS Gangnam',
      txt10: 'Submission of application and portfolio',
      txt11: '1st Application and portfolio screening',
      txt12: '콘텐츠 크리에이터 프로그램 홍보 영상',
    },
    Fashion: {
      txt01: '&lt;DREAMPLUS Fashion & Goods Acceleration Program&gt; provides a wealth of resources and guidance to help designers realize their dreams of starting a business and break into the Fashion & Goods industry. We provide close support of professional accelerators   for 6 months and training so you can start a business, build products and establish your logistics. <span class="point">We also give you the opportunities to enter the global market, get guidance from our legal team, develop PR, network with venture capitalists, and secure investor funding and IP (patent and trade marks). The F&B Acceleration Program is ideal for:</span>',
      txt02: 'Individuals or teams with unique design ideas for products in the lifestyle (fashion and goods) market',
      txt03: 'University and graduate students, graphic designers, illustrators, character designers and etc.',
      txt04: 'Pre-entrepreneurs seeking to start their own customized designer brand',
      txt05: 'Close support with professional accelerators for 6 months',
      txt06: 'Education programs related to startups',
      txt07: 'Specialized training on production, logistics, marketing, and industry trends',
      txt08: 'Professional mentoring',
      txt09: 'Guidance from professionals in the E-Commerce MD (Timon, GS Shop), Fashion business and relevant sectors',
      txt10: 'Access support for planning and business development through cooperation projects (developing products and securing logistics channels)',
      txt11: 'Gain the opportunity to enter the global market through the GEP(Global Expansion Program), a DREAMPLUS\' support programs for global entry',
      txt12: 'Access the resources at the DREAMPLUS Gangnam\'s Digital Media Center',
      txt13: 'Access our legal (for trade mark and IP) and PR support services',
      txt14: 'Be able to deliberate for investment with the Hanwha Group, Fashion & Goods businesses and outside venture capitalists',
      txt15: 'Networking support to link the business infrastructure with Hanwha Group and big logistic chains',
      txt16: 'Network with Fashion & Goods specialists locally and abroad',
      txt17: '패션&굿즈 프로그램 소개 및 커스텀 장비 참조 영상',
    },
  },
  Corporate: {
    txt01: 'We provide fast, effective and efficient support for open innovation.',
    txt02: 'Corporate Acceleration Program',
    txt03: `&lt;DREAMPLUS Business Acceleration Program&gt; helps businesses promote open innovation in a quickly and efficiently.<br> We provide programs tailored to our partners' purpose. We run the entire acceleration process from startup recruiting to nurturing and growth. Participant companies can benefit from DREAMPLUS' know-how, networking power locally and abroad, and gain access to a pool of outstanding startups. DREAMPLUS can help companies get connected into the
    <span class="point">ecosystem of the future more easily and successfully than by relying on their own acceleration programs.</span>`,
    txt04: 'Internalization of accelerating capability in a short period of time',
    txt05: 'DREAMPLUS is a leading business accelerator in Korea, and selected as the no. 1 preferred investor for early investment. DREAMPLUS leverages information and know-how to provide a tailored acceleration programs specific to the  needs and characteristics.',
    txt06: 'Save on rent',
    txt07: 'Save on rent expense (including the security deposit and the cost to furnish your space), which is lower than   that of a space with the equivalent size',
    txt08: 'Free use of to other facilities (Event Hall and Media Center)',
    txt09: 'Sourcing a promising startup an identifying the opportunity of business cooperation   ',
    txt10: 'The entire recruitment process from screening to the final selection to find startups with the right fit for open innovation',
    txt11: 'Get the opportunity to work with established companies and other startups through the DREAMPLUS community',
    txt12: 'Company<span>(partner)</span>',
    txt13: 'Request enrollment into the startup acceleration programs',
    txt14: 'Rent office space for acceleration at DREAMPLUS Gangnam',
    txt15: 'DREAMPLUS',
    txt16: 'Program for business-tailored acceleration',
    txt17: 'Announcement for when recruitment begins',
    txt18: 'Selected<span>startups</span>',
    txt19: 'Moving into the DREAMPLUS Gangnam office spaces',
    txt20: 'Participating in the business acceleration programs',
    txt21: 'Identifying opportunities for new business development, cooperation, and investment',
    txt22: 'E-Land is a logistics and fashion company.',
    txt23: 'For open innovation, the company works together with DREAM PLUS to identify start ups in various fields including big data, image recognition and product recommendation and provide them training for 6 months. E-Land has been engaged in cooperation and investment projects with new business of E-Land Mall. ',
    txt24: '(July 2018 to Jan 2019)',
  },
  Gep: {
    txt01: 'At the Chungnam Center   for Creative Economy and Innovation (CCCEI), we offer a 3-month-long support to startups so they can prove out their business cases in overseas countries.',
    txt02: 'GEP(Global Expansion Program)',
    txt03: '&lt;DREAMPLUS GEP Program&gt; provides a 3-month-long support for startups to develop their business overseas, along with the CCCEI. <br> We offer the selected companies with <span class="point"> networking support</span> with local partners through Hanwha DREAMPLUS and local accelerators. We also provide support for living expenses during the business trip and <span class="point">local office space if necessary.</span>',
    txt04: 'Living expenses   including the cost of a local   business flights ',
    txt05: 'Expense support for meals, accommodations and flights (up to 2 million KRW)',
    txt06: 'Local office space',
    txt07: 'China, Vietnam',
    txt08: 'Support of the entire recruitment process from screening to making final selection of startups that exhibit the greatest potential for open innovation',
    txt09: 'Creating the opportunity to cooperate with other startups and successful companies within the DREAMPLUS community',
  },
  Tenant: {
    Gangnam: {
      txt01: 'We offer an acceleration programs tailored to every DREAMPLUS tenant',
      txt02: 'DREAMPLUS Gangnam',
      txt03: 'DREAMPLUS 63 Fintech Center',
      txt04: 'Accelerating programs',
      txt05: 'DREAMPLUS provides operational support through its <span class="point">acceleration programs. It leverages its infrastructure and network to set businesses up for success and then help them </span> grow faster.<br> We serve as a startup ecosystem hub that puts the growth of tenants first by providing various opportunities to network and access investment support.',
      txt06: 'Inquire with the Acceleration programs contact person after moving in with DREAMPLUS',
      txt07: 'Applying Online',
      txt08: 'We support business networking and partnerships between our tenants and companies in the business sector of interest. We as also create investment funding opportunities by connecting startups to potential investors.<br> We offer the opportunity for mutual growth through networking and cooperation between the tenants of DREAMPLUS.',
      txt09: 'Various partners in law, accounting and patenting  provide customized consulting in sales, HR, business development and law. As tenants of DREAMPLUS, you get preferential treatment and access to privileged resources.',
      txt10: 'We provide PR and marketing services through the group channel DREAMPLUS Portal and other various channels.',
      txt11: 'Program to gain specialized training and knowledge sessions to share ideas and information on key topics across sectors like healthcare, education, leisure, and finance.',
      txt12: 'We invite tenants and partners to regularly meet in informal networking events.',
      txt13: 'We offer casual lunch meetings or tea time with celebrities and specialists from various industries to help tenants gain insight and network.',
    },
    Square: {
      txt01: 'Incubation Program',
      txt02: 'We offer <span class="point">free office spaces and various support programs to help you grow</span> your startup. We will also help you create synergy within the fintech and insurance industry.',
      txt03: 'Separate notice upon recruitment',
      txt04: '63 Building 6F DreamPlus 63 Fintech Center Office space is provided free of charge.',
      txt05: 'We provide support for market entry overseas with assistance from legal, IP, tax, accounting and PR specialists. You’ll also get AWS credit and discount on Apple Korea\'s products.',
      txt06: 'We assign a dedicated accelerator to provide you close support and give you the opportunity to cooperate and network with Korea\'s top partner companies.',
      txt07: 'We provide opportunities to attract investors and funding locally and abroad.',
    },
  },
  Academy: {
    txt01: 'DREAMPLUS offers unique on/offline education content to help you keep up with the rapidly changing technologies and trends.',
    txt02: 'DREAMPLUS Blockchain Academy',
    txt03: '6-week-long course: All about blockchain from basic 101 to development. Free classes you can take anytime and anywhere. Take Blockchain at Berkeley, a popular blockchain training course with Korean subtitles and the opportunity to directly communicate with lecturers!',
    txt04: 'Attendees and Benefits',
    txt05: 'Courses on the technology of bitcoin,',
    txt05_1: 'blockchain,',
    txt05_2: 'and derivative business',
    txt06: 'A to Z courses',
    txt06_1: 'for future blockchain developers',
    txt07: 'We offer offline events for online students',
    txt07_1: 'and a boot camp for developers.',
    txt08: 'Cryptography& Economics',
    txt09: 'Bitcoin & Blockchain Technology',
    txt10: 'Blockchain Business',
    txt11: 'Protocol and Consensus Overview and Bitcoin History',
    txt12: 'Bitcoin Mechanics and Optimizations and Bitcoin IRL',
    txt13: 'Ethereum Smart Contracts & Game Theory',
    txt14: 'Cryptoeconomics and Alternative Consensus Mechanisms',
    txt15: 'Real-World Applications Scalability',
    txt16: 'Altcoins & Advanced Pics',
    txt17: 'Solidity language learning',
    txt18: 'Developing Smart Contract',
    txt19: 'dApps & Web3.0',
    txt20: 'Ethereum Mechanics and Smart Contracts',
    txt21: 'Solidity & Smart Contract Security',
    txt22: 'Tokens and Smart Contract Architecture & Web3',
    txt23: 'Web 3.0 and IPFS',
    txt24: 'Urbit Project',
    txt25: 'Lightning Network & Cosmos',
    txt26: 'Instructors',
    txt27: 'Blockchain at Berkeley is one of the first associations in US universities to adopt and spread blockchain.The members include Berkeley students, alumni, community members, and blockchain enthusiasts from all educational and industrial backgrounds.Since 2015, it is dedicated to creating blockchain communities in greater East Bay crypto.',
    txt28: 'Blockchain at Berkeley is expanding its business into education, consulting, research and development.They work with companies such as ExxonMobil and Qualcomm to develop strategic approaches to implement blockchain technologies.They are specialized in education from open-source undergraduate cyptocurrency courses to the largest crypto meetup in the East Bay.',
    txt29: 'Signing up for class',
    txt30: 'Blockchain Academy',
    txt31: 'DREAMPLUS Travel Creator',
  },
  Scaleup: {
    txt01: '<span class=\'hanwa-tit3\'></span> affiliate collaboration programs',
  },
  academy: {
    Common: {
      txt01: 'Apply',
      txt02: 'Company information',
      txt03: 'Member information',
      txt04: 'Key services',
      txt05: 'Investment record',
      txt06: 'Enter other',
      txt07: 'Check my application',
      txt08: 'is mandatory.',
      txt09: 'Applying for accelerating programs',
      txt10: 'Phone number of representative',
      txt11: 'See the application',
      txt12: 'Enter application',
    },
    blockchain: {
      txt01: 'All about blockchain, from high-level view to blockchain development within 6 weeks',
      txt02: 'Study free online courses at your convenience - Wherever you are, Whenever you like',
      txt03: 'Well known lectures from ‘Blockchain at Berkeley’ with korean subtitles! Have active communication with lecturers!',
      txt04: `All about blockchain, from high-level view to blockchain development within 6 weeksStudy free online courses at your convenience - Wherever you are, Whenever you like
      Well known lectures from ‘Blockchain at Berkeley’ with korean subtitles! Have active communication with lecturers!`,
      txt05: 'This programs is designed to increase your understanding of the blockchain ',
      txt06: 'and foster professional developers.',
      txt07: 'Anyone can take an online course for free of charge.',
      txt08: 'Now Available',
      txt09: 'Introduction to dApp development and hands-on practice',
      txt10: 'The students would be able to learn different protocols and their features.',
      txt11: 'We provide a hands-on experience on developing and testing Ethereum dApps on ML BPaaS.',
      txt12: 'The students would be able to learn different protocols and their features. We provide a hands-on experience on developing and testing Ethereum dApps on ML BPaaS.',
      txt13: 'Title and introduction by course',
      txt14: 'Blockchain Technology and Distributed Ledger Technology',
      txt15: `For the first week, we will be looking at the core of Blockchain Technology. Let’s start at what blockchain is and how it works.
              It is to help you understand consensus algorithm and how blockchain is different from distributed ledger technology.`,
      txt16: 'Blockchain Use Cases and features of different blockchain protocols',
      txt17: 'There are discussions around how blockchain can be used in the real-world. We will be looking at its most commonly talked about use cases in this week. Here, we can look into different protocols for different use cases according to their features.',
      txt18: 'Introduction of Ethereum dApp and getting familiar with ML BPaaS',
      txt19: 'This week, we aim to prepare you before starting a hands-on experience in developing an Ethereum dApp. Together, we will explore different tools and features of ML BPaaS you can use to develop in cloud environment.',
      txt20: 'Developing and testing Ethereum Smart Contracts',
      txt21: `The Solidity language is the most widely used programming language to develop software for the Ethereum Virtual Machine.
              The software, also known as Smart Contracts are enforced by the logical steps in its programming design and secured by cryptography on the Ethereum system.`,
      txt22: 'Develop and test a Web app using Node.js or Spring Boot',
      txt23: 'The Web is an open source project that has brought immense value to our modern world. Understanding the basic tools necessary to bring a WebApp alive and distribute it through the internet is a key element in our path to develop smart distributed applications.',
      txt24: 'Integrate Web App with smart contracts',
      txt25: 'Smart Contracts live in a distributed system, in thousands of nodes all around the globe. But the way to accessing and interacting with them must be made simple and intuitive for newcomers in this space to discover its potential.',
      txt26: 'Test and deploy dApps. Identify your next steps (MCQ quiz included)',
      txt27: 'Before we deploy, making sure that our applications are secure and understanding its strengths and weaknesses is an integral part of the development process. As it is the last week, we provide you with a set of MCQ questions as well.',
      txt28: 'For more information about the Blockchain Academy,',
      txt29: 'please contact',
      txt30: 'Morpheus Labs Lecture Point',
      txt31: 'Various Protocols',
      txt32: 'Understanding Distributed',
      txt33: 'Ledger Technology and features of',
      txt34: 'different blockchains',
      txt35: 'Development Environment',
      txt36: 'Offering a cloud dApp',
      txt37: 'development environment',
      txt38: 'without setting up nodes',
      txt39: 'Hands-on experience',
      txt40: 'Program that allows you to test',
      txt41: 'and develop a dApp',
      txt42: 'Morpheus Labs Course curriculum',
      txt43: 'Blockchain Technology and Distributed Ledger Technology',
      txt44: 'Blockchain Use Cases and features of different blockchain protocols',
      txt45: 'Introduction of Ethereum dApp and getting familiar with ML BPaaS',
      txt46: 'Developing and testing Ethereum Smart Contracts',
      txt47: 'Develop and test a Web app using Node.js or Spring Boot',
      txt48: 'Integrate Web App with smart contracts',
      txt49: 'Test and deploy dApps. Identify your next steps',
      txt50: 'Morpheus Labs introduction',
      txt51: 'Morpheus Labs BPaaS is designed especially for developers and ',
      txt52: 'enterprises who wish to save time and costs to develop, test and ',
      txt53: 'runblockchain business applications. By using our platform,you get ',
      txt54: 'environment, App Library for quickdeployments, and fail-safe lab setting to  ',
      txt55: 'test drive your ideas. Let our platform do the heavy lifting while you ',
      txt56: 'focus on value-adding work like application creation and experiment with the blockchain technologies at a fraction of the cost and time.',
      txt57: 'Morpheus Labs BPaaS is designed especially for developers and enterprises who wish to save time and costs to develop, test and run blockchain business applications. By using our platform, you get unparalleled benefits from a pre-configured collaborative development environment, App Library for quick deployments, and fail-safe lab setting to test drive your ideas. Let our platform do the heavy lifting while you focus on value-adding work like application creation and experiment with the blockchain technologies at a fraction of the cost and time.',
      txt58: 'R3 Corda Key Concept',
      txt59: 'Corda is an open source blockchain platform that is built to record and manage contracts between mutually distrusting parties.',
      txt60: 'Corda was built by R3 in collaboration with the world’s largest financial institutions. It was designed to meet the rigorous requirements imposed by',
      txt61: 'financial service regulators, conform to industry standards, and deliver on the promise of blockchain technology.',
      txt61_1: 'Corda is an open source blockchain platform that is built to record and manage contracts between mutually distrusting parties. Corda was built by R3 in collaboration with the world’s largest financial institutions. It was designed to meet the rigorous requirements imposed by financial service regulators, conform to industry standards, and deliver on the promise of blockchain technology.',
      txt62: 'Title and introduction by course',
      txt63: 'Corda Setup',
      txt64: 'In this course we discuss how to set up Corda for Windows or Mac.',
      txt65: 'The Corda Ledger',
      txt66: 'The first in a series of courses which explains Corda from the ground up.',
      txt66_1: 'In this webinar we take a high level view of a Corda network and how data is shared around the network.',
      txt66_2: `The first in a series of courses which explains Corda from the ground up.
              In this webinar we take a high level view of a Corda network and how data is shared around the network.`,
      txt67: 'Corda States',
      txt68: 'In the previous course we mentioned that the ledger maintains sets of "shared facts". In this webinar we introduce',
      txt68_1: 'states as immutable objects which represent shared facts such as an agreement or contract at a specific point in time.',
      txt68_2: `In the previous course we mentioned that the ledger maintains sets of "shared facts". In this webinar we introduce
              states as immutable objects which represent shared facts such as an agreement or contract at a specific point in time.`,
      txt69: 'Transactions',
      txt70: 'In this course we discuss transactions which are used to facilitate atomic updates to the ledger.',
      txt70_1: 'We discuss the differences between the Corda transaction model and other DLTs.',
      txt70_2: `In this course we discuss transactions which are used to facilitate atomic updates to the ledger.
              We discuss the differences between the Corda transaction model and other DLTs.`,
      txt71: 'Contracts',
      txt72: 'In this course we discuss how the contract code is used to control the evolution of',
      txt72_1: 'on-ledger states.',
      txt72_2: 'In this course we discuss how the contract code is used to control the evolution of on-ledger states.',
      txt73: 'Commands',
      txt74: 'In this course we discuss commands which are used to parameter transactions and hint to their intention.',
      txt75: 'Timewindows',
      txt76: 'In this course we discuss Timewindows which are used to assert whether a transaction happened before,',
      txt76_1: 'after or between a given period in time.',
      txt76_2: `In this course we discuss Timewindows which are used to assert whether a transaction happened before,
              after or between a given period in time.`,
      txt77: 'Attachments',
      txt78: 'In this course we discuss attachments which are arbitary files that can be attached to a Corda transaction.',
      txt78_1: 'They may contain contract code or static data required for verification.',
      txt78_2: `In this course we discuss attachments which are arbitary files that can be attached to a Corda transaction.
              They may contain contract code or static data required for verification.`,
      txt79: 'Flows',
      txt80: 'In this course we discuss why the flow framework is needed, how it works, look at an example flow to issue',
      txt80_1: 'an IOU from Alice to Bob and cover what functionality is available for CorDapp developers.',
      txt80_2: `In this course we discuss why the flow framework is needed, how it works, look at an example flow to issue
              an IOU from Alice to Bob and cover what functionality is available for CorDapp developers.`,
      txt81: 'Consensus',
      txt82: 'In this course we begin talking about consensus and how it\'s implemented in Corda.',
      txt83: 'Notaries',
      txt84: 'In this course we introduce notaries as nodes on a Corda network which provide uniqueness consensus',
      txt84_1: '(i.e. transaction ordering).',
      txt84_2: `In this course we introduce notaries as nodes on a Corda network which provide uniqueness consensus
              (i.e. transaction ordering).`,
      txt85: 'Oracles',
      txt86: 'In this course we discuss Oracle services which are a means for Corda contracts to reference off-ledger data',
      txt86_1: 'in a controlled (deterministic) manner required for the smart contracts sandbox.',
      txt86_2: `In this course we discuss Oracle services which are a means for Corda contracts to reference off-ledger data
              in a controlled (deterministic) manner required for the smart contracts sandbox.`,
      txt87: 'Corda node, CorDapps and Network',
      txt88: 'In this course we take a high level look at the Corda node, extensions to the node called CorDapps, how the',
      txt88_1: 'components of a CorDapp are used in a typical transaction and lastly we look at how a Corda network is structured.',
      txt88_2: `In this course we take a high level look at the Corda node, extensions to the node called CorDapps, how the
              components of a CorDapp are used in a typical transaction and lastly we look at how a Corda network is structured.`,
      txt89: 'Ontology Blockchain dApp Development',
      txt90: 'The purpose of the ontology blockchain development course is to let beginners quickly learn and master how to develop dApps.',
      txt91: 'At present, 10 development tool introduction videos have been completed. Including the introduction of Ontology’s developer documentation, one-click start private ',
      txt92: `network Solo-chain, command line tool Ontology Cli, smart contract development software Smartx and vscode extension for ontology, dApp development framework
              Punica, different language SDK and dAPI, community developed Chrome Extension Cyano, signature service.`,
      txt93: 'The purpose of the ontology blockchain development course is to let beginners quickly learn and master how to develop dApps.At present, 10 development tool introduction videos have been completed. Including the introduction of Ontology’s developer documentation, one-click start private network Solo-chain, command line tool Ontology Cli, smart contract development software Smartx and vscode extension for ontology, dApp development framework Punica, different language SDK and dAPI, community developed Chrome Extension Cyano, signature service.',
      txt94: 'ONTOLOGY Lecture Point',
      txt95: 'develop guide',
      txt96: 'Introduction how to use',
      txt96_1: 'development tools.',
      txt97: 'Smart contract',
      txt97_1: 'development tool',
      txt98: 'smart contract development software',
      txt99: 'Smartx and vscode extension for ontology',
      txt100: 'will helpful for beginners.',
      txt101: 'Punica is Ontology dApp development',
      txt101_1: 'framework, and has (almost) everything you',
      txt102: 'need to start developing your Ontology',
      txt102_1: 'based dApp.',
      txt103: 'ONTOLOGY',
      txt103_1: 'Course Curriculum',
      txt103_2: 'ONTOLOGY Course Curriculum',
      txt104: 'Ontology Official Document Introduction',
      txt105: 'How to Use Solo-chain',
      txt105_1: 'How to Use Cyano Wallet',
      txt105_2: 'How to Use dAPI',
      txt105_3: 'How to Use Rpc/Restful/Websocket API',
      txt105_4: 'How to Use Ontology CLI (1)',
      txt105_5: 'How to Use VSCode Extension',
      txt105_6: 'How to Use Ontology CLI (2)',
      txt105_7: 'How to Use Ontology Signature Service',
      txt105_8: 'How to Use Punica Suite',
      txt106: 'ONTOLOGY',
      txt106_1: 'Company Introduction',
      txt106_2: 'ONTOLOGY Company Introduction',
      txt107: 'Ontology is a high-performance public blockchain project and a distributed trust collaboration platform.',
      txt108: 'Ontology includes a series of complete distributed ledgers and smart contract systems. Ontology blockchain framework supports public blockchain systems and is able to customize different public blockchains for different applications.',
      txt109: 'Ontology supports collaboration amongst chain networks with its various protocol groups.',
      txt110: 'Ontology will constantly provide common modules on the underlying infrastructure for different kinds of distributed scenarios, such as those for the distributed digital identity framework, distributed data exchange protocol, and so on. Based on specific scenario requirements, Ontology will continue to develop new common modules.',
      txt111: 'Course Curriculum',
      txt112: 'Ontology Official Document Introduction',
      txt113: 'Ontology Official',
      txt114: 'Document Introduction',
      txt115: 'What is Ontology?',
      txt116: 'How to install Ontology',
      txt117: 'Ontology Smart Contract',
      txt118: 'Interface',
      txt119: 'Digit identity and claim',
      txt120: 'SDKs overview',
      txt121: 'Wallet integration',
      txt122: 'How to use dAPI',
      txt123: 'How to Use Solo-chain',
      txt124: 'What is Solo-chain?',
      txt125: 'What is',
      txt126: 'Solo-chain?',
      txt127: 'How to install Solo-chain',
      txt128: 'A step-to-step guide for a Solo-chain demo',
      txt129: 'How to Use Cyano Wallet',
      txt130: 'What is Cyano wallet?',
      txt131: 'How to install Cyano wallet',
      txt132: 'How to use Cyano wallet',
      txt133: 'Create a new ONT ID',
      txt134: 'Process chart of Cyano Wallet, a dAPI provider',
      txt135: 'Step-to-step guide to use Cyano wallet with SmartX',
      txt136: 'How to Use dAPI',
      txt137: 'What is dAPI?',
      txt138: 'OEP-6 architecture',
      txt139: 'A step-to-step guide to install and use dAPI',
      txt140: 'Example of dAPI methods',
      txt141: 'Running the demo project',
      txt142: 'How to Use Rpc/Restful/Websocket API',
      txt142_1: 'How to Use',
      txt142_2: 'Rpc/Restful/Websocket API',
      txt143: 'The introduction of Ontology-API',
      txt144: 'How to use restful API in SmartX',
      txt145: 'How to use Rpc API in Python SDK',
      txt146: 'How to Use Ontology CLI (1)',
      txt147: 'What is Ontology CLI?',
      txt148: 'How to start and manage Ontology nodes',
      txt149: 'Wallet management',
      txt150: 'Asset management',
      txt151: 'How to Use VSCode Extension',
      txt152: 'What is VSCode Extension for Ontology?',
      txt153: 'Extension Settings',
      txt154: 'How to use Ontology extension',
      txt155: 'Valued tips!',
      txt156: 'How to Use Ontology CLI (2)',
      txt157: 'Query information of the blockchain',
      txt158: 'Smart contract deployment and invocation',
      txt159: 'Build, sign, and send a transaction',
      txt160: 'How to Use Ontology Signature Service',
      txt160_1: 'How to Use',
      txt160_2: 'Ontology Signature Service',
      txt161: 'What is Ontology signature service?',
      txt162: 'How to install signature service. By release version or terminal.',
      txt163: 'How to use signature service',
      txt164: 'API structure',
      txt165: 'How to use API methods',
      txt166: 'How to Use Punica Suite',
      txt167: 'What is Punica suite?',
      txt168: 'How to install Punica suite',
      txt169: 'How to initiate a new project with Punica suite',
      txt170: 'key concepts',
      txt171: 'of Corda',
      txt172: 'Build and run',
      txt173: 'CorDapps',
      txt174: 'Become a Certified',
      txt175: 'Corda Developer',
      txt175_1: 'R3 Lecture Point',
      txt176: 'R3 Course curriculum',
      txt177: 'Corda Setup',
      txt178: 'In this course we discuss how to set up Corda for Windows or Mac.',
      txt179: 'The Corda Ledger',
      txt180: 'The first in a series of courses which explains Corda from the ground up. In this webinar we take a high level view of a Corda network and how data is shared around the network.',
      txt181: 'Corda States',
      txt182: 'In the previous course we mentioned that the ledger maintains sets of "shared facts".In this webinar we introduce states as immutable objects which represent shared facts such as an agreement or contract at a specific point in time.',
      txt183: 'Transactions',
      txt184: 'In this course we discuss transactions which are used to facilitate atomic updates to the ledger.We discuss the differences between the Corda transaction model and other DLTs.',
      txt185: 'Contracts',
      txt186: 'In this course we discuss how the contract code is used to control the evolution of on-ledger states.',
      txt187: 'Commands',
      txt188: 'Commands In this course we discuss commands which are used to parameter transactions and hint to their intention.',
      txt189: 'Timewindows',
      txt190: 'In this course we discuss Timewindows which are used to assert whether a transaction happened before, after or between a given period in time.',
      txt191: 'Attachments',
      txt192: 'In this course we discuss attachments which are arbitary files that can be attached to a Corda transaction. They may contain contract code or static data required for verification.',
      txt193: 'Flows',
      txt194: 'In this course we discuss why the flow framework is needed, how it works, look at an example flow to issue an IOU from Alice to Bob and cover what functionality is available for CorDapp developers.',
      txt195: 'Consensus',
      txt196: 'In this course we begin talking about consensus and how it\'s implemented in Corda.',
      txt197: 'Notaries',
      txt198: 'In this course we introduce notaries as nodes on a Corda network which provide uniqueness consensus (i.e. transaction ordering).',
      txt199: 'Oracles',
      txt200: 'In this course we discuss Oracle services which are a means for Corda contracts to reference off-ledger data in a controlled (deterministic) manner required for the smart contracts sandbox.',
      txt201: 'Corda node, CorDapps',
      txt202: 'and Network',
      txt203: '',
      txt204: 'Corda node, CorDapps and Network',
      txt205: '',
      txt206: 'In this course we take a high level look at the Corda node, extensions to the node called CorDapps, how the components of a CorDapp are used in a typical transaction and lastly we look at how a Corda network is structured.',
      txt207: 'R3 Company introduction',
      txt208: 'R3 is an enterprise software firm that builds and maintains Corda,',
      txt209: 'a blockchain platform developed specifically for businesses. privately',
      txt210: 'R3’s vision is of a world where everyone can transact directly and privately',
      txt211: 'without friction; a world where business partners operate in perfect synchrony,',
      txt212: 'and duplicated,inconsistent records are a thing of the past.',
      txt213: 'R3 is an enterprise software firm that builds and maintains Corda, a blockchain platform developed specifically for businesses. R3’s vision is of a world where everyone can transact directly and privately without friction; a world where business partners operate in perfect synchrony, and duplicated,inconsistent records are a thing of the past.',
      txt214: 'Logistics',
      txt215: 'Discussion Leaders',
      txt215_1: 'Cliff Ahn, Gillian Chu, Griffin Haskins, Brian Ho, Alexandra Tran (Blockchain at Berkeley)',
      txt216: 'Class Format ',
      txt216_1: 'Online courses (2 lectures per week recommended, 6-week completion).',
      txt217: 'Prerequisites ',
      txt217_1: 'This course has no formal prerequisites. However, cryptocurrency is very technical in nature, so coming into this course with knowledge of computer science or cryptography will be extremely helpful, although not required.',
      txt218: 'Textbooks',
      txt218_1: 'You will need these books, which are both freely distributed and available online.',
      txt219: 'Curriculum',
      txt220: 'Week',
      txt221: 'Protocol and Consensus: A High Level Overview',
      txt222: 'Bitcoin History',
      txt223: 'Bitcoin Mechanics and Optimizations',
      txt224: 'Bitcoin IRL:',
      txt224_1: 'Walllets, Mining, and More',
      txt224_2: 'Bitcoin IRL:Walllets, Mining, and More',
      txt225: 'Ethereum and Smart Contracts:',
      txt225_1: 'Enabling a Decentralized Future',
      txt225_2: 'Ethereum and Smart Contracts:Enabling a Decentralized Future',
      txt226: 'Game Theory and Network Attacks:',
      txt226_1: 'How to Destroy Bitcoin',
      txt226_2: 'Game Theory and Network Attacks:How to Destroy Bitcoin',
      txt227: 'Cryptoeconomics and Proof-of-Stake',
      txt228: 'Alternative Consensus Mechanisms',
      txt229: 'Enterprise Blockchain:',
      txt229_1: 'Real-World Applications',
      txt229_2: 'Enterprise Blockchain:Real-World Applications',
      txt230: 'Scaling Blockchain:',
      txt230_1: 'Cryptocurrencies for the Masses',
      txt230_2: 'Scaling Blockchain:Cryptocurrencies for the Masses',
      txt231: 'Anonymity:',
      txt231_1: 'Mixing and Altcoins',
      txt231_2: 'Anonymity:Mixing and Altcoins',
      txt232: 'Advanced Topics:',
      txt232_1: 'The People\'s Pick',
      txt232_2: 'Advanced Topics: The People\'s Pick',
      txt233: 'Argue! Debate on one of two topics (check Piazza for submission instructions):',
      txt234: 'Come up with a dApp (decentralized app) idea or blockchain use case! Try to be as creative and specific as possible, and justify the use of a blockchain.',
      txt235: 'Take a side: ',
      txt235_1: 'Proof-of-Work or Proof-of-Stake! Discuss which of the two you prefer and come tell us why! Or if you have some other alternative consensus mechanism you know about, feel free to share why you like that! Here are some questions that you might want to answer: What are some advantages/disadvantages of the consensus protocol that you chose? What cryptocurrencies/altcoins use it? How is different from other consensus protocols that we talked about?',
      txt236: 'Analyze: Take one of the scaling solutions that we discussed in lecture or find a scaling solution that wasn\'t discussed online and analyze the pros and cons of the solution.',
      txt237: 'Design your own cryptocurrency or blockchain platform and justify your design decisions, OR analyze the properties of an existing cryptocurrency or blockchain platform.',
      txt238: 'This course has no formal prerequisites. However, cryptocurrency is very technical in nature, so coming into this course with knowledge of computer science or cryptography will be extremely helpful, although not required.',
      txt239: 'Prerequisites',
      txt240: 'You will need these books, which are both freely distributed and available online.',
      txt241: 'Online courses (2 lectures per week recommended, 6-week completion).',
    },
    CompanyInfo: {
      txt01: 'Company name',
      txt02: 'Company name in Korean',
      txt03: 'Company name in English',
      txt04: 'Business registration number',
      txt05: 'Enter business registration number',
      txt06: 'Corporation registration number',
      txt07: 'Enter corporation registration number',
      txt08: 'Venture company authentication number',
      txt09: 'Enter venture company authentication number',
      txt10: 'Year of establishment',
      txt11: 'Year',
      txt12: 'Month',
      txt13: 'Date',
      txt14: 'Representative',
      txt15: 'Enter representative\'s name',
      txt16: 'co-CEO',
      txt17: 'Enter co-CEO\'s name',
      txt18: 'You can enter the names of up to {0} representatives.',
      txt19: 'Number of current employees ',
      txt20: 'Enter the number of employees',
      txt21: 'Expected number of new additional hires',
      txt22: 'Enter the number of employees',
      txt23: 'Please enter the number of new hires expected within 6 months after moving in DREAMPLUS',
      txt24: 'Website / Social Media',
      txt25: 'Enter Website URL',
      txt26: 'Enter SNS URL',
      txt27: 'Enter Facebook URL',
      txt28: 'Awards',
      txt29: 'Date',
      txt30: 'Enter competition name',
      txt31: 'Enter host institution',
      txt32: 'Enter awards',
      txt33: 'Enter in reverse-chronological order.',
      txt34: 'Intellectual property rights',
      txt35: 'Enter name',
      txt36: 'Enter certificate number',
      txt37: 'Registration',
      txt38: 'Application',
      txt39: 'Registration/Application date',
      txt40: 'License and Registration Status',
      txt41: 'Enter license name',
      txt42: 'Enter license number',
      txt43: 'Types of application',
      txt44: 'Composition of members',
      txt45: 'Individual business',
      txt46: '예비창업자',
      txt47: '개인',
      txt48: 'Team',
      txt49: 'Enter number of team members only',
      txt50: 'Email address of representative',
      txt50_1: 'Enter email address of representative',
      txt51: 'Highest level of education of representative',
      txt51_1: 'Enter highest level of education of representative',
      txt52: 'Major work background of representative',
      txt52_1: 'Enter major work background of representative',
      txt53: 'You are able to enter as many as {0} characters.',
      txt54: 'Number without -',
      txt55: 'Establishment of a U.S. Coporation',
      txt56: 'YES',
      txt57: 'NO',
    },
    MemberInfo: {
      txt01: 'Member',
      txt02: 'Name',
      txt03: 'Full-time',
      txt04: 'Part-time',
      txt05: 'Position',
      txt06: 'Date of birth',
      txt07: 'Experience of participating in startup programs',
      txt08: 'Start date',
      txt09: 'End date',
      txt10: 'Program',
      txt11: 'Awards',
      txt12: 'Benefits',
      txt13: 'Enter the experiences newest to oldest.',
      txt14: 'Mentors',
      txt15: 'Mentor name',
      txt16: 'Major careers of the mentors',
      txt17: 'Cell phone number',
      txt18: 'E-mail',
      txt19: '근무형태',
      txt20: 'Cell phone number without -',
      txt21: 'Man',
      txt22: 'Woman',
      txt23: 'Gender',
      txt24: 'Developer',
      txt25: 'Director',
      txt26: 'Degigner',
    },
    ServiceInfo: {
      txt01: 'Service name ',
      txt02: 'Enter service name',
      txt03: 'Service introduction',
      txt04: 'Enter service introduction',
      txt05: 'You can enter up to {0} characters.',
      txt06: 'Distinctive service features',
      txt07: 'Enter distinctive service features',
      txt08: 'Service URL',
      txt09: 'Enter service URL',
      txt10: 'SNS URL',
      txt11: 'PR video/new article URL',
      txt12: 'Enter PR video/new article URL',
    },
    InvestmentInfo: {
      txt01: 'Status of attracting investment',
      txt02: 'Date of investment',
      txt03: 'Investment agency',
      txt04: 'Investment amount',
      txt05: 'round',
      txt06: 'Enter the investment record newest to oldest.',
      txt07: '최소 지원 자격은 기존 투자 유무와 관계없이 지원 시점에서 법인 설립이 완료된 기업입니다.',
      txt08: '투자이력 없음',
    },
    OtherInfo: {
      txt01: 'Attachment',
      txt02: 'Attach business registration',
      txt03: 'Attach shareholders list',
      txt04: 'Attach certified copy of registration',
      txt05: 'Attach venture company registration',
      txt06: 'Attach copy of intellectual property rights',
      txt07: 'Items for which you want to apply',
      txt08: 'Legal support (various legal reviews, patent application, etc.)',
      txt09: 'Tax/accounting',
      txt10: 'PR/marketing',
      txt11: 'Inspection and support for development',
      txt12: 'Global entry (focusing on Asia)',
      txt13: 'Business support including connection with financial companies',
      txt14: 'Strengthening business models',
      txt15: 'Investment attraction',
      txt16: 'Office space',
      txt17: 'Reasons for application',
      txt18: 'Enter reasons for application',
      txt19: 'Enter (up to 300 characters)',
      txt20: 'Program Participation Agreement',
      txt21: 'I agree to Program Participation Agreement.',
      txt22: '지원영역',
      txt23: 'You can upload up to 10MB in attachments.',
      txt24: '리테일테크',
      txt25: '신규 리테일 컨텐츠',
      txt26: '신규 비즈니스 모델',
      txt27: 'Items to apply for ',
      txt28: '중국',
      txt29: '베트남',
      txt30: '일본',
      txt31: '인도네시아',
      txt32: 'HEALTH MONITORING',
      txt33: 'HEALTH DATA ANALYTICS',
      txt34: 'IN-CAR CARE SOLUTION',
      txt35: 'CONNECTED CARE SERVICE',
      txt36: '운전자 감정/건강 정보 수집',
      txt37: '외부 환경 정보 수집',
      txt38: '식단 분석',
      txt39: '영양소 분석',
      txt40: 'HEART RATE',
      txt41: 'BODY MASS',
      txt42: '혈당측정/분석',
      txt43: '수면 분석',
      txt44: '건강 테라피 콘텐츠',
      txt45: '심리 상담 및 케어 기능',
      txt46: '감정 상태 맞춤형 실내 환경',
      txt47: '장애 보조 실내 기능',
      txt48: '운동/자세 보조 기능',
      txt49: '병원/약국 접근성',
      txt50: '맞춤형 명소 추천/안내',
      txt51: '보험 연계',
      txt52: 'Application path',
      txt53: 'Select application path',
      txt54: 'SNS (Facebook, Instagram, YouTube)',
      txt55: '스타트업 매체 (벤처스퀘어, 온오프믹스, 스타트업위클리, 이벤터스, 센서블박스, 코리아스타트업포럼 등)',
      txt56: '오프라인 포스터 및 드림플러스 홈페이지',
      txt57: '외부추천',
      txt58: '기타',
      txt59: '외부추천사',
      txt590: '공모전 사이트(씽굿 등)',
      txt60: '기타 지원경로',
      txt61: '컨텐츠 비즈니스',
      txt62: '신규 플랫폼',
      txt63: 'Digital Healthcare',
      txt64: 'Wellness',
      txt65: '현대자동차그룹 및 한화 계열사 현업 팀과의 희망 협업 프로젝트를 선택하여 지원 (테마는 1개 선택, 프로젝트는 복수 선택 가능)',
      txt66: '인지기능 스크리닝',
      txt67: '인지기능 트레이닝',
      txt68: '식이요법',
      txt69: '운동요법',
      txt70: 'NFT',
      txt71: '핀테크',
      txt72: '결제',
      txt73: '콘텐츠(IP)',
      txt74: 'DeFi',
      txt75: 'GAME',
      txt76: '물류',
      txt77: '기타',
      txt78: 'DAO',
      txt79: 'SECURITY',
    },

    DisplayInfo: {
      txt01: 'Year',
      txt02: 'Month',
      txt03: 'Date',
      txt04: '',
      txt05: 'Major mentors',
      txt06: '(in 100m)',
      txt07: 'Other',
      txt08: 'Agreement',
      txt09: 'I agree to Program Participation Agreement.',
      txt10: 'I do not agree to the pledge for programs participation.',
      txt11: 'Consent for marketing use',
      txt12: 'I agree to Consent for marketing use.',
      txt13: 'I do not agree to consent for marketing use.',
      txt14: 'Yes',
      txt15: 'No',
      txt16: 'Yes, I do',
    },
    ApplyComplete: {
      txt01: 'Your application has been successfully submitted.',
      txt02: 'Your application has been successfully submitted.',
      txt03: 'You can view your application results with history of competition participation.',
      txt04: 'Confirm',
      txt05: 'You have already applied.<br/>Duplicate application is not possible.',
    },
    ApplyTemporary: {
      txt01: 'You have a draft application registration request. ',
      txt02: 'You may register a new application after completing',
      txt03: 'and registering the draft.',
      txt04: 'New registration',
    },
  },
  Kind: {
    AC101: 'Fintech',
    AC102: 'Lifestyle-Digital Healthcare / Wellness',
    AC103: 'Lifestyle-Education',
    AC104: 'Lifestyle-F&B',
    AC105: 'Academy-Travel Contents Creator',
    AC106: 'Lifestyle-Fashion & Goods Creator',
    AC107: 'Corporate ACC-Eland',
    AC108: 'GEP',
    AC109: 'Tenant ACC-Gangnam',
    AC110: 'Tenant ACC-63',
    AC111: 'Lifestyle-Digital Contents',
    AC112: 'Lifestyle-Digital Contents Creator',
    AC113: 'ScaleUp',
    AC114: 'Corporate ACC-Hyundai',
    AC115: 'Corporate ACC-Eisai',
  },
};
