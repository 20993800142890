/* eslint-disable max-len */
export default {
  Application: {
    btn: {
      txt01: '공모 지원하기',
      txt02: '공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중 공모중',
    },
    Tab: {
      Apply: '공모 지원',
      List: '공모 지원 내역',
    },
  },
  with_hanwha: {
    program_desc: '드림플러스는 스타트업에게는 성장을, 기업에게는 혁신의 기회를, 일반 대중에게는 창ㆍ취업 지원활동 기회를 제공합니다.',
    contact: '문의: ',
    main_program: '주요 프로그램',
    program_benefit: '프로그램 혜택',
    participation_example: '프로그램 참여 사례',
    main: {
      meetup_desc: `스타트업과 현업 담당자의 정기 밋업을 통해 사업협력
      <br />기회를 마련합니다.`,
      poc_desc: `기업 내부의 혁신 니즈 발굴을 통해 실질적인
      <br />POC 기회를 제공합니다.`,
      tenants_desc: `강남센터 입주사를 대상으로 사업협력 및 파트너 연계,
      <br />투자유치 등을 지원합니다.`,
      demoday_title: 'DEMODAY',
      demoday_desc: `드림플러스와 함께한 스타트업을 선발하여 사업연계 및
      <br />투자유치를 위한 IR 피칭 행사를 매년 개최합니다.`,
    },
    benefit: {
      business_desc: `사업협력/PoC 기회 제공 등
      <br />스타트업의 성장을 지원`,
      networking_desc: `드림플러스 데모데이 및 각종
      <br />네트워킹 기회 제공`,
      pr_desc: `다양한 채널을 활용한 홍보,
      <br />마케팅 기회 제공`,
    },
    example: {
      agilesoda: {
        company: '애자일소다',
        participant: '드림플러스 핀테크 프로그램 참여사',
        description: `"어떤 액셀러레이팅 공간보다 쾌적한 강남센터에서 다양한 기회를 얻을 수 있다는 점이 무척 좋았습니다.
        <br />실제로 이를 통해 큰 비즈니스 기회를 얻었구요.
        <br />특히 한화생명과 수행한 인공지능 프로젝트를 통해, 세계 최초로 강화 학습을 활용해 클레임 프로세스를
        <br />개선 할 수 있었습니다.
        <br />애자일 소다를 믿고 새로운 도전의 기회를 제공해 주셔서 너무나 감사합니다."`,
        ceo: '애자일소다 대표 최대우 님',
      },
      petner: {
        company: '펫트너',
        participant: '한화생명 연계 POC 프로그램 참여사',
        description: `"드림플러스를 만난 것은 펫트너에게 상당한 터닝 포인트로 작용 했습니다.
        <br />특히 한화생명, 한화손해보험과 협업할 수 있는 기회를 얻을 수 있는 점이 좋았는데요.
        <br />한화손해보험과 업계 최초로 펫시터 보험을 만들어 도입하기도 했고,
        <br />또 한화생명과 반려견 매칭 서비스를 함께 하면서 금융 등 새로운 비즈니스 영역을 발견할 수 있었습니다."`,
        ceo: '펫트너 대표 최가림 님',
      },
    },
  },
  with_partner: {
    program_desc: `각 영역 전문 파트너와 공동으로 기업들이 오픈이노베이션을 보다 빠르고 효율적으로 추진할 수 있도록,
    <br />기업의 니즈에 부합한 프로그램 설계, 모집, 육성 전 과정을 지원합니다.`,
    partners: '주요 파트너사',
    program_benefit: '프로그램 혜택',
    selection_process: '선발 프로세스',
    participation_example: '프로그램 참여 사례',

    benefit: {
      support_desc: `PoC 기회, 사무공간,
      <br />전담 액셀러레이팅을 제공합니다.`,
      networking_desc: `드림플러스 생태계 참여사와
      <br />각종 네트워킹 기회를 제공합니다.`,
      investment_desc: `국내외 VC 네트워크 기반
      <br />투자 유치 기회를 제공합니다.`,
    },
    process: {
      step01: '서류접수',
      step02: '1차 서류심사',
      step03: '2차 서류심사',
      step04: '선발 발표',
    },
    example: {
      welt: {
        company: '웰트',
        participant: '드림플러스 라이프스타일 액셀러레이팅 프로그램 참여사',
        description: `"프리미엄 코워킹 스페이스를 바탕으로, 최고의 멤버들을 확보하고 근무만족도를 높이며,
        <br />수많은 국내외 클라이언트들과의 딜도 성공시킬 수 있었습니다.
        <br />여기에 금융, 레저, 유통, 방산에 걸친 한화 그룹 계열사들과 스스럼없이 만나 협업 논의를 펼칠 수 있는
        <br />보너스 까지, 그야말로 최고의 환경입니다."`,
        ceo: '웰트 대표 강성지 님',
      },
      korea_startup_forum: {
        company: '코리아스타트업포럼',
        participant: '드림플러스 파트너사',
        description: `"한화 드림플러스는 코리아스타트업포럼의 든든한 파트너입니다!
        <br />코스포의 미션인 '스타트업의 성장을 도와 세상을 혁신하는 일'에 뜻을 모으기로 약속하고,
        <br />창업가를 위한 교육/네트워킹 프로그램을 공동으로 개최하는 등 긴밀하게 협력해오고 있습니다.
        <br />이 과정에서 강남센터에 입주한 다양한 분야의 스타트업들과 교류 기회를 확대할 수 있었고,
        <br />생태계를 구성하는 이해관계자들과 협력포인트를 발굴할 수 있었습니다."`,
        ceo: '코리아스타트업포럼 최성진 대표님',
      },
      rocketview: {
        company: '로켓뷰',
        participant: 'DREAMPLUS X ELAND RETAIL TECH PROGRAM 1기 참여사',
        description: `"이랜드 액셀러레이팅 프로그램 뿐만 아니라 드림플러스에서 열리는 데모데이 참여 기회 제공, 저희 팀의
        <br />특성에 맞는 프로그램 추천 등 전담 매니저님이 물심양면으로 도와주셔서 많은 도움을 받았습니다.
        <br />시너지 효과를 낼 수 있는 팀을 소개 받아 함께 프로젝트를 진행할 수 있는 기회를 만들수 있었고,
        <br />지금도 활발히 진행하고 있습니다."`,
        ceo: '로켓뷰 대표 김화경 님',
      },
      designovle: {
        company: '디자이노블',
        participant: 'DREAMPLUS X ELAND RETAIL TECH PROGRAM 1기 참여사',
        description: `"IR자료 작성에서부터 투자자미팅, 고객사 소개, 여러가지 이벤트나 체험 프로그램 등 스타트업 혼자서는
        <br />할 수 없거나 경험할 수 없는 일까지 챙겨주는 한화 드림플러스 덕분에 더 많은 기회를 얻을 수 있었습니다.
        <br />이랜드 리테일을 통해서 AI 기술을 적용한 패션 상품을 함께 출시할 수 있었고,
        <br />M사와 함께 글로벌 진출도 타진 중입니다. 또한 도전 K스타트업에서 대통령상까지 받는 등,
        <br />한화 드림플러스와 함께하며 성장에 속도가 더욱 붙고 있습니다."`,
        ceo: '디자이노블 대표 신기영 님',
      },
    },
    alumni: {
      lifestyle_01: 'LIFESTYLE 1기',
      lifestyle_02: 'LIFESTYLE 2기',
    },
  },
  internship: {
    internship_desc: `드림플러스는 열린 파트너십을 통해 공공기관-대학교-대기업간 상생 협력구조를 마련하고, 스타트업과 역량있는 인재를 매칭함으로써
    <br />일자리 창출에 기여함은 물론, 건강한 생태계를 위한 Facilitator 역할을 확대하고 있습니다.`,
    youth_tlo: '청년 TLO 스타트업 인턴십',
    youth_tlo_desc: `과학기술 일자리 진흥원과 협력을 통해 청년 TLO 육성사업 운영 대학 졸업생을 대상으로
    <br />장기 스타트업 인턴십 6개월 과정 수시 운영`,
    college_internship: '대학생 스타트업 인턴십',
    selection_process: '선발 프로세스',
    academy_desc: `최신 트렌드에 맞는 주제를 선정하여 해당 영역의 전문 파트너와 함께 기획한, 일반 대중을 위한 다양한 아카데미 프로그램을 운영하고 있습니다.
    <br />이를 통해 개인의 창취업 지원은 물론, 혁신 성장 생태계 참여의 기회를 제공하고 있습니다.`,
    academy_image: '아카데미 현장사진',
    colleges: {
      yonsei: '드림플러스 X 연세대학교',
      yonsei_desc: '스타트업 인턴십 (경영)',
      ewha: '드림플러스 X 이화여자대학교',
      ewha_desc: '스타트업 인턴십 (디자인)',
      hufs: '드림플러스 X 한국외국어대학교',
      hufs_desc: '스타트업 인턴십 (리서치)',
    },
    process: {
      step01: '참여 스타트업 모집',
      step02: '인턴 모집 공고',
      step03: '면접 심사',
      step04: '드림플러스 매칭 지원',
      step05: '최종 선발',
    },
    academy: {
      blockchain_desc: '미래기술 습득 및 전문 개발자 양성(2018)',
      fashion_goods_desc: '디자이너 브랜드 양성 및 지원을 위한 전문 교육(2019)',
      fNb_with: 'with 장진우 아카데미',
      fNb_desc: '요식 분야 자영업자를 위한 예비 창업가 교육(2018)',
      onboarding_desc: '스타트업 앱 마케팅 전문 교육(2019)',
      contents_with: 'with YES24/플래디',
      contents_desc: '콘텐츠 크리에이터 역량 강화를 통한 전문 교육(2019)',
      ios_desc: 'iOS 앱개발자 교육 (2020)',
    },
  },
};
