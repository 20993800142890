/* eslint-disable max-len */
export default {
  common: {
    dreamplus_desc: `꿈을 이루고자 도전하는 이들에게 다양한 기회와 희망을 제공하고, 그 꿈들이 서로 시너지를 이루고 지속 가능한 혁신의 선순환이 될 수 있도록,
    <br />다양한 꿈들을 서로 연결하는 혁신의 촉매제가 되어 더 나은 세상을 만들어가고자 합니다.`,
    signature_desc01: `심볼마크는 역동성과 영원함을 나타내며, 하나의 원이 또 다른 원을 만듭니다.
    <br />새로 만들어진 원은 본래의 원에서 분리되어 점차 독립적으로 성장하여 스스로 자립해 갑니다.`,
    signature_desc02: `두 원은 관계를 유지하며, 각각의 새로운 삶을 만들어 갑니다.
    <br />이는 심볼의 정적인 형태 속에 존재하는 역동성 뿐 아니라 생명의 유기성을 나타냅니다.`,
    innovation_ecosystem_desc: `스타트업 생태계 플레이어 간의 협업과 혁신 그리고 성장을 촉진하는 OPEN INNOVATION HUB이자, BUSINESS ENABLER로서
    <br />구성원간의 연결을 통해 건전한 스타트업 생태계 구축 및 활성화에 기여합니다.`,
    partnership_desc: `업종, 분야, 방식, 규모에 제약을 두지 않고 다양한 영역의 스타트업, 기업 및 기관들과의 열린 파트너십을 통해
    <br />더 건강한 혁신성장 생태계를 만들기 위해 노력하고 있습니다.`,
    btn_brochure: '브랜드소개서 보기',
    btn_brochure_area: '공간소개서 보기',
    btn_domestic: '국내',
    btn_global: '해외',
  },
  provide: {
    centers_desc: '다양한 구성원이 연결되는 이노베이션 센터로 원활한 CONNECTIVITY 구축을 위해 국내는 물론 해외까지 글로벌 거점을 확대하며, 네트워킹을 확장하고 있습니다.',
    programs_desc: '창업 초기, 성장, 확장, 엑싯(Exit)에 이르는 스타트업 생애주기 전 과정에 걸쳐 다양한 액셀러레이팅 프로그램을 지원하고 있습니다.',
    partnership_desc: 'Global Network 기반의 사업확장 및 해외진출을 지원하며, 열린 파트너십을 통해 더 건강한 혁신성장 생태계를 만들기 위해 노력하고 있습니다.',
    collaboration_desc: '기업 및 투자자 연계를 통한 투자기회를 마련하고 사업연계 및 협업 프로젝트를 통한 오픈이노베이션 기회를 제공합니다.',
    events_desc: '스타트업 생태계 구성원 뿐만 아니라 일반 대중이 참여할 수 있는 아카데미와 이벤트를 통해 창·취업 지원활동 및 혁신성장 생태계참여 기회를 제공합니다.',
  },
};
