/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Story: {
    txt01: '',
    txt02: 'Download DREAMPLUS (Kor)',
    txt03: 'DREAMPLUS fosters a fast-paced yet collaborative <strong>startup ecosystem</strong> in an environment where everything you need to ‘<strong>innovate</strong>’ and ‘<strong>grow</strong>’ a product from concept will be readily at your disposal. ',
    txt04: 'DREAMPLUS is <strong>Korea’s one and only open innovation platform</strong>, It provides a comprehensive set of resources for startups to go from vision to launch to success, domestically and even globally. DREAMPLUS has leveraged its experience, know-how, and expertise to create a healthy startup ecosystem and positive feedback loop to improve innovation and accelerate growth. This system leads to higher morale, motivation and productivity.',
    txt05: 'A to Z Support for Startups',
    txt06: 'DREAMPLUS’ acceleration programs provides a wide range of solutions over the lifetime of startups from the cradle to the grave. The acceleration programs helps the starting entrepreneur kickstart a business or get a better job. It encourages startups to grow their businesses by empowering them so they can innovate freely.',
    txt07: 'Innovation Centers',
    txt08: 'Our two innovation centers DREAMPLUS Gangnam and 63 Fintech Center will together serve as a global hub connecting other major innovation centers in Asia including as China, Japan, Hong Kong and Singapore.',
    txt09: 'Investment funding Opportunities for Startups',
    txt10: 'Since 2015, DREAMPLUS has funded more than KRW 700 billion (USD $620MM) into startups. These funds are geared towards nurturing Korean startups so that they can grow their businesses and enter global markets. DREAMPLUS has formed the AI Alliance Fund with Hyundai Motor Company and SK Telecom for select AI startups to invest in. Over KRW 100billion will be invested annually in startups that operate domestically and abroad.',
    txt11: 'Open Partnership',
    txt12: 'DREAMPLUS has heavily invested in developing a robust ecosystem that encourages transparency and collaboration among startups, businesses, and institutions. Creative ideas abound while the type of solution, business, industry, or scale...are boundless.',
    txt13: 'Global Network',
    txt14: 'DREAMPLUS has also partnered with Chungnam Center for Creative Economy and Innovation (CCCEI) to help startups succeed on a global scale. With a network of over 300 Korean startups that include Hanwha Group’s affiliates and its global network consisting of around 250 startups, DREAMPLUS is able to help startups achieve growth and business expansion.',
    txt15: 'No.1 Innovation Platform',
    txt16: 'DREAMPLUS is both Korea\'s no.1 platform and the only one of its kind that can provide comprehensive support to new entrepreneurs and their startups from concept to execution to exit strategy.',
    txt17: '<strong>Starting a business</strong> <span>Startup Funding</span> <span>Startup Academy</span> <span>Training Program</span>',
    txt18: '<strong>Early Stage</strong> <span>Incubation</span> <span>Business model designs</span> <span>Shared services</span> <span>Mentorship</span> <span>Online Community</span>',
    txt19: '<strong>Growth stage</strong> <span>Acceleration</span> <span>Co-working space and Growth</span> <span>Marketing/PR</span> <span>Event/Networking</span> <span>Business collaboration and partnership</span>',
    txt20: '<strong>Expansion stage</strong> <span>Strategic investment</span> <span>Investor Relations (IR)</span> <span>Global entry</span>',
    txt21: '<strong>Exit</strong> <span>IPO</span> <span>M&amp;A</span>',
    txt22: 'Vertical & Horizontal Connectivity',
    txt23: 'DREAMPLUS enhances connectivity through its Innovation Centers.',
    txt24: 'Vertical Connectivity',
    txt25: 'Innovation Centers attract various players in the innovative growth ecosystem',
    txt26: 'Horizontal Connectivity',
    txt27: 'Expansion of the ecosystem by connecting innovation hubs in Asia and beyond',
    txt28: 'Innovation Centers',
    txt29: 'Center of innovative growth ecosystem',
    txt30: 'DREAMPLUS will make industry experts, government agencies and core players in their field available in the startup ecosystem to offer guidance and training. Entrepreneurs within this environment will be free to exchange ideas and collaborate to form communities for mutual growth.',
    txt31: 'Our Value',
    txt32: 'Business',
    txt33: '<dt>Environment for <span>open innovation</span></dt> <dd>Open innovations and technology trends</dd> <dd>Identifying and linking   various leading startups</dd>',
    txt34: 'Investor',
    txt35: '<dt>Qualified opportunity <span>of successful investment</span> <span>and startup nurturing</span></dt> <dd>Identifying promising startups and pairing them together</dd> <dd>Networking with various Asian companies</dd>',
    txt36: 'Startup',
    txt37: '<dt>Optimal environment for <span>development and growth</span></dt> <dd>Business development and overseas market entry</dd> <dd>Investment, mentoring and networking</dd>',
    txt38: 'DREAMERS',
    txt39: '<dt>Various training programs and support for <span> job seekers and startups</span></dt> <dd>Education programs   and startup academy</dd> <dd>University-based   internship programs in startups</dd> <dd>Event attendance/networking to promote business or seek jobs</dd>',
    txt40: 'DREAMPLUS Network',
    txt41: 'We set up a healthy ecosystem through open partnership and various networks.<br>DREAMPLUS creates a healthier ecosystem through partnerships with various startups, corporations and agencies regardless of their respective industry, sector, method or scale. DREAMPLUS supports innovation and growth of startups through 60 Hanwha affiliates, 250 plus global networks and more than 300 domestic partnerships.',
    txt42: 'We lead the way in creating a <strong>sustainable future and we’re committed to nurturing</strong> the best talent.',
    txt43: 'DREAMPLUS operates acceleration programs and collaboration activities designed to support and nurture startups. We provide new opportunities where individuals can find new jobs and gain invaluable experiences that they can apply to their careers. DREAMPLUS is on a trajectory to become an iconic brand, synonymous with great job opportunities for the youth and entrepreneurship. DREAMPLUS is also working through various corporate social responsibility and housing projects for the poor, including Spark Fire Story and Spark Fire Road.',
    txt44: 'Go to the DREAMPLUS Portal',
    txt45: 'Learn about the DREAMPLUS Academy',
    txt46: 'Major activities',
    txt47: 'Acceleration',
    txt48: '(Established in 2014)',
    txt49: 'Acceleration activities for startups<span>(DREAMPLUS Locations: Shanghai, China and Seoul 63 Square)</span>',
    txt50: 'Co-Working Facilities',
    txt51: '(Established in 2017)',
    txt52: 'Co-working offices to facilitate and support the startup ecosystem<span>(Gangnam, Seoul)</span> ',
    txt53: 'The Academy',
    txt54: '(Established in June of 2018)',
    txt55: 'Hanwha’s Premier Academy to build future talents and help them with their careers or start a new business',
    txt56: 'DREAMPLUS Portal',
    txt57: '(August 2018)',
    txt58: 'A Digital platform where opportunities and information abound and dreams come true',
    txt59: 'Co-Living',
    txt60: '(Jan 2019)',
    txt61: 'Shared housing where you can learn and networking together <span>(Planned Launch in Jan 2019)</span>',
    txt62: 'Download DREAMPLUS (eng)',

  },
  News: {
    txt01: 'News from DREAM PLUS Innovation Hub',
    txt02: 'No Post',
    txt03: 'Previous',
    txt04: 'No more previous page',
    txt05: 'Next',
    txt06: 'No more next page',
    txt07: 'We provide a variety of reading materials for DREAMPLUS and startup industry as a whole.',
    txt08: 'File attachment',
  },
};
