/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    txt01: '자주하는 질문과 답변',
    txt02: '전체',
    txt03: '강남',
    txt04: '63 핀테크센터',
    txt05: 'ACC 프로그램',
    txt06: '이용안내',
  },
  gangnam: {
    txt01: '강남센터',
    txt02: '투어',
    txt03: '입주',
    txt04: '센터이용',
    txt05: '주차',
    txt06: '시설',
    txt07: '이벤트',
    txt08: '대관',
    txt09: '드림플러스 강남 투어를 하려면 어떻게 해야 하나요?',
    txt10: `강남센터 안내페이지에서 방문자 예약 신청 후 투어가 가능합니다.
    투어 확정 시 투어 당일 투어확정 문자 메시지를 보내드립니다.
    받으신 문자를 1층 리셉션 데스크에 보여주시면 됩니다.`,
    txt11: '강남센터 바로가기',
    txt12: '드림플러스 강남 투어 예약 가능한 시간은 언제 인가요?',
    txt13: '월-금 14:00~16:00까지 선택 가능합니다. (법정공휴일 예약불가)',
    txt14: '드림플러스 강남 입주 비용이 궁금합니다.',
    txt15: '입주 비용의 경우 계약기간과 입주인원에 따라 변동 되므로 투어 진행 시 담당 매니저를 통해 안내 받으시기 바랍니다.',
    txt16: '드림플러스 강남 입주사의 경우 액셀러레이팅 지원이 가능한가죠?',
    txt17: '입주사가 사업제휴, 투자유치 등 액셀러레이팅 지원이 필요한 경우 운영팀에 연락 주시면 액셀러레이팅 팀을 통해 후속 절차 안내 드립니다.',
    txt18: '드림플러스 강남 입주사의 경우 특별한 혜택이 있나요?',
    txt19: `1층 로비에 위치한 사회적기업 카페 Beans & Dreams의 음료 50% 할인(입주자 출입카드 제시)
    한화 리조트, 골프장, 더플라자 호텔 등 다양한 한화계열사 이용 시 할인
    파트너 제휴 혜택 및 생활편의 서비스 혜택 등이 있습니다.
    보다 자세한 사항 및 신청 방법은 입주사 전용 인트라넷에서 확인 가능합니다.`,
    txt20: '드림플러스 강남 운영시간이 어떻게 되나요?',
    txt21: `드림플러스 강남은 입주사의 경우 365일 24시간 이용 가능 합니다.
    단, 운영진의 경우 월-금 09:00~18:00까지 근무합니다.`,
    txt22: '드림플러스 강남 입주사 미팅 시 방문절차가 있나요?',
    txt23: '드림플러스 강남은 방문자의 경우 1층 로비 안내데스크에서 출입절차 등록 후 출입증 수령이 가능하며 신분증 제출이 필수 입니다.',
    txt24: '드림플러스 강남 주차는 어떻게 되나요?',
    txt25: `1. 입주사 : 분기에 한 번 추첨을 통하여 월정 주차를 배정하고 있습니다.
    2. 투어 방문자 : 투어 시간 동안은 무료 주차 가능합니다.
    3. 입주사 방문자 : 기본 30분 무료 주차 가능하며 방문하는 입주사에게 주차 처리 요청하시면 됩니다.`,
    txt26: '드림플러스 강남 주차장 시설은 어떤게 있나요?',
    txt27: '카쉐어링 서비스인 쏘카 이용이 가능하며 전기 자동차 충전 시설은 별도로 마련되어 있지 않습니다.',
    txt28: '드림플러스 강남 디지털미디어센터 이용은 입주사만 가능한가요?',
    txt29: `입주사의 경우 디지털미디어센터(스튜디오, 편집시설) 이용이 가능합니다.
    디지털미디어센터 운영팀에 별도 예약을 하셔야하며 촬영 장비 대여도 가능합니다.
    단 고정석과 자유석 이용자의 경우는 이용이 불가합니다.`,
    txt30: '드림플러스 강남 이벤트홀 외부 대관 가능한가요?',
    txt31: '내부 이벤트가 없는 경우 외부 대관가능하며 시간당 30만원(VAT별도)으로 자세한 대관 문의는 홈페이지에서 확인 부탁 드립니다.',
    txt32: '드림플러스 강남 공용공간 중 편의시설이 있나요?',
    txt33: '지하 1층 이벤트홀에 이벤트 진행이 없는 경우에는 탁구대와 다트시설 이용이 가능하며 운동 후 같은 층에 마련되어 있는 샤워실 이용도 가능합니다.',
    txt34: '드림플러스 강남 푸드코드 혹은 식당이 마련되어 있나요?',
    txt35: '푸드코드 및 식당은 센터에 없으나 지하 1층에 emart24가 위치하고 있습니다.',
    txt36: '이벤트 신청이 잘 되었는지 보려면 어떻게 하면 되나요?',
    txt37: '마이페이지에서 신청한 이벤트 내역을 확인 할 수 있습니다.',
    txt38: '이벤트 신청 취소는 어떻게 하면 되나요?',
    txt39: '마이페이지에서 신청한 이벤트 내역을 확인 할 수 있으며, 이벤트 별로 신청 취소 할 수 있습니다. 다만, 이벤트에 따라 신청취소 기한이 있을 수 있습니다.',
    txt40: '이벤트에 참석하는 경우 주차가 가능한가요?',
    txt41: '주차장이 협소하고, 유료 주차만 가능하기 때문에(1시간당 6,000원) 가급적 대중교통 이용을 권유 드립니다.',
    txt42: '대관 신청은 어떻게 하면 되나요?',
    txt43: 'Event Hall Reservation 에서 대관신청 버튼을 클릭하면 대관신청 페이지로 연결됩니다. 대관신청은 신청일 기준 2주 이후의 날짜부터 신청이 가능하며, 신청 후 담당자가 별도로 연락을 드립니다.',
    txt44: 'Event Hall Reservation 바로가기',
    txt45: '대관 신청을 취소하려면 어떻게 하면 되나요?',
    txt46: '마이페이지에서 대관신청한 내역과 상태를 확인 후 신청 내역 별로 취소할 수 있습니다. 다만, 입금확인 후 대관신청을 취소하는 경우 예약금은 환불이 불가합니다.',
    txt47: '대관은 입주사만 가능한가요?',
    txt48: '외부 대관도 가능하나 스타트업 유관 행사, 입주사 분들이 함께 참여 가능한 행사가 아닌 경우 제한이 있을 수 있습니다.',
    txt49: '대관은 이벤트홀만 가능한가요?',
    txt50: `1. 입주사 : 이벤트홀, 멀티룸, 회의실, 미디어센터 대관 가능
    2. 외부 : 이벤트홀, 멀티룸(이벤트홀을 대관하는 경우에만) 대관 가능`,
    txt51: '이벤트홀 대관에 포함되는 항목은 무엇인가요?',
    txt52: '지하 1층 이벤트홀 공간, 테이블, 의자, 이벤트 홀 내 기본 음향, LED 스크린, 무선 마이크 2대, HDMI 연결 선 입니다. 그 외 노트북, 클리커(포인터), HDMI 호환 젠더 등은 개별 지참하셔야 합니다.',
    txt53: '연결되어 있는 케이터링 업체가 있나요?',
    txt54: '케이터링 업체는 직접 섭외하셔야 하며 섭외하신 업체에서 음식물 쓰레기를 수거해가는 경우에만 음식물 반입이 가능합니다.',
    txt55: '대관 행사 참석자 분들이 주차가 가능한가요?',
    txt56: '주차장이 협소하고, 유료 주차만 가능하기 때문에(1시간당 6,000원) 가급적 대중교통 이용을 권유 드립니다.',
  },
  FintechCenter: {
    txt01: '63 핀테크센터',
    txt02: '방문/이용',
    txt03: '액셀러레이팅',
    txt04: '63 핀테크센터에 방문하고자 하는데 방문절차가 있나요?',
    txt05: `63 핀테크센터은 63빌딩 4층에 위치하고 있습니다.
    63빌딩 1F 한화생명 본관 로비에서 4층 63 핀테크센터 방문한다고 하시면 임시 출입증을 발급하여 드립니다. (신분증 필요)`,
    txt06: '방문 시 주차지원이 가능한가요?',
    txt07: '방문 시 1시간까지 무료주차가 가능합니다.',
    txt08: '이벤트홀 외부 대관이 가능한가요?',
    txt09: '이벤트홀은 외부대관은 불가합니다.',
    txt10: '63 핀테크센터는 24시간 운영되나요?',
    txt11: `63 핀테크센터의 입주동은 입주 스타트업에게 24시간 개방됩니다.
    입주 스타트업은 누구나 24시간 사무실을 이용할 수 있습니다.`,
    txt12: '보유 입주실 개수와 수용가능 인원을 알고 싶습니다.',
    txt13: `총 10개의 입주실을 제공합니다.
    입주실은 최소 8인부터 최대 16인까지 다양합니다.`,
    txt14: '입주사는 임대비, 관리비를 포함한 전액 무료로 지원을 받나요?',
    txt15: '입주사에게는 임대비, 관리비가 부과되지 않으며 회의실, 컨퍼런스룸, 이벤트홀 역시 무상으로 제공됩니다.',
    txt16: '졸업사에게 후속 지원이 있는지요?',
    txt17: '졸업사에게는 필요시 계열사와의 사업연계, IR 및 해외진출 기회를 제공합니다.',
    txt18: '입주 사무실의 시설은 어떻게 구성되어 있나요?',
    txt19: '입주 사무실은 독립 시건장치, 개별냉난방장치, 대형 TV, 공용 캐비닛이 구비되어 있습니다.',
    txt20: '회의실 예약은 어떻게 하나요?',
    txt21: '자체 그룹웨어에서 비어 있는 회의실을 사전 예약하고 사용하시면 됩니다.',
    txt22: '기타 부대시설은 어떤 것들이 있나요?',
    txt23: '커피머신(무료), 공용냉장고, 냉온정수기, 전자레인지 등이 구비되어 있습니다.',
    txt24: '액셀러레이팅 프로그램 참가기업은 수시로 모집하나요?',
    txt25: '6개월 배치형태의 액셀러레이팅 프로그램은 수시모집이 아닌, 정기모집을 하고 있습니다.',
  },
  Acc: {
    txt01: '프로그램 신청',
    txt02: '프로그램 문의',
    txt03: '입주사 ACC',
    txt04: 'ACC프로그램은 어떻게 신청하나요?',
    txt05: '드림플러스는 다양한 분야 (핀테크/블록체인/F&B/Contents/Travel/Healthcare/Education) 의 액셀러레이팅 프로그램을 운영중에 있으며 비정기적으로 진행되고 있습니다. 신청은 드림플러스 웹사이트에서 지원가능합니다.',
    txt06: '액셀러레이팅 프로그램 관련하여 문의를 하고 싶습니다.',
    txt07: '드림플러스의 액셀러레이팅을 받길 원하거나 액셀러레이팅 관련 문의사항이 있으면 <a href="mailto:accelerating@dreamplus.center">accelerating@dreamplus.center</a> 로 회사소개서(사업계획서)와 함께 보내 주세요.',
    txt08: 'GEP 프로그램은 어떻게 신청하나요?',
    txt09: 'GEP는 충남창조경제혁신센터에서 진행하고 있습니다. GEP는 비정기적으로 모집하고 있으며(1년에 2~3회) 보다 자세한 사항은 충남창조경제혁신센터(<a href="http://ccei.creativekorea.or.kr/chungnam" target="_blank">http://ccei.creativekorea.or.kr/chungnam</a>) 에서 확인하시기 바랍니다.',
    txt10: '드림플러스 입주사 액셀러레이팅 프로그램에 지원하고 싶습니다.',
    txt11: '각 센터별 신규 액셀러레이팅 프로그램이 있는 경우 별도 공지를 통해 신청자를 모집하고 있습니다. 공지 시 신청기간내에 공지된 방법으로 신청하시면 됩니다.',
  },
  UseInfo: {
    txt01: '이용안내',
    txt02: '회원',
    txt03: '서비스이용',
    txt04: '아이디(이메일)를 잊어버렸어요',
    txt05: '아이디를 잊어버린 경우, 로그인 페이지 > Email ID 찾기를 통해 아이디를 찾을 수 있습니다.',
    txt06: '비밀번호를 변경하려면 어떻게 해야 하나요?',
    txt07: `비밀번호 변경은 마이페이지에서 가능 합니다.
    비밀번호를 잊어버린 경우에는 비밀번호를 재설정해야 합니다.
    로그인 페이지에서 Password 찾기 를 클릭하여 이메일 입력 시 비밀번호 재설정 링크를 메일로 발송해 드립니다.`,
    txt08: '로그인이 되지 않습니다.',
    txt09: `이메일 주소와 비밀번호를 정상적으로 입력했는데도 로그인이 되지 않는 경우, 해당 이메일 주소가 SNS로 가입된 주소가 아닌지 확인해 주세요.
    SNS로 가입된 이메일주소의 경우 SNS로그인으로 로그인해야 합니다.`,
    txt10: '로그인 연동(Single Sign On)이 무엇인가요?',
    txt11: `로그인 연동(Single Sign On)은 동일한 이메일아이디로  드림플러스 이노베이션 허브(http://www.dreamlus.asia)와 드림플러스 포털(http://www.dreamplus.io) 을 로그인하는 기능입니다.
    로그인 연동(Single Sign On)에 동의하게 되면 두 서비스를 한 번의 로그인으로 이용할 수 있습니다.

    * 로그인 연동 동의 후에도 언제든 마이페이지에서 연동을 철회할 수 있습니다.
    * 두 서비스 사이트에서 각각 다른 이메일아이디를 사용 중인 경우에는 로그인 연동을 할 수 없습니다.`,
    txt12: '비밀번호 재설정 메일이 오지 않습니다.',
    txt13: `비밀번호 재설정을 요청 했으나 메일을 받지 못한 경우, 스팸메일함을 확인해 주세요.
    스팸메일함에도 메일이 오지 않은 경우 <a href="mailto:contact@dreamplus.asia">contact@dreamplus.asia</a> 로 문의해 주세요.`,
    txt14: '비밀번호 재설정이 되지 않습니다.',
    txt15: '비밀번호 재설정에 지속적으로 실패하는 경우 <a href="mailto:contact@dreamplus.asia">contact@dreamplus.asia</a> 로 문의해 주세요',
    txt16: '회원 탈퇴하려면 어떻게 하나요?',
    txt17: `회원탈퇴를 원하실 경우 마이페이지에서 가능합니다.
    마이페이지 > 개인정보 페이지 하단에 회원탈퇴 버튼을 클릭하시면 회원탈퇴가 완료됩니다.`,
    txt18: '* 회원탈퇴할 경우 회원님의 정보는 삭제되며 복구 불가합니다.',
    txt19: '드림플러스와 연계된 전문가의 도움을 받고 싶은데 어떻게 해야 하나요?',
    txt20: `전문가의 도움의 필요한 경우, Player DB에서 필요한 분야의 플레이어를 검색해 보세요. 적합한 플레이어를 찾아 메시지를 보낼 수 있습니다.
    혹은 Biz Opportunity 에서 게시글을 작성해 보세요. 드림플러스와 함께 하는 많은 전문가와 회원들이 도움을 드릴 수 있습니다.`,
    txt21: '* Biz Opportunity 는 입주사회원, 파트너회원, 한화회원만 사용가능한 메뉴입니다.',
    txt22: 'Player DB 바로가기',
  },
};
