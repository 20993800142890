/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    txt01: 'Frequently Asked Questions',
    txt02: 'Total',
    txt03: 'Gangnam',
    txt04: '63 FintechCenter',
    txt05: 'ACC Programs',
    txt06: 'Use Guide',
  },
  gangnam: {
    txt01: 'GangnamCenter',
    txt02: 'Tour',
    txt03: 'Move in',
    txt04: 'Visit',
    txt05: 'Parking',
    txt06: 'Facility',
    txt07: 'Event',
    txt08: 'Reservation',
    txt09: 'How do I participate on the DREAMPLUS Gangnam tour?',
    txt10: `You can register for a tour of DREAMPLUS Gangnam online.
    Once you sign up for the tour, you will receive a text message confirming the date of your tour.
    You will need to show this text message to the receptionist on the first floor at DREAMPLUS Gangnam.`,
    txt11: 'Go to Gangnam Center',
    txt12: 'What time slots for DREAMPLUS Gangnam tour reservation are available?',
    txt13: 'Mon-Fri, 2:00pm-4:00pm. (Except on legal holidays)',
    txt14: 'How much is the move-in cost for DREAMPLUS Gangnam?',
    txt15: 'Since move-in costs vary depending on the contract period and the number of people moving in, please consult the facilities manager during the tour.',
    txt16: 'Are DREAMPLUS Gangnam tenants allowed to apply for Acceleration Programs?',
    txt17: 'Tenants that need Acceleration support for business partnerships and attracting investor funding, can contact our Operation Team.',
    txt18: 'What are some of the additional perks for being a DREAMPLUS Gangnam tenant?',
    txt19: `Aside from the myriad of services, resources, and support services available to every tenant, there are additional perks for DREAMPLUS Gangnam tenants. They include a 50% discount on all drinks at Beans & Dreams on the first floor (upon presenting a tenant card) and discounts on various Hanwha facilities, including Hanwha Resorts, golf courses and The Plaza Hotel.
    For more details and application process, please visit the tenant intranet.`,
    txt20: 'What are the operating hours of DREAMPLUS Gangnam?',
    txt21: `DREAMPLUS Gangnam is open to tenants 24/7.
    However, operators work Monday - Friday, from 9:00am to 6:00pm.`,
    txt22: 'What are the visiting hours at DREAMPLUS Gangnam?',
    txt23: 'Visitors must go to the information desk located at the 1F main lobby and hand in their ID to receive a visitor pass.',
    txt24: 'Is there parking at DREAMPLUS Gangnam?',
    txt25: `1. Tenants are assigned monthly parking lots on a quarterly basis.
    2. Tour visitors: Parking is free during the duration of a tour.
    3. Tenant visitors: Parking is free for the first 30 minutes. Ask the tenant you are visiting for additional time.`,
    txt26: 'What facilities does DREAMPLUS Gangnam parking have? ',
    txt27: 'You can use SOCAR, a car-sharing service. However, there is no electric vehicle charging station on site.',
    txt28: 'Is DREAMPLUS Gangnam Digital Media Center only open to tenants?',
    txt29: `Yes, only Tenants may use the Digital Media Center (studio, editing equipment, etc.) Note, the Digital Media Center is not open to fixed/free seat users.
    Reservations via Digital Media Center Operation Team are required. Tenants can also rent filming equipment.`,
    txt30: 'Can an external party rent the DREAMPLUS Gangnam event hall?',
    txt31: 'If there is no internal event, external parties may rent the event hall for 300,000 won/hour (VAT not included). Please visit our website for more details.',
    txt32: 'What amenities are in shared spaces of DREAMPLUS Gangnam?',
    txt33: 'The Event Hall is a shared space. Tenants can use ping-pong tables and darts in the event hall (B1) when there is no event taking place. There is also a shower room on the same floor.',
    txt34: 'Is there a food court or cafeteria at DREAMPLUS Gangnam?',
    txt35: 'We do not have a food court or cafeteria but there is a convenience store on the first basement level.',
    txt36: 'How can I find whether the application for event is well processed?',
    txt37: 'You can find the list of event applications on My Page.',
    txt38: 'How can I cancel the application for event?',
    txt39: 'You can find the list of event applications and cancel the application for each event on My Page. However, there might be a valid period of cancellation of application depending on event.',
    txt40: 'Is car parking available if I participate in an event?',
    txt41: 'As a small-sized paid parking lot is available only (6,000won per hour), we recommend using public transportation if possible.',
    txt42: 'How can I rent a event hall?',
    txt43: 'If you click a button for rental application on Event Hall Reservation, the page will be linked to a page of rent application. You can apply for rent at least two weeks before the event date. After application is made, our manager will contact you individually.',
    txt44: 'Go to Event Hall Reservation',
    txt45: 'How can I cancel the application for rent?',
    txt46: 'You can cancel the application after finding the list and status of  applications on My Page. However, deposit money is not refundable if you cancel the application after the receipt of payment is confirmed.',
    txt47: 'Is rental only allowed for resident companies?',
    txt48: 'Non-resident companies are allowed for rental. However, there might be limits if the event is not related to start-ups or not open to resident companies.',
    txt49: 'Is event hall only available for rental?',
    txt50: `1. Resident company: available for renting event hall, multi room, meeting room and media center
    2. Non-resident company: available for renting event hall and multi room (only if renting event hall)`,
    txt51: 'What is included in the event hall rental?',
    txt52: 'The rental includes event hall space on the B1F, table, chair, basic sound equipment, LED screen, wireless mic (2 ea.), and HDMI cable. You need bring a laptop, clicker (pointer) and HMDI gender changer on your own if necessary.',
    txt53: 'Is there any catering company linked to your business?',
    txt54: 'You need to arrange for catering service on your own. Food is allowed to be carried in only if the catering service provider collects the food waste.',
    txt55: 'Is parking available for event participants?',
    txt56: 'As a small-sized paid parking lot is available only (6,000won per hour), we recommend using public transportation if possible.',
  },
  FintechCenter: {
    txt01: '63 FintechCenter',
    txt02: 'Visit',
    txt03: 'Accelerating',
    txt04: 'How do I visit DREAMPLUS 63?',
    txt05: `DREAMPLUS 63 is located on the fourth floor of The 63 Building.
    Go to the main lobby of Hanwha Life on the first floor to receive a visitor pass. (ID is required)`,
    txt06: 'Is parking free for visitors?',
    txt07: 'Parking is free for visitors for only the first hour.',
    txt08: 'Can an external party rent the event hall? ',
    txt09: 'No, external parties are not allowed to rent the event hall.',
    txt10: 'Is DREAMPLUS 63 open 24/7?',
    txt11: `DREAMPLUS 63 is open 24 hours.
    All DREAMPLUS tenants have 24/7 access to the office spaces.`,
    txt12: 'How many office spaces do you have and what\'s the capacity?',
    txt13: `There is a total of 10 office spaces.
    Depending on type of office, capacity can range from 8 to 16 people.`,
    txt14: 'Are tenants exempt from rent and maintenance fee? ',
    txt15: 'Yes. Tenants are not required to pay rent or any maintenance fee. Moreover, they can use the meeting rooms, conference rooms and the event hall for free.',
    txt16: 'Is there any follow-up support for tenants after they move out?',
    txt17: 'By request, we offer ex-tenants with business collaboration, IR and overseas expansion opportunities.',
    txt18: 'What amenities are in the offices?',
    txt19: 'Each office comes with its own door lock, heating/cooling system, a big screen TV, cabinets, a coffee machine, a refrigerator, a water purifier and a microwave.',
    txt20: 'How do I reserve a meeting room?',
    txt21: 'You can reserve a meeting room through our groupware.',
    txt22: 'What other amenities are there?',
    txt23: 'There is a coffee machine, shared refrigerator, water purifier and microwave.',
    txt24: 'When do you recruit for the Acceleration Program?',
    txt25: 'During a 6-month period in any given year, we recruit participants for the Acceleration Programs.',
  },
  Acc: {
    txt01: 'Apply for programs',
    txt02: 'Program inquiries',
    txt03: 'Tenant ACC',
    txt04: 'How do I apply for the Accleration Programs?',
    txt05: 'DREAMPLUS offers Acceleration Programs in various fields (such as fintech, blockchain, F&B, content, travel, healthcare and education). Please visit our website to apply for Acceleration Programs.',
    txt06: 'I\'d like to ask questions about ACC Programs.',
    txt07: 'To participate in or ask questions about DREAMPLUS ACC Programs, please send your company profile (business plan) to <a href="mailto:accelerating@dreamplus.center">accelerating@dreamplus.center</a>',
    txt08: 'How do I apply for GEP?',
    txt09: 'GEP is operated by Chungnam Creative Economy and Innovation Center. GEP accepts applications 2-3 times a year on dates that are not set. Please visit their website (<a href="http://ccei.creativekorea.or.kr/chungnam" target="_blank">http://ccei.creativekorea.or.kr/chungnam</a>) for more details.',
    txt10: 'I\'d like to apply for Tenant ACC Programs.',
    txt11: 'Each center posts announcements of their new programs to recruits applicants. Please refer to the announcements and apply within the application period.',
  },
  UseInfo: {
    txt01: 'Usage Guide',
    txt02: 'Member',
    txt03: 'Service',
    txt04: 'I forgot my ID (email address).',
    txt05: 'If you can\'t remember your ID, you can go to the Login page > Find Email/ID to retrieve it.',
    txt06: 'How do I change my password?',
    txt07: `You can change your password on My Page.
    If you can't remember your password, you must reset it.
    If you click "Forgot My Password" on the login page and enter your email address, the password reset URL will be sent.`,
    txt08: 'I cannot login.',
    txt09: `If you can't login even after entering the correct email  and password, check whether you signed up with a social media account with the same email address.
    If so, you have to login via SNS Login.`,
    txt10: 'What is a Single Sign On?',
    txt11: `If you agree to use Single Sign On, you can simultaneously login to both DREAMPLUS Innovation Hub (http://www.dreamlus.asia) and DREAMPLUS  Portal(http://www.dreamplus.io).
    You can always disable Single Sign On on My Page.`,
    txt12: 'I have not received a password reset email.',
    txt13: `If you have not received a password reset email after requesting for password reset, make sure to check the spam mailbox.
    Please contact contact@dreamplus.asia if you still can't find it. <a href="mailto:contact@dreamplus.asia">contact@dreamplus.asia</a>`,
    txt14: 'I cannot reset my password.',
    txt15: 'If password reset fails repeatedly, please contact <a href="mailto:contact@dreamplus.asia">contact@dreamplus.asia</a>.',
    txt16: 'How do I delete my account?',
    txt17: `To delete your account, go to My Page > Personal Information.
    Click "Delete account" at the bottom of the page to delete your account.`,
    txt18: '* Deleting your account will also permanently delete your information.',
    txt19: 'How do I get help from experts partnered up with DREAMPLUS?',
    txt20: `If you need expert help, please use Player DB to search for experts in the applicable field. You can send messages to appropriate players.
    You can also upload a post on Biz Opportunity. Many experts and members who are working with DREAMPLUS will be able to help you.`,
    txt21: '* Biz Opportunity is only available to DREAMPLUS tenants and partners and Hanwha members. ',
    txt22: 'Go to Player DB',
  },
};
