/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  SignIn: {
    txt01: '드림플러스 이노베이션 허브에 가입이 완료되었습니다.',
    txt02: '드림플러스 이노베이션 허브에 오신 것을 환영합니다.',
    txt03: '스타트업과 기업을 위한 온ㆍ오프라인 통합 플랫폼 드림플러스 이노베이션 허브에 지금 로그인 하십시오.',
    txt04: '로그인',
    txt05: 'E-mail ID 입력',
    txt06: '비밀번호 입력',
    txt07: '로그인 오류시 <br> <a href="mailto:contact@dreamplus.asia">관리자메일(contact@dreamplus.asia)</a>로 문의해 주세요.',
    txt08: 'SNS 로그인',
    txt09: '아직 드림플러스 이노베이션 허브 회원이 아니신가요?',
    txt10: '회원가입',
  },
  popup: {
    SSOAlert: {
      txt01: '로그인 연동(Single Sign On) 안내',
      txt02: '드림플러스 포털과 동일한 이메일 주소로 드림플러스 이노베이션 허브 서비스에 가입하시면, 한 번의 로그인으로 두 서비스를 이용할 수 있습니다.',
      txt03: '회원가입 하시겠습니까?',
      txt04: '회원가입하지 않음',
      txt05: '일주일 동안 띄우지 않음',
    },
    SSOConnector: {
      txt01: '로그인 연동(Single Sign On)',
      txt02: '드림플러스 포털과의 로그인 연동을 위해 이메일아이디를 드림플러스 포털에 제공합니다.',
      txt03: '로그인 연동에 동의하시겠습니까?',
      txt04: `1. 제공받는 자 : (주)한화<br><br>

      2. 제공받는 자의 이용목적 : 별도 로그인없이 드림플러스 이노베이션 허브 온라인 서비스를 이용하게 하는 회원 로그인 정보 공동이용 서비스 제공<br><br>

      3. 제공할 개인정보 항목 : e-mail 아이디<br><br>

      4. 보유·이용기간 <br>
         - 동의일로부터 서비스를 제공하는 기간 동안<br>
           다만, 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의 규정에 따라 보존할 필요성이 있는 경우에는 개인정보를 보유할 수 있습니다.<br><br>

      5. 동의 거부권 및 동의 거부에 따른 불이익 고지<br>
         - 귀하는 위 항목에 대하여 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회 가능합니다.<br><br>

         * 단, 동의를 거부하실 경우에는 서비스 이용에 제한(통합 로그인 제약)이 있을 수 있습니다.`,
      txt05: '동의함',
      txt06: '동의하지 않음',
      txt07: '로그인 연동을 위해 드림플러스 이노베이션 허브의 비밀번호를 입력해 주세요.',
      txt08: '비밀번호가 맞지 않습니다.',
      txt09: '로그인 연동 완료를 위해 SNS로그인이 필요합니다.',
      txt10: 'SNS로그인이 필요합니다.',
      txt11: '로그인 연동이 완료되었습니다.',
      txt12: '이제 드림플러스 포털과 드림플러스 이노베이션 허브 사이트를',
      txt13: '한 번의 로그인으로 모두 이용할 수 있습니다.',
      txt14: '언제든 마이페이지에서 로그인 연동 동의를 철회할 수 있습니다.',
    },
  },
};
