/** EVENT & NEWS - News */
import { append } from '@/common/helperUtils';

const appendName = append;

export default [
  {
    path: '',
    redirect: 'list',
  },
  {
    path: 'list',
    name: 'NewsList',
    component: () => import(`@/views/news/${appendName}NewsList.vue`),
    meta: {
      title: 'News',
    },
  },
  {
    path: ':newsNo(\\d+)',
    name: 'NewsDetail',
    component: () => import(`@/views/news/${appendName}NewsDetail.vue`),
    props: true,
  },
];
