import common from './en/common';
import main from './en/main';
import about from './en/about';
import center from './en/center';
import accelerating from './en/accelerating';
import event from './en/event';
import partners from './en/partnership';
import networking from './en/networking';
import faq from './en/faq';
import users from './en/users';
import signup from './en/signup';
import signin from './en/signin';
import auth from './en/auth';
import dreamplus from './en/dreamplus';
import studio from './en/studio';
import programs from './en/programs';
import news from './en/news';
import apply from './en/apply';

/* eslint-disable max-len */
export default {
  content: {
    common,
    main,
    about,
    center,
    accelerating,
    event,
    partners,
    networking,
    faq,
    users,
    signup,
    signin,
    auth,
    dreamplus,
    studio,
    programs,
    news,
    apply,
  },
  // GNB
  footer: {
    private: 'Privacy Policy',
    agreement: 'Terms of Use',
    faq: 'FAQ',
  },
  gnbMenu: {
    top: {
      about: 'About',
      center: 'Center',
      accelerating: 'Accelerating',
      event: 'Event',
      partnership: 'Partnership',
      oiDirectory: 'OI Directory',
    },
    sign: {
      signin: 'Login',
      signup: 'Sign up',
      signout: 'Log out',
    },
  },
  signUp: {
    title: {
      type: 'Select membership type',
      user: 'Enter information',
      agreement: 'Agree to terms and conditions',
      auth: 'Sign up complete',
    },
  },
  sns: {
    naver: 'NAVER',
    facebook: 'Facebook',
    google: 'Google',
    kakao: 'KAKAO',
  },
  networking: {
    player: {
      serviceType: 'Service Category',
      businessType: 'Business Category',
    },
  },
  commonCode: {
    6: 'Event Hall ',
    7: 'Multi Room A',
    8: 'Multi Room B',
    9: 'Multi Room C',
    10: 'Multi Room D',
    11: 'Multi Room E',
    ko: 'KOR',
    en: 'ENG',
    ja: 'JPN',
    zh: 'CHN',
    eventStatus01: '행사취소',
    eventStatus02: '행사완료',
    eventStatus03: '행사 중',
    eventStatus04: '신청접수 완료',
    eventStatus05: '신청 접수 중',
    undefined: '',
    MB101: 'Email',
    MB102: 'Naver',
    MB103: 'Google',
    MB104: 'Facebook',
    MB201: 'General member',
    MB202: 'Tenant member (Gangnam)',
    MB203: 'Employee member ',
    MB204: 'Partner member',
    MB205: 'Tenant member (63)',
    MB298: 'Manager',
    MB299: 'Super Manager',
    EV101: 'Business/IR',
    EV102: 'Class/Conference',
    EV103: 'Networking',
    EV104: 'Etc.',
    EV105: 'Promotion/Presentation',
    EV201: 'DREAMPLUS Gangnam Event Hall',
    EV202: 'DREAMPLUS Gangnam Multi Room A',
    EV203: 'DREAMPLUS Gangnam Multi Room B',
    EV204: 'DREAMPLUS Gangnam Multi Room C',
    // EV205: 'DREAMPLUS 63 Event Hall',
    EV205: 'DREAMPLUS Gangnam Multi Room D',
    EV206: 'DREAMPLUS Gangnam Multi Room E',
    EV209: 'Other',
    EV301: 'Inside the website',
    EV302: 'External websites',
    EV501: 'General member',
    EV502: 'Tenant(Gangnam)',
    EV503: 'Hanwha Member',
    EV504: 'Partner member',
    EV505: 'Tenant(63)',
    EV506: 'Total',
    EV507: '회원전체 & 비회원 포함',
    EV508: 'Non-members',
    EV509: 'Other members',
    EV601: '행사완료',
    EV602: '행사 중',
    EV603: '행사일',
    EV604: '신청마감',
    EV605: '신청 접수 중',
    EV606: '신청 접수 전',
    EV609: '행사취소',
    PL101: 'Startup',
    PL102: 'Accelerator',
    // PL103: `Incubator`,
    // PL104: `Company Builder`,
    // PL105: `Venture Capital`,
    // PL106: `Corporate Venture Capital`,
    // PL107: `Private Equity Fund`,
    // PL108: `Angel Investment Club`,
    // PL109: `Private Investment Fund`,
    // PL110: `Private Investor (Angel)`,
    PL111: 'Large Corporate',
    // PL112: `Small and Medium-Sized Enterprise`,
    // PL113: `Special Purpose Corporation`,
    // PL114: `Sole Proprietorship`,
    PL115: 'Public Institution',
    // PL116: `Private Institution`,
    // PL117: `Supporting Organization`,
    // PL118: `Nonprofit Organization`,
    // PL119: `Association/Foundation`,
    // PL120: `Government Organization`,
    PL121: 'Educational Organization',
    // PL122: `DREAMPLUS Tenant`,
    // PL123: `Other`,
    PL201: 'Lifestyle',
    PL202: 'Mobile/App',
    PL203: 'IoT',
    PL204: 'O2O',
    PL205: 'Blockchain',
    PL206: 'Education',
    PL207: 'Health care',
    PL208: 'Medical/Bio',
    PL209: 'Beauty/Fashion',
    PL210: 'Environment/Energy',
    PL211: 'Media/Entertainment',
    PL212: 'Game/Publishing',
    PL213: 'Video/Content',
    PL214: 'Advertisement/Marketing/Promotion',
    PL215: 'Accommodation/Food',
    PL216: 'Travel/Leisure',
    PL217: 'Entertainment/Logistics',
    PL218: 'Chemistry/Heavy Industry',
    PL219: 'Automobile/Drone',
    PL220: 'Book/Publication',
    PL221: 'Design/Interior',
    PL222: 'Shopping/E-Commerce',
    PL223: 'Payment/Security',
    PL224: 'Companion Animal/Hobby',
    PL225: 'Arts/Sports',
    PL226: 'Childcare/Wedding',
    PL227: 'VR/AR',
    PL228: 'Government/Public',
    PL229: 'Real Estate/Construction',
    PL230: 'Agriculture/Fishery/Forestry/Mining and Manufacturing',
    PL231: 'AI/Self-Driving',
    PL232: 'Electronics/Electrical/Mechanical',
    PL233: 'Legal/Patent/Accounting/Tax',
    PL234: 'Data/Statistics',
    PL235: 'Manufacturing/Hardware',
    PL236: 'Sharing Economy/Rental',
    PL237: 'Finance/Fintech',
    PL238: 'Transportation/Communication',
    PL239: 'Software/Development',
    PL240: 'Investment',
    PL241: 'CSR/CSV',
    PL242: '3D Printer',
    PL243: 'Event/Ceremony',
    PL244: 'NGO/NPO',
    PL245: 'Startup Support',
    PL246: 'Management Consulting',
    PL301: 'Startup',
    PL302: 'Accelerator',
    PL303: 'Investor',
    PL304: 'Company / Institution',
    PL305: 'Government Organization',
    PL306: 'Educational Organization',
    PL501: 'Approved ',
    PL502: 'Saved draft',
    PL503: 'Request for Registration',
    PL504: 'Evaluation in Prgress',
    PL505: 'Approval on hold',
    PL601: 'Gangnam',
    PL602: '63',
    PL603: 'Past Tenant',
    PL609: 'N/A',
    PL701: 'ACC',
    PL702: 'ACC',
    PL709: 'N/A',
    PL801: '1~10',
    PL802: '11~50',
    PL803: '51~100',
    PL804: '101~300',
    PL805: '301~500',
    PL806: '501~1000',
    PL807: '1001 or more',
    PL1001: 'CEO',
    PL1002: 'Co-CEO',
    PL1003: 'Point of Contact',
    PL1004: 'Person',
    PL1101: 'Seed(Pre-A)',
    PL1102: 'Series A',
    PL1103: 'Series B',
    PL1104: 'Series C',
    PL1105: 'Series C+',
    PL1106: 'IPO',
    PL1107: 'M&A',
    PL1108: 'ICO',
    PL1109: 'Others',
    BZ101: 'Business collaboration',
    BZ102: 'Investment',
    BZ103: 'Overseas expansion',
    BZ104: 'Service/Partnership',
    BZ199: '[Admin.-only posts]',
    CT101: 'Gangnam',
    CT102: '63',
    CT201: 'Event Hall',
    CT202: 'Multi-Room A',
    CT203: 'Multi-Room B',
    CT204: 'Multi-Room C',
    CT203_4: 'Multi-Room B+C',
    CT205: 'Multi-Room D',
    CT206: 'Multi-Room E',
    CT205_6: 'Multi-Room D+E',
    CT207: 'Multi-Room D',
    CT208: 'Multi-Room E',
    CT301: 'Table + chair: 144 seats',
    CT302: 'Chair: 200 seats',
    CT303: 'Other requests',
    CT401: 'Rental approved',
    CT402: 'Rental application',
    CT403: 'Rental cancelled',
    CT404: '대관거절',
    AC290: 'Application completed',
    AC291: 'Cancelled application',
    AC292: 'Pass',
    AC293: 'Fail',
    AC299: 'Save draft',
    AC300: '모집중지',
    AC301: '모집전',
    AC302: '모집중',
    AC303: '공모마지막일',
    AC304: '모집종료',
    ZA001: '초기화',
    ZA002: '확인',
    ZA003: '취소'
  },
  // Member
  customer: {
  },
  // for custom alert message
  alertMessage: {
    logout: 'You have logged out.',
    sentEmail: 'Email sent',
    sentMessage: 'Message sent',
    deleted: 'Deleted',
    updated: 'Edited',
    saved: 'Saved',
    canceled: 'Cancelled',
    savedTemporary: 'Your draft has been saved.',
    emptiedReply: 'Posted',
    registered: 'Registered',
    deletedUser: 'Your account has been deleted.',
    updatedUser: 'Your information has been edited.',
    approving: 'Approval pending',
    applicatedEvent: 'You have successfully applied to participate in an event ',
    faildEvent: '모집정원 초과로 이벤트 참여 신청이<br>실패되었습니다.',
    beforeApplicatedEvent: '이미 신청하신 이벤트 입니다.',
    canceldEvent: 'You have cancelled your event participation.',
    resetPassword: 'Password reset email has been sent.<br>Please check your email.',
    deletedEmptyMessage: 'Select messages to delete.',
    invalidVenueHour: 'Rent hours should be at least 2 hours.',
    emptiedVenue: 'Please drag and select the time you want',
    invalidVenueRoom: 'You are only able to rent Multi Room with Main hall.<br>And same working hours as Main Hall rental hours.',
    // invalidVenueDiffHour: `메인홀과 멀티룸을 동반예약할 경우<br>대관시간은 동일한 시간만 신청 가능합니다.`,
    invalidVenueDiffHour: 'You are only able to rent Main hall and Multi Room simultaneously.',
    invalidReApplyVenue: 'You are only able to rent Main hall and Multi Room simultaneously.',
    lectureApplication: '수강신청 접수되었습니다.',
    invalidVenueWeekendRoom: 'On Saturday, You are only able to rent Multi Room A/B/C with Main hall.<br>And same working hours as Main Hall rental hours.',
    invalidVenueWeekendRole1: 'On Saturday, You are only able to rent Multi Room E and D simultaneously.',
    invalidVenueWeekendRole2: 'On Saturday, You are only able to rent Multi Room D and E simultaneously.',
    invalidVenueRole1: 'You are only able to rent Multi Room E and D simultaneously.',
    invalidVenueRole2: 'You are only able to rent Multi Room D and E simultaneously.',
    invalidVenueEtc: 'An error has occured.<br>Please contact your site administrator.',
    invalidVenueMatchTime: 'You are only able to select same working hours as Multi Room rental hours.',
    invalidVenueErr1: 'You cannot make a reservation. Main hall has already booked by another person.',
    invalidVenueErr2: 'You cannot make a reservation. Multi Room D has already booked by another person.',
    invalidVenueErr3: 'You cannot make a reservation. Multi Room E has already booked by another person.',
    invalidEventHallErr1: '예약 시간 전후 30분<br/>정리시간이 필요합니다.',
  },
  confirmMessage: {
    canceled: 'Do you want to cancel?',
    requiredLogin: 'You need to login.',
    deletedMessage: 'Do you want to delete this message?',
    deletedReply: 'Do you want to delete this comment?',
    deletedPosts: 'Do you want delete this post?',
    deletedPlayer: 'Do you want to delete this player?',
    deletedUser: 'Deleting your account will also delete the history and records of using this service.<br>Are you sure you want to delete your account?',
    canceledProgram: 'Do you want to<br> cancel this application?',
    canceledBizOpp: 'Do you want to cancel request registration ?',
    lectureApplication: '수강신청 하시겠습니까?',
  },
  // for custom error message
  errorMessage: {
    invalidLogin: 'Login error. Please check your email and password.',
    invalidEmail: 'Login error. Please check your email. Login impossible with phone number.',
    invalidPassword: 'Incorrect password.',
    DuplicatedPassword: 'You cannot re-use the previous password.',
    notFoundEmail: 'Cannot find your SNS information.<br/>Please sign up first.',
    usedEmail: 'This is an e-mail used to move into Dream Plus Gangnam.<br/>Please sign up for another SNS channel.',
    code999: 'Server error!!! Please contact your site administrator.',
    code625: 'Please logout and login again<br>for update your information.',
  },
  /*
   * VeeValidate
   * - Localization : https://logaretm.github.io/vee-validate/guide/localization.html
   * - Messages as Functions : https://logaretm.github.io/vee-validate/guide/basics.html#messages
   */
  validations: {
    code: 'en',
    names: {
      email: 'E-mail',
    },
    messages: {
      email: '{_field_} Incorrect address.',
      phone: '{_field_} is incorrect.',
      required: 'Please enter {_field_}',
      fieldRequired: '{_field_} is required.',
      size: 'Please check size of {_field_}.',
    },
    fields: {
      regexPassword: {
        regex: 'Please check the combination and number of characters of your password.',
        required: 'Enter password.',
      },
      regexUserName: {
        regex: 'You can only use Korean/English characters and numbers.',
        required: 'Enter nickname.',
      },
      regexPhone: {
        regex: 'Enter your phone number accurately.',
        required: 'Enter phone number.',
      },
    },
  },
};
