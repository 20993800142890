<template>
  <div class="layer-wrap" style="display: block;">
    <div class="popup">
      <div class="popup-contents">
        <p
          v-html="message"
          class="message"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * 확인(Alert)  팝업 컴포넌트
 */
export default {
  name: 'ToastPopup',
  data() {
    return {
      hide: false,
    };
  },
  props: {
    name: {
      type: String,
      default: 'toast',
    },
    message: {
      type: String,
      required: true,
    },
    time: {
      type: Number,
      default: 1500,
    },
  },
  mounted() {
    setTimeout(() => {
        this.$modal.hide(this.name, true);
    }, this.time);
  },
};
</script>

<style scoped>
.popup.sm-pop {
  width: 460px;
  height: auto;
}
.popup.sm-pop .pop_cnt {
  padding: 40px !important;
}
.popup.sm-pop .pop_cnt .msg {
  font-size: 20px;
  color: #333;
  line-height: 26px;
}
.popup.sm-pop .btns-wrap {
  position: relative;
  text-align: center;
  bottom: 0 !important;
}

@media all and (max-width: 1023px) {
  .popup.sm-pop .pop_cnt {
    padding: 20px !important;
  }
  .popup.sm-pop .btns-wrap {
    text-align: center;
    padding-top: 0;
    margin: 20px 0 0 0;
  }
}

@media all and (max-width: 767px) {
  .popup.sm-pop {
    width: 90%;
    height: auto;
  }
  .popup.sm-pop .pop_cnt {
    padding: 20px !important;
  }
  .popup.sm-pop .pop_cnt .msg {
    font-size: 15px;
    line-height: 21px;
  }

  .popup.sm-pop .btns-wrap {
    text-align: center;
    padding-top: 0;
    margin: 20px 0 0 0;
  }
}
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 :
```jsx
<BaseButton @click="showAlert('확인해주세요')">Alert</BaseButton>
```

팝업 표시 예시 :
```jsx
<AlertPopup message="확인해주세요"></AlertPopup>
```
</docs>
