/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Story: {
    txt01: '',
    txt02: '드림플러스 소개자료(Kor) 다운로드',
    txt03: '‘<strong>혁신</strong>’과 ‘<strong>성장</strong>’이 선순환 되는 건강한 <strong>창업 생태계</strong>를 만들어갑니다.',
    txt04: '드림플러스는 창업부터 사업확장, 글로벌 진출까지 스타트업 생애주기의 전 영역에 걸쳐 솔루션을 제공하는<span>‘<em>대한민국의 유일한 오픈 이노베이션 플랫폼</em>’으로서 드림플러스만의 방식과 노하우로 ‘혁신’과 ‘성장’이 선순환 되는 건강한 창업 생태계를 만들어 가고 있습니다.',
    txt05: '스타트업 A to Z 지원 프로그램',
    txt06: '드림플러스는 창업에서 엑싯 (Exit)에 이르는 스타트업 생애주기 전 과정에 솔루션을 제공하는 액셀러레이팅 프로그램을 통해 청년에게는 창취업을, 스타트업에게는 성장을, 그리고 기업에게는 혁신을 돕습니다.',
    txt07: '이노베이션 센터',
    txt08: '드림플러스 강남 및 드림플러스 63 핀테크센터를 중심으로 중국, 일본, 홍콩, 싱가폴을 비롯한 아시아 주요 거점들로 이노베이션 센터를 확대하여 글로벌을 연결하는 거점으로 활용할 계획입니다.',
    txt09: '스타트업 투자 및 연계',
    txt10: '2015년부터 국내 스타트업 육성펀드 및 해외진출 및 성장펀드에 7천억원을 투자하였으며, 현대자동차, SK텔레콤과 함께 AI 스타트업을 발굴하고 투자하는 AI Alliance Fund를 조성하는 등 매 년 1천억원 이상을 국내외 스타트업에 투자할 계획입니다.',
    txt11: '열린 파트너십',
    txt12: '드림플러스는 업종, 분야, 방식, 규모에 제약을 두지 않고 다양한 영역의 스타트업, 기업 및 기관들 과의 열린 파트너십을 통해 더 건강한 생태계를  만들기 위해 노력하고 있습니다.',
    txt13: '글로벌 네트워크',
    txt14: '드림플러스는 충남창조경제 혁신센터와 함께 스타트업의 해외진출을 지원하고 있으며 한화그룹 계열사를 비롯한 300여 개 이상의 스타트업 네트워크와 250여 개의 글로벌 네트워크를 바탕으로 스타트업의 성장 및 확장을 지원하고 있습니다.',
    txt15: 'No.1 Innovation Platform',
    txt16: '드림플러스는 예비 창업가 지원부터 해외진출까지 스타트업의 성장 전 과정을 지원하는 대한민국 No.1이자 유일한 플랫폼입니다.',
    txt17: '<strong>창업</strong> <span>초기 투자</span> <span>창업 아카데미</span> <span>교육 프로그램</span>',
    txt18: '<strong>초기</strong> <span>인큐베이팅</span> <span>비즈니스 모델 설계</span> <span>사업 지원(Shared Service)</span> <span>멘토링</span> <span>커뮤니티</span>',
    txt19: '<strong>성장기</strong> <span>액셀러레이팅</span> <span>코워킹스페이스</span> <span>마케팅/홍보</span> <span>이벤트/네트워킹</span> <span>사업협력 및 파트너십</span>',
    txt20: '<strong>확장기</strong> <span>전략적 투자</span> <span>투자유치(IR)</span> <span>해외진출</span>',
    txt21: '<strong>Exit</strong> <span>IPO</span> <span>M&amp;A</span>',
    txt22: 'Vertical & Horizontal Connectivity',
    txt23: '드림플러스는 이노베이션 센터를 중심으로 Connectivity 를 강화하고 있습니다.',
    txt24: 'Vertical Connectivity',
    txt25: '혁신성장 생태계의 다양한 참여자들이 모이는 혁신 센터 조성',
    txt26: 'Horizontal Connectivity',
    txt27: '아시아를 비롯한 전세계 주요 거점 별 혁신 Hub들의 연결을 통한 생태계 확장',
    txt28: 'Innovation Centers',
    txt29: '혁신성장 생태계의 거점',
    txt30: '드림플러스는 이노베이션 센터를 통해 검증된 파트너들과 스타트업 생태계의 핵심 플레이어들이 물리적으로 같은 공간에서 동반성장을 위한 <span>원활한 교류와 커뮤니티 생성을 할 수 있도록 지원을 아끼지 않고 있습니다.</span>',
    txt31: 'Our Value',
    txt32: '기업',
    txt33: '<dt>개방형 혁신을 <span>위한 환경</span></dt> <dd>개방형 혁신과 기술 트렌드</dd> <dd>다양한 선도 스타트업 발굴 및 연결</dd>',
    txt34: '투자자',
    txt35: '<dt>성공적인 투자와 육성을 <span>위한 검증된 기회</span></dt> <dd>유망 스타트업 발굴 및 매칭</dd> <dd>다양한 아시아 기업들과의 네트워크</dd>',
    txt36: '스타트업',
    txt37: '<dt>성장과 발전을 위한 <span>최고의 환경</span></dt> <dd>사업개발 및 해외진출</dd> <dd>투자 및 멘토링, 네트워킹</dd>',
    txt38: 'DREAMERS',
    txt39: '<dt>창・취업을 위한 <span>다양한 교육과 지원</span></dt> <dd>교육 프로그램, 창업 아카데미</dd> <dd>대학 연계 스타트업 인턴십 프로그램</dd> <dd>창・취업 관련 이벤트/네트워킹</dd>',
    txt40: 'DREAMPLUS Network',
    txt41: '열린 파트너십과 다양한 네트워크로 건강한 생태계를 만들어 갑니다.<br>드림플러스는 업종, 분야, 방식, 규모에 제약을 두지 않고 다양한 영역의 스타트업, 기업 및 기관들 과의 파트너십을 통해 더 건강한 생태계를 만들어 가고 있습니다.<br>또한 국내외 60개의 한화그룹 계열사와 250여개의 글로벌 네트워크, 300여 곳 이상의 국내 파트너십을 바탕으로 스타트업의 혁신과 성장을 지원합니다.',
    txt42: '지속가능한 <strong>미래 인재 육성</strong>에 앞장섭니다.',
    txt43: '<span>드림플러스는 스타트업을 지원하고 육성하는 액셀러레이팅, 코워킹 스페이스 활동 뿐만 아니라 개개인이 새로운 직업을 발견하고,</span> <span>배우고, 경험할 수 있는 새로운 기회들을 제공하고 있습니다. 또한 불꽃스토리, 불꽃로드 등 다양한 CSR 활동과 쉐어하우스 등의</span> <span>사업을 통해 창업을 넘어 청년 창・취업 지원활동을 대표하는 브랜드가 되고자 합니다.</span>',
    txt44: '드림플러스 포털 바로가기',
    txt45: '드림플러스 아카데미 더 알아보기',
    txt46: '주요활동',
    txt47: 'Accelerating',
    txt48: '(Since 2014)',
    txt49: '스타트업 성장을 지원하기 위한 액셀러레이팅 활동 전개<span>(중국 상하이, 서울 63스퀘어)</span>',
    txt50: 'Co-Working',
    txt51: '(Since 2017)',
    txt52: '스타트업 생태계를 활성화하고 지원하기 위한 허브로서 코워킹 오피스 운영<span>(서울 강남)</span> ',
    txt53: 'Academy',
    txt54: '(June 2018)',
    txt55: '미래인재육성을 위한 다양한 창·취업 지원 아카데미 프로그램 운영',
    txt56: 'DREAMPLUS Portal',
    txt57: '(August 2018)',
    txt58: '꿈을 실현하기 위한 기회와 정보를 제공하기 위한 디지털 플랫폼 구축',
    txt59: 'Co-Living',
    txt60: '(Jan 2019)',
    txt61: '배움, 주거 및 네트워킹이 모두 결합된 쉐어하우스 설립<span>(19년 1월 오픈 예정)</span>',
    txt62: '드림플러스 소개자료(eng) 다운로드',
  },
  News: {
    txt01: '드림플러스 이노베이션 허브의 다양한 소식을 전합니다.',
    txt02: '등록된 뉴스가 없습니다.',
    txt03: '이전글',
    txt04: '이전글이 없습니다',
    txt05: '다음글',
    txt06: '다음글이 없습니다',
    txt07: 'DREAMPLUS와 스타트업 업계 전반에 대한 다양한 읽을거리를 제공 합니다.',
    txt08: '첨부파일',
  },
};
