/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  PlayerDB: {
    txt01: 'companies working together.',
    txt02: 'Company name',
    txt03: 'There is no registered player',
    txt04: '※ You may select up to 2 categories.',
    txt05: '※ You can select up to 5 categories.',
    txt06: 'select',
    txt07: 'Business category',
    txt08: 'Location',
    txt09: 'Main number',
    txt10: 'Website',
    txt11: 'Detailed information',
    txt12: 'Number of employees',
    txt13: 'Amount (in 100m won)',
    txt14: 'Investment company',
    txt15: 'This information is only available after logging in.',
    txt16: '대표자',
  },
  CompanyInfo: {
    txt01: 'Request player registration',
    txt02: 'Company information',
    txt03: 'Service',
    txt04: 'Other',
    txt05: 'Investment Information',
    txt06: 'Consent For inform',
    txt07: 'All information is safely saved and different information will be provided based on the authorization level of your membership.<br />Please enter up-to-date and accurate information and update any changes since this information will be used for contact, networking, investment and information sharing between members who use Player DB.',
    txt08: '회사정보입력',
    txt09: 'Company name',
    txt10: 'Required',
    txt11: 'Company name (Eng)',
    txt12: 'You can enter up to 20 characters.',
    txt13: 'Company logo',
    txt14: 'Select company logo',
    txt15: 'Only image files are allowed. Please check file type.',
    txt16: 'Only 160*160 JPG, JPEG, PNG and GIF files are allowed.<span>If there is no logo registered, DREAMPLUS default image will be displayed.</span>',
    txt17: 'Company introduction',
    txt18: 'Enter company introduction',
    txt19: 'You can enter up to 40 characters.',
    txt20: 'Select Business Category',
    txt21: 'Business category is required. Please select at least 1 category.',
    txt22: 'You can select 1 to 2 business categories.',
    txt23: 'Move-in information',
    txt24: 'Tenant',
    txt25: 'Gangnam',
    txt26: 'Past Tenant',
    txt27: 'ACC Participation information',
    txt28: 'Currently under ACC',
    txt29: 'ACC Alumni',
    txt30: 'Year of establishment',
    txt31: 'Enter year',
    txt32: 'Enter month',
    txt33: 'Number of employees',
    txt34: 'Select number of employees',
    txt35: 'Telephone number',
    txt36: 'Country code',
    txt37: 'Area code',
    txt38: 'Email',
    txt39: 'Enter Email',
    txt40: 'Address',
    txt41: 'Enter postal code',
    txt42: 'Enter address',
    txt43: 'Website',
    txt44: 'Enter website URL',
    txt45: 'Enter Social Media URL ',
    txt46: '페이스북 URL 입력',
    txt47: 'Enter company name (Kor)',
    txt48: 'Enter company name (Eng)',
    txt49: 'Enter telephone number (Do not include "-")',
    txt50: 'Not Applicable',
    txt51: 'Country',
    txt52: 'City',
    txt53: 'Required field',
    txt54: 'Business registration number',
    txt55: 'Enter business registration number (Do not include "-")',
    txt56: 'Business registration number is not disclosed.',
    txt57: 'Representative',
    txt58: 'Enter representative\'s name',
    txt59: 'Select country code',
    txt60: 'Please enter your business registration number.',
    txt61: 'Enter website URL is required field',
  },
  ServiceInfo: {
    txt01: 'Service name',
    txt02: 'Company name and service name are the same.',
    txt03: 'Enter service name (Kor)',
    txt04: 'Enter service name (Eng)',
    txt05: 'Service name (Eng)',
    txt06: 'Service logo',
    txt07: 'Select service logo',
    txt08: 'Service introduction',
    txt09: 'Enter service introduction',
    txt10: 'Select Service Category',
    txt11: 'Service Category is mandatory.Please select at least one.',
    txt12: 'You can select at least 1, up to 5 service categories.',
    txt13: '다운로드',
    txt14: '애플 앱스토어 다운로드 URL 입력',
    txt15: '구글 플레이스토어 다운로드 URL 입력',
    txt16: 'Add service',
  },
  ContactInfo: {
    txt01: '대표자 추가',
    txt02: '대표자 입력',
    txt03: '대표자를 컨택포인트로 지정',
    txt04: 'Enter {name} name of person in charge',
    txt05: '첫번째번호 입력',
    txt06: '가운데번호 입력',
    txt07: '마지막번호 입력',
    txt08: 'contact number',
    txt09: '내선번호',
    txt10: '휴대폰 번호는 한화 직원에게만 공개됩니다.',
    txt11: 'Enter {name} email of person in charge',
    txt12: '{name} name, {name} contact (business phone number, cellphone number) and {name} email are required fields.',
    txt13: 'You can send and receive messages with other players by entering your contact points.',
    txt14: 'You can check the received message with My Page.',
  },
  InvestmentInfo: {
    txt01: 'Enter investment information',
    txt02: 'Investment Information',
    txt03: 'Investment stage',
    txt04: 'Year',
    txt05: 'Month',
    txt06: 'Enter investment amount',
    txt07: 'Undisclosed',
    txt08: 'Enter amount in KRW 100 millions.',
    txt09: 'Enter investment company',
    txt10: 'If there are multiple investment companies, use comma (,) to separate them.',
    txt11: 'Add investment information',
    txt12: 'January',
    txt13: 'February',
    txt14: 'March',
    txt15: 'April',
    txt16: 'May',
    txt17: 'June',
    txt18: 'July',
    txt19: 'August',
    txt20: 'September',
    txt21: 'October',
    txt22: 'November',
    txt23: 'December',
  },
  AgreementInfo: {
    txt01: 'Personal Information Collection and Use Agreement',
    txt02: '<strong>1. Collected items</strong><ul><li>- Name of POC personnel, Contact, email address.</li></ul><strong>2. Objective</strong><ul><li>- To provide DREAMPLUS Player DB service to members.</li></ul><strong>3. Retention and Period of Use</strong><ul><li>- Until the termination of service</li><li>* All information will be deleted immediately when the Player DB is deleted.</li></ul>',
    txt03: 'I agree to the collection and use of my personal information to use the Player DB service.',
    txt04: 'Request Registration',
  },
  PlayerRegistry: {
    txt01: 'Player registration has been requested.',
    txt02: 'Player registration will be processed after Player DB manager\'s review.<br> Once the review is complete, you will be notified.',
    txt03: 'Go to Player DB List',
  },
  PlayerTemporary: {
    txt01: 'You have a draft player registration request. ',
    txt02: 'You may register a new player after completing and  <br>registering the draft.',
  },
  Opportunities: {
    txt01: 'This is where you can have discussions with DREAMPLUS members on business collaboration, investment, overseas expansion, service/strategic partnership, etc.',
    txt02: 'Register Request Inquiry',
    txt03: 'Select Category',
    txt04: 'Enter title',
    txt05: 'Only to administrator post',
    txt06: 'Service Category',
  },
  OpportunityRegistry: {
    txt01: 'Modify Request Inquiry',
    txt02: 'Category',
    txt03: 'Disclosure',
    txt04: 'Disclose',
    txt05: 'Only to administrator',
    txt06: 'Title',
    txt08: 'You must enter a title.',
    txt09: 'You can select up to 5 categories.',
    txt10: 'Attachment',
    txt11: 'Select File',
    txt12: 'File attachment failed. Please check the file size.',
    txt13: 'Up to 3 files can be attached, and the size of one file should be smaller than 20MB.',
    txt14: 'Contents',
    txt15: 'You must enter content.',
  },
  Message: {
    txt01: 'To',
    txt02: 'Message',
    txt03: 'You can enter up to 300 characters.',
    txt04: 'Please enter message.',
    txt05: 'Please check the file extension.',
    txt06: 'File attachment failed. Please check the file size.',
    txt07: 'Only ppt, pptx, pdf, hwp, doc, docx and xls files are allowed.',
    txt08: 'File size is limited to 10MB.',
    txt09: 'Send',
  },
};
