export default {
  /**
   * 로그인 Route Name
   */
  ROUTE_NAME_SIGNIN: 'SignIn',

  /**
   * 회원가입 Route Name
   */
  ROUTE_NAME_SIGNUP: 'SignUp',
  ROUTE_NAME_EVENTLIST: 'EventList',
  /**
   * 로그아웃 Route Name
   */
  ROUTE_NAME_SIGNOUT: 'SignOut',

  /**
   * 404 (Page not found) 페이지 Route Path
   */
  ROUTE_PATH_NOT_FOUND: '/404',
  /**
   * 네이버 아이디로 로그인 - 콜백 (로그인) Route Path
   */
  ROUTE_PATH_NAVER_CALLBACK_SIGNIN: '/signin/navercallback',
  /**
   * 네이버 아이디로 로그인 - 콜백 (회원가입) Route Path
   */
  ROUTE_PATH_NAVER_CALLBACK_SIGNUP: '/signup/type/navercallback',
};
