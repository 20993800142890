/** letter */
import { container, append } from '@/common/helperUtils';

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

export default [
  {
    path: '',
    redirect: 'list',
  },
  {
    path: 'list',
    name: 'LetterList',
    component: () => import(`@/views/letter/${appendName}LetterList.vue`),
    meta: {
      layout: container,
      title: 'Letter',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: 'detail/:letterId',
    name: 'LetterDetail',
    component: () => import(`@/views/letter/${appendName}LetterDetail.vue`),
    meta: {
      layout: container,
      title: 'Letter',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: 'read',
    name: 'LetterRead',
    component: () => import(`@/views/letter/${appendName}LetterRead.vue`),
    meta: {
      layout: container,
      title: 'Letter',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: 'msg',
    name: 'LetterMsg',
    component: () => import(`@/views/letter/${appendName}LetterMsg.vue`),
    meta: {
      layout: container,
      title: 'Letter',
      metaInfo: noindexMetaInfo
    },
  },
];
