import { container, append } from '@/common/helperUtils';

const appendName = append;

/** Center */
export default [
  {
    path: '',
    name: 'Center',
    redirect: 'square',
  },
  /** Center - 63 Fintech */
  {
    path: 'square',
    name: 'Square',
    component: () => import(`@/views/center/${appendName}Square.vue`),
    meta: {
      layout: container,
      title: '63 Fintech | Center',
    },
  },
  /** Center - Gangnam */
  {
    path: 'gangnam',
    component: () => import(`@/views/center/gangnam/${appendName}Gangnam.vue`),
    redirect: 'gangnam/intro',
    children: [
      {
        path: 'intro',
        name: 'GangnamIntro',
        component: () => import(`@/views/center/gangnam/${appendName}Intro.vue`),
        meta: {
          layout: container,
          title: 'Gangnam | Center',
        },
      },
      {
        path: 'reservation',
        name: 'GangnamReservation',
        component: () => import(`@/views/center/gangnam/${appendName}GangnamReservation.vue`),
        meta: {
          layout: container,
          title: 'Gangnam Reservation | Center',
        },
      },

    ],
  },
  /** Center - Global */
  {
    path: 'global',
    name: 'Global',
    component: () => import(`@/views/center/${appendName}Global.vue`),
    meta: {
      layout: container,
      title: 'Global | Center',
    },
  },
  {
    path: 'intranetvisitor',
    meta: {
      layout: container,
      title: 'GangnamReservation',
    },
    component: () => import(`@/views/center/gangnam/GangnamReservation${appendName}.vue`),
  },
];
