/* eslint-disable max-len */
export default {
  Application: {
    btn: {
      txt01: 'Apply for program',
      txt02: 'program in progress program in progress program in progress program in progress',
    },
    Tab: {
      Apply: 'Apply for program',
      List: 'History of competition participation',
    },
  },
  with_hanwha: {
    program_desc: `DREAMPLUS provides chances of growth for startups, innovation for companies, and individual's
    <br />job opportunity and entrepreneurship for the general public.`,
    contact: 'Contact: ',
    main_program: 'MAIN PROGRAM',
    program_benefit: 'Program Benefit',
    participation_example: 'Program Participation Example',
    main: {
      meetup_desc: `We offer business cooperation opportunities through
      <br />regular meetups between startups and relevant personnel.`,
      poc_desc: `We provide practical POC opportunities by discovering
      <br />innovation needs within the company.`,
      tenants_desc: `We support business cooperation, partner linkage,
      <br />and investment attraction for tenants of GANGNAM CENTER.`,
      demoday_title: `DEMONSTRATION DAY
      <br />(DEMO DAY)`,
      demoday_desc: `We host annual IR pitching event for business connection
      <br />and investment attraction for startups selected to
      <br />cooperate with DREAMPLUS.`,
    },
    benefit: {
      business_desc: `We support the growth of startups by providing
      <br />business cooperation/PoC opportunities`,
      networking_desc: `We offer DREAMPLUS Demonstration Day
      <br />and many other networking events`,
      pr_desc: `We provide advertisement and marketing
      <br />opportunities by using diverse channels`,
    },
    example: {
      agilesoda: {
        company: 'AGILESODA',
        participant: 'DREAMPLUS FINTECH PROGRAM PARTICIPANT',
        description: `“I was very happy that I could get a variety of opportunities offered by the
        <br />Gangnam Center, which also is more comfortable than other accelerating spaces.
        <br />I actually received a big business opportunity through the program.
        <br />In particular, through AI projects cooperated with Hanwha Life Insurance, my company was able to improve claim process for the first in the world using the reinforcement learning.
        <br />Thank you very much for trusting in Agile Soda and giving us the chance to take on
        <br />new challenges.”`,
        ceo: 'AGILESODA CEO Choi, Dae-woo',
      },
      petner: {
        company: 'PETNER',
        participant: 'HANWHA LIFE INSURANCE LINKED-POC PROGRAM PARTICIPANT',
        description: `“Meeting DREAMPLUS served as a turning point for PETNER.
        <br />I especially liked the opportunity to cooperate with Hanwha Life
        <br />Insurance and Hanwha General Insurance.
        <br />In fact, PETNER created and introduced pet sitter insurance for the
        <br />first time in the industry with Hanwha General Insurance.
        <br />We also discovered new business in finance by providing
        <br />a dog matching service with Hanwha Life Insurance.”`,
        ceo: 'PETNER CEO CHOI, GARAM',
      },
    },
  },
  with_partner: {
    program_desc: `We support the entire process of designing, recruiting, and fostering programs that meet the needs of companies so that companies
    <br />can promote open innovation more quickly and efficiently in collaboration with partners specialized in each field.`,
    partners: 'Major Partner Companies',
    program_benefit: 'Program Benefit',
    selection_process: 'Selection Process',
    participation_example: 'Program Participation Examples',

    benefit: {
      support_desc: `We provide PoC opportunities, office spaces,
      <br />and dedicated accelerating.`,
      networking_desc: `We provide a variety of networking chances
      <br />with DREAMPLUS eco system participants.`,
      investment_desc: `We offer opportunities to attract investment
      <br />utilizing domestic and international VC networks.`,
    },
    process: {
      step01: `Application Document
      <br />Registration`,
      step02: `1st Application Document
      <br />Screening`,
      step03: `2nd Application Document
      <br />Screening`,
      step04: 'Result Announcement',
    },
    example: {
      welt: {
        company: 'WELT',
        participant: 'DREAMPLUS Lifestyle Accelerating Program Participants',
        description: `“We are not only able to use the premium co-working space, but receive chances to
        <br />secure the best members, increase work satisfaction, and succeed to sign on deals
        <br />with numerous domestic and international clients.
        <br />On top of that, we sat down with Hanwha Group affiliates in finance, leisure, distribution
        <br />and defense industries to discuss about potential cooperation without any difficulty.
        <br />It is literally the best environment ever to work in."`,
        ceo: 'WELT CEO Kang, Sung-ji',
      },
      korea_startup_forum: {
        company: 'KOREA STARTUP FORUM',
        participant: 'DREAMPLUS Partner Companies',
        description: `"Hanwha DREAMPLUS is a reliable partner of the Korea Startup Forum! Both of us promise
        <br />to work on our mission of 'Innovating the world by helping the growth of startups.'
        <br />Since then, we have closely cooperated to host training and
        <br />networking programs for entrepreneurs.
        <br />In the process, we were able to expand opportunities for startups to exchange in
        <br />various fields in the Gangnam Center, and discover possible cooperation areas
        <br />with ecosystem stakeholders.”`,
        ceo: 'KOREA STARTUP FORUM CEO Choi, Sung-Jin',
      },
      rocketview: {
        company: 'ROCKETVIEW',
        participant: 'DREAMPLUS X ELAND RETAIL TECH PROGRAM Participants',
        description: `“We participated in not only the E-Land Accelerator Program, but also DREAMPLUS DEMO
        <br />DAY and received lots of help and support from our dedicated manager to find right
        <br />programs that meet our team.
        <br />Since we were introduced to a team that can generate synergy with us, we have been
        <br />working actively together until today."`,
        ceo: 'ROCKETVIEW CEO Kim, Hwa-Kyeong',
      },
      designovle: {
        company: 'DESIGNOVLE',
        participant: 'DREAMPLUS X ELAND RETAIL TECH PROGRAM Participants',
        description: `“We have received lots of helps from Hanwha DREAMPLUS.
        <br />They have offered us events and experience programs including IR document preparation,
        <br />investor meeting, and introduction of client, many of which would have never been
        <br />realized without DREAMPLUS.
        <br />I was able to launch AI-based fashion goods with the help of E-Land Retail, and currently
        <br />figuring out the possibilities to go abroad with Company M.
        <br />In addition, our growth has gotten momentum after joining Hanwha DREAMPLUS
        <br />as we won the Challenge K-Startup competition with Presidential prize."`,
        ceo: 'DESIGNOVLE CEO Shin, Ki-Young',
      },
    },
    alumni: {
      lifestyle_01: 'LIFESTYLE Group 1',
      lifestyle_02: 'LIFESTYLE Group 2',
    },
  },
  internship: {
    internship_desc: `DREAMPLUS lays the foundation of cooperative structure among public institutes, universities,
    <br />and large corporations through open partnership and matches startups and capable talents.
    <br />By doing so, it contributes to creating jobs and expanding its role as a facilitator for a healthier ecosystem.`,
    youth_tlo: 'Youth TLO Startup Internship',
    youth_tlo_desc: `Operating 6-month Youth TLO internship with Commercializations Promotion Agency for
    <br />R&D Outcomes(COMPA) for college graduates`,
    college_internship: 'Startup internship for college students',
    selection_process: 'Selection Process',
    academy_desc: `We operate a variety of academy programs about trendy topics for the general public which is planned with professional partners of each area.
    <br />It allows us to support individuals to run their own business and to encourage them to take part in the innovation growth ecosystem.`,
    academy_image: 'Academy Activity Image',
    colleges: {
      yonsei: 'DREAMPLUS X YONSEI',
      yonsei_desc: 'Startup Internship (Management)',
      ewha: 'DREAMPLUS X EWHA',
      ewha_desc: 'Startup Internship (Design)',
      hufs: 'DREAMPLUS X HUFS',
      hufs_desc: 'Startup Internship (Research)',
    },
    process: {
      step01: `Recruitment of
      <br />Participating Companies`,
      step02: `Internship Recruitment
      <br />Notice`,
      step03: 'Interview',
      step04: `Matching Support with
      <br />DREAMPLUS`,
      step05: 'Final Selection',
    },
    academy: {
      blockchain_desc: `Learning future technologies and nurturing
      <br />professional developers (2018)`,
      fashion_goods_desc: `Specialized education to nurture and support
      <br />designer brand (2019)`,
      fNb_with: 'with Jang Jin Woo Academy',
      fNb_desc: `Education for potential entrepreneurs in
      <br />F&D industry (2018)`,
      onboarding_desc: `Professional education of startup app
      <br />marketing (2019)`,
      contents_with: 'with YES24/PLADI',
      contents_desc: `Specialized education for empowering
      <br />content creator (2019)`,
      ios_desc: 'iOS Developer Academy(2020)',
    },
  },
};
