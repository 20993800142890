/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Type: {
    txt01: 'Sign up with email',
    txt02: 'Sign up with email +',
    txt03: 'Sign up with social media account',
    txt04: 'Hanwha employees can sign up with your Hanwha email only.',
    txt05: 'DREAMPLUS tenants must request the DREAMPLUS Center manager to register their accounts first.',
  },
  UserInfo: {
    txt01: 'Enter email address to use as your ID',
    txt02: 'Membership confirmation',
    txt03: 'Your email address will be used for login and authentication purposes.',
    txt04: 'Please confirm the membership through e-mail.',
    txt05: 'Enter nickname',
    txt06: 'Check for duplicate IDs',
    txt07: 'You can only use Korean/English characters and numbers for your nickname.',
    txt08: 'Check for duplicate nicknames ',
    txt09: 'Enter password',
    txt10: 'Password must have 8-15 characters with a combination of English alphabet, numbers and special characters.',
    txt11: 'Check password',
    txt12: 'Please check your password.',
    txt13: 'Partners ',
    txt14: 'Check only if you’re a partner.',
    txt15: 'Partner members can complete the sign-up process after manager\'s approval.',
    txt16: '중복되는 이메일이 존재합니다.',
  },
  Agreement: {
    txt01: 'I agree to all terms and conditions',
    txt02: 'Terms and Conditions of Membership',
    txt03: 'Collection and Use of Personal Information',
    txt04: 'Provision of Information to a Third Party for Single Sign On (Optional)',
    txt05: 'Content',
    txt06: 'Collection and Use of Personal Information(Mandatory)',
    txt07: 'Privacy Policy',
    txt08: 'Age Requirements(14+)',
    txt09: 'Entrustment of Personal Information',
  },
  Auth: {
    txt01: 'Congratulations on becoming a member of DREAMPLUS Innovation Hub.',
    txt02: `Check the email sent to<span>[{email}]</span>
    <span class="txt03">o complete the process.</span>`,
    txt03: 'Re-send authentication email',
    txt04: 'If the e-mail is not sent, please click re-sending the verification e-mail.',
    txt05: 'Depending on e-mail service, the arrival of the e-mail might be delayed a bit.',
    txt06: 'If the e-mail is not sent for long, please check your spam mail box.',
    txt07: 'If the e-mail is not sent even after using the methods as above, please contact our manager (<a href="mailto:contact@dreamplus.asia" class="mail">contact@dreamplus.asia</a>)',
  },
  OauthUserInfo: {
    txt01: 'Proceed with membership process with<span>[{name}]</span>',
    txt02: 'Notification and important news will be sent to this E-mail.',
    txt03: 'Nickname',
  },
  popup: {
    DuplicatedEmail: {
      txt01: 'Notification of membership',
      txt02: '<span>[{snsName}]</span> This is the email registered as a Dream Plus portal member.',
      txt03: 'Please use E-mail Log-in.',
      txt04: 'This is E-mail account registered with<span>[{snsName}]</span>',
      txt05: 'Please use <span>[{snsName}]</span> Login',
      txt06: '<span>[{email}]</span> is an already registered e-mail on Innovation Hub.',
      txt07: 'If you have not joined the membership, please contact to our manager <a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">(contact@dreamplus.asia)</a>',
    },
    DuplicatedName: {
      txt01: 'This name is already in use.',
      txt02: 'Please enter a different name.',
      txt03: 'This name is available.',
      txt04: 'Please enter a name you will use.',
    },
    IntegratedUser: {
      txt01: 'Providing personal information to third party',
      txt02: `<li>1. Receiver of personal information: Hanwha Corporation</li>
      <li>2. Goal of utilizing personal information of personal information receiver: providing the service of jointly using the member's log-in information which enables using the online services of DREAM PLUS Innovation Hub without log-in</li>
      <li>3. Personal information items provided : e-mail ID</li>
      <li>
        <span>4. Period of retaing and utilizing collected personal information</span><br>
        - Period of providing the service starting from the date of consent<br>
        However, even after the goal of collection of or provision is acheived, the Company may store personal information if neceesary according to code of laws including commercial laws.
      </li>
      <li>
        <span>5. Notification of rights to refuse and disadvantages for refusal</span><br>
         - You have the right to refuse to give consent on the items as above. You can withdraw the consent you provided at any time.<br><br>

        * However, if you refuse to give consent, there could be limitations in using the Service (limitations in Single Sign-On).
      </li>`,
    },
    PersonalInfo: {
      txt01: '개인정보 처리방침',
      txt02: '한화생명보험주식회사(이하 ‘회사’라 함) 드림플러스 홈페이지(이하 "홈페이지"라 함)는 이용자의 개인정보를 보호하기 위하여 최선을 다하고 있으며, 이를 위해 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’, ‘개인정보보호법’ 등 관련 법령에 따라 아래와 같이 개인정보처리방침을 수립하여 시행하고 있습니다.',
      txt03: '개인정보처리방침은 개인정보가 어떻게 보호조치가 되고 있는지 알려드리는 기본지침으로서 관련 법령 및 회사 내부운영방침의 변경에 따라 수시로 변경될 수 있습니다. 만약 개인정보처리방침을 변경하는 경우에는 홈페이지를 통해 공지하고 있습니다.',
      txt04: '1. 수집하는 개인정보 항목 및 이용 목적',
      txt05: '가. 수집∙이용 목적 ',
      txt06: '1) 회원 관리',
      txt07: '- 회원가입 및 불량회원의 부정이용 및 비인가 사용방지, 민원처리, 고지사항 등',
      txt08: '2) 서비스 제공에 대한 이행 ',
      txt09: '- 이벤트 참여 및 신청, 이벤트홀 대관, 공모 접수, 방문 투어 신청 등',
      txt10: '나. 수집 항목 ',
      txt11: '1) 회원의 경우',
      txt12: '- 회원명, 이메일(아이디), 비밀번호, 홈페이지 접속정보 및 이용정보, 쿠키, 접속 IP정보, 방문일시, 기타 공모를 위한 정보',
      txt13: '2) 비회원의 경우',
      txt14: '- 홈페이지 접속정보 및 이용정보, 쿠키, 접속 IP정보, 방문일시, 휴대폰 번호',
      txt15: '다. 수집 방법',
      txt16: '- 홈페이지 접속 및 로그인 시 SNS 채널 별 제공',
      txt17: '2. 개인정보의 보유 및 이용기간',
      txt18: '이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기(복원이 불가능한 방법으로 영구 삭제)합니다. 장기 미로그인(로그인을 하지 않고 일정기간(1년)경과) 회원 정보 또한 파기됩니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.',
      txt19: '가. 회사 내부 방침에 의한 정보보유 사유',
      txt20: '- 부정이용기록 보존 이유 : 부정 이용방지(보존 기간 1년)',
      txt21: '나. 관련법령에 의한 정보보유 사유',
      txt22: '상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.',
      txt23: '1) 웹사이트 방문기록 : 통신비밀보호법(보존 기간 3개월) ',
      txt24: '3. 개인정보의 제3자 제공에 관한 사항',
      txt25: '홈페이지에서 수집된 개인정보는 회사의 업무위탁과 관계없이는 원칙적으로 외부에 제공하지 않습니다.',
      txt26: '다만, 다음의 경우에는 예외로 합니다.',
      txt27: '가. 이용자들이 사전에 동의한 경우',
      txt28: '나. 기타 법률의 규정에 의지하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우',
      txt29: '4. 개인정보 처리를 위탁한 업무의 내용 및 위탁받은 자',
      txt30: '회사는 원활한 서비스 이행을 위해 개인정보의 처리 처리를 위탁하고 있으며 개인정보 처리를 위탁 받은 업체는 위탁 받은 업무의 수행에 필요한 최소한의 범위내에서 개인정보를 처리하게 됩니다.',
      txt31: '개인정보처리를 위탁한 업무의 내용 및 위탁받은 자는 다음과 같습니다.',
      txt32: '위탁하는 업무의 내용 위탁받은 자 연락처',
      txt33: '위탁받은 자',
      txt34: '연락처',
      txt35: '홈페이지 유지보수, 홈페이지를 통한 ',
      txt36: '개인정보의 수집 · 보관, 이메일 발송, SSO 처리 등',
      txt37: '한화시스템',
      txt38: '054-560-8800',
      txt39: '5. 개인정보를 자동으로 수집하는 장치(쿠키 등)의 설치·운영 및 거부에 관한 사항',
      txt40: '회사 홈페이지는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다.',
      txt41: '가. 쿠키(cookie) 등 사용목적',
      txt42: '이용자의 접속 빈도나 방문시간, 방문횟수, 관심 분야 파악, 각 종 이벤트 참여 정도 등을 파악하여 맞춤형 마케팅 및 서비스를 제공합니다.',
      txt43: '나. 쿠키(cookie) 설정 거부 방법',
      txt44: '이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나 혹은 모든 쿠키의 저장을 거부할 수도 있습니다.',
      txt45: '※ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구>인터넷 옵션 >개인정보 단,고객이 쿠키 설치를 거부하는 경우 일부 서비스 제공에 어려움이 있을 수 있습니다.',
      txt46: '6. 정보주체의 권리•의무 및 그 행사방법에 관한 사항',
      txt47: '가. 개인정보의 열람',
      txt48: '1) 정보주체는 회사가 처리하는 자신의 개인정보에 대하여 개인정보 열람요구서를 회사에 제출하는 등의 방법으로 열람을 요구할 수 있습니다.',
      txt49: '2) 회사는 열람을 요구받으면 그로부터 10일 이내에 정보주체가 해당 개인정보를 열람할 수 있게 합니다. 만약 해당 기간 내에 열람할 수 없는 사유가 있을 때에는 요구를 받은 날부터 10일 이내에 정보주체에게 그 사유 및 이의제기방법을 알리고 열람을 연기할 수 있으며, 그 사유가 소멸한 경우에는 정당한 사유가 없는 한 사유가 소멸한 날로부터 10일 이내에 열람하도록 할 수 있습니다.',
      txt50: '3) 회사는 다음의 어느 하나에 해당하는 경우 정보주체에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다. 이 경우 열람이 제한되는 사항을 제외한 부분은 열람할 수 있으며, 열람 요구를 받은 날부터 10일 이내에 그 사유 및 이의제기방법을 알려드립니다.',
      txt51: '- 법률에 따라 열람이 금지되거나 제한되는 경우',
      txt52: '- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우',
      txt53: '나. 개인정보의 정정•삭제',
      txt54: '1) 개인정보를 열람한 정보주체는 개인정보 정정•삭제 요구서를 회사에 제출하는 등의 방법으로 그 개인정보의 정정 또는 삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.',
      txt55: '2) 회사는 정보주체의 요구를 받은 경우 개인정보의 정정 또는 삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우를 제외하고는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 그 개인정보를 조사하여 정보주체의 요구에 따라 정정•삭제 등 필요한 조치를 한 후 그 결과를 알려드립니다.',
      txt56: '3) 회사는 다음의 어느 하나에 해당하는 경우 정당한 사유가 없는 한 정보주체의 요구를 받은 날로부터 10일 이내에 정정•삭제를 할 수 없는 사유와 이의제기방법을 알려드립니다.',
      txt57: '- 법률에 따라 열람이 금지되거나 제한되는 경우',
      txt58: '- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우',
      txt59: '다. 개인정보의 처리 정지',
      txt60: '1) 정보주체는 회사에 대하여 개인정보 처리정지 요구서를 회사에 제출하는 등의 방법으로 자신의 개인정보 처리의 정지를 요구할 수 있습니다.',
      txt61: '2) 회사는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 정보주체의 요구에 따라 개인정보 처리의 전부를 정지하거나 일부를 정지하고, 처리가 정지된 개인정보에 대하여 처리정지의 요구를 받은 날로부터 10일 이내에 필요한 조치를 하여야 합니다. 다만, 다음의 어느 하나에 해당하는 경우에는 정보주체의 처리정지 요구를 거절할 수 있으며, 이 경우 정보주체에게 요구를 받은 날로부터 10일 이내에 그 사유를 알려주어야 합니다.',
      txt62: '- 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우',
      txt63: '- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우',
      txt64: '- 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우',
      txt65: '라. 권리행사의 방법 및 절차',
      txt66: '회사는 개인정보의 수집 시에 요구되지 않았던 증빙서류 등을 요구하거나 추가적인 절차를 요구하지 않습니다. 정보주체는 개인정보의 열람, 정정•삭제, 처리정지 요구를 대리인(정보주체의 법정대리인, 정보주체로부터 위임을 받은 자)에게 하게 할 수 있습니다. 이 경우, 대리인은 회사에 정보주체의 위임장을 제출하여야 합니다.',
      txt67: '7. 개인정보보호를 위한 기술적•관리적 보호대책',
      txt68: '회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 안정성 확보를 위하여 다음과 같은 기술적•관리적 대책을 강구하고 있습니다.',
      txt69: '가. 기술적 대책',
      txt70: '다음의 기술적 대책에도 불구하고 해커의 침입 및 신종 바이러스 침투와 같은 사고 발생 당시의 기술로 예방, 조치할 수 없는 불가항력 사유로 인하여 발생한 개인정보의 분실, 도난, 유출,  변조 또는 훼손에 대하여는 회원에게 책임을 지지 않습니다.',
      txt71: '1) 회원의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 보호하고 있습니다.',
      txt72: '2) 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안 장치를 채택하고 있습니다.',
      txt73: '3) 해킹 등 외부 침입에 대비하여 취약점 분석 시스템 등을 이용하여 보안에 만전을 기하고 있습니다.',
      txt74: '나. 관리적 대책',
      txt75: '회사는 개인정보처리 직원을 최소한으로 제한하고 담당 직원에 대한 수시교육을 통하여 본 방침의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 시정조치하고 있습니다.',
      txt76: '1) 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.',
      txt77: '2) 그 외 내부관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 당사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.',
      txt78: '8. 개인정보보호 책임자',
      txt79: '회사는 수집된 이용자의 개인정보 관리를 위해 개인정보보호 책임자를 다음과 같이 지정하여 관리하고 있으며, 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.',
      txt80: '가. 개인정보보호 책임자',
      txt81: '1) 담당자 : 조상현',
      txt82: '2) 직책 : 개인정보보호책임자(CPO)',
      txt83: '3) 전화번호 : 080-789-8114',
      txt84: '4) e-mail : <a href="mailto:privacy.life@hanwha.com">privacy.life@hanwha.com</a>',
      txt85: '나. 개인정보보호 담당자',
      txt86: '1) 담당자 : IT 보안파트 개인정보보호 담당자',
      txt87: '2) 전화번호 : 080-789-8114',
      txt88: '3) e-mail : <a href="mailto:privacy.life@hanwha.com">privacy.life@hanwha.com</a>',
      txt89: '※ 개인정보보호처리방침 시행일자: 2020.11.23',
      txt90: '※ 개인정보처리방침 버전번호: V.2',
    },
    AgeInfo: {
      txt01: '1) 만 14세 미만 아동의 개인정보는 수집하고 있지 않지만 연령확인 체크 필요',
      txt02: '만 14세 미만은 가입이 불가합니다.',
    },
    PersonalInfoUse: {
      txt01: '개인정보 처리방침',
      txt02: '한화생명보험주식회사(이하 ‘회사’라 함) 드림플러스 홈페이지(이하 "홈페이지"라 함)는 이용자의 개인정보를 보호하기 위하여 최선을 다하고 있으며, 이를 위해 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’, ‘개인정보보호법’ 등 관련 법령에 따라 아래와 같이 개인정보처리방침을 수립하여 시행하고 있습니다.',
      txt03: '개인정보처리방침은 개인정보가 어떻게 보호조치가 되고 있는지 알려드리는 기본지침으로서 관련 법령 및 회사 내부운영방침의 변경에 따라 수시로 변경될 수 있습니다. 만약 개인정보처리방침을 변경하는 경우에는 홈페이지를 통해 공지하고 있습니다.',
      txt04: '1. 수집하는 개인정보 항목 및 이용 목적',
      txt05: '가. 수집∙이용 목적',
      txt06: '1) 회원 관리',
      txt07: '- 회원가입 및 불량회원의 부정이용 및 비인가 사용방지, 민원처리, 고지사항 등',
      txt08: '2) 서비스 제공에 대한 이행',
      txt09: '- 이벤트 참여 및 신청, 이벤트홀 대관, 공모 접수, 방문 투어 신청 등',
      txt10: '',
      txt11: '나. 수집 항목',
      txt12: '1) 회원의 경우',
      txt13: '- 회원명, 이메일(아이디), 비밀번호, 홈페이지 접속정보 및 이용정보, 쿠키, 접속 IP정보, 방문일시, 기타 공모를 위한 정보',
      txt14: '2) 비회원의 경우',
      txt15: '- 홈페이지 접속정보 및 이용정보, 쿠키, 접속 IP정보, 방문일시, 휴대폰 번호',
      txt16: '',
      txt17: '다. 수집 방법',
      txt18: '- 홈페이지 접속 및 로그인 시 SNS 채널 별 제공',
      txt19: '2. 개인정보의 보유 및 이용기간',
      txt20: '이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기(복원이 불가능한 방법으로 영구 삭제)합니다. 장기 미로그인(로그인을 하지 않고 일정기간(1년)경과) 회원 정보 또한 파기됩니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.',
      txt21: '',
      txt22: '가. 회사 내부 방침에 의한 정보보유 사유',
      txt23: '- 부정이용기록 보존 이유 : 부정 이용방지(보존 기간 1년)',
      txt24: '',
      txt25: '나. 관련법령에 의한 정보보유 사유',
      txt26: '상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.',
      txt27: '1) 웹사이트 방문기록 : 통신비밀보호법(보존 기간 3개월)',
      txt28: '3. 개인정보의 제3자 제공에 관한 사항',
      txt29: '홈페이지에서 수집된 개인정보는 회사의 업무위탁과 관계없이는 원칙적으로 외부에 제공하지 않습니다.',
      txt30: '다만, 다음의 경우에는 예외로 합니다.',
      txt31: '',
      txt32: '가. 이용자들이 사전에 동의한 경우',
      txt33: '',
      txt34: '나. 기타 법률의 규정에 의지하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우',
      txt35: '',
      txt36: '4. 개인정보 처리를 위탁한 업무의 내용 및 위탁받은 자',
      txt37: '회사는 원활한 서비스 이행을 위해 개인정보의 처리 처리를 위탁하고 있으며 개인정보 처리를 위탁 받은 업체는 위탁 받은 업무의 수행에 필요한 최소한의 범위내에서 개인정보를 처리하게 됩니다.',
      txt38: '개인정보처리를 위탁한 업무의 내용 및 위탁받은 자는 다음과 같습니다.',
      txt39: '위탁하는 업무의 내용 위탁받은 자 연락처',
      txt40: '홈페이지 유지보수, 홈페이지를 통한',
      txt41: '개인정보의 수집 · 보관, 이메일 발송, SSO 처리 등',
      txt42: '',
      txt43: '5. 개인정보를 자동으로 수집하는 장치(쿠키 등)의 설치·운영 및 거부에 관한 사항',
      txt44: '회사 홈페이지는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다.',
      txt45: '',
      txt46: '가. 쿠키(cookie) 등 사용목적',
      txt47: '이용자의 접속 빈도나 방문시간, 방문횟수, 관심 분야 파악, 각 종 이벤트 참여 정도 등을 파악하여 맞춤형 마케팅 및 서비스를 제공합니다.',
      txt48: '',
      txt49: '나. 쿠키(cookie) 설정 거부 방법',
      txt50: '이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나 혹은 모든 쿠키의 저장을 거부할 수도 있습니다.',
      txt51: '※ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구>인터넷 옵션 >개인정보 단,고객이 쿠키 설치를 거부하는 경우 일부 서비스 제공에 어려움이 있을 수 있습니다.',
      txt52: '6. 정보주체의 권리•의무 및 그 행사방법에 관한 사항',
      txt53: '가. 개인정보의 열람',
      txt54: '1) 정보주체는 회사가 처리하는 자신의 개인정보에 대하여 개인정보 열람요구서를 회사에 제출하는 등의 방법으로 열람을 요구할 수 있습니다.',
      txt55: '2) 회사는 열람을 요구받으면 그로부터 10일 이내에 정보주체가 해당 개인정보를 열람할 수 있게 합니다. 만약 해당 기간 내에 열람할 수 없는 사유가 있을 때에는 요구를 받은 날부터 10일 이내에 정보주체에게 그 사유 및 이의제기방법을 알리고 열람을 연기할 수 있으며, 그 사유가 소멸한 경우에는 정당한 사유가 없는 한 사유가 소멸한 날로부터 10일 이내에 열람하도록 할 수 있습니다.',
      txt56: '3) 회사는 다음의 어느 하나에 해당하는 경우 정보주체에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다. 이 경우 열람이 제한되는 사항을 제외한 부분은 열람할 수 있으며, 열람 요구를 받은 날부터 10일 이내에 그 사유 및 이의제기방법을 알려드립니다.',
      txt57: '- 법률에 따라 열람이 금지되거나 제한되는 경우',
      txt58: '- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우',
      txt59: '',
      txt60: '나. 개인정보의 정정•삭제',
      txt61: '1) 개인정보를 열람한 정보주체는 개인정보 정정•삭제 요구서를 회사에 제출하는 등의 방법으로 그 개인정보의 정정 또는 삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.',
      txt62: '2) 회사는 정보주체의 요구를 받은 경우 개인정보의 정정 또는 삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우를 제외하고는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 그 개인정보를 조사하여 정보주체의 요구에 따라 정정•삭제 등 필요한 조치를 한 후 그 결과를 알려드립니다.',
      txt63: '3) 회사는 다음의 어느 하나에 해당하는 경우 정당한 사유가 없는 한 정보주체의 요구를 받은 날로부터 10일 이내에 정정•삭제를 할 수 없는 사유와 이의제기방법을 알려드립니다.',
      txt64: '- 법률에 따라 열람이 금지되거나 제한되는 경우',
      txt65: '- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우',
      txt66: '',
      txt67: '다. 개인정보의 처리 정지',
      txt68: '1) 정보주체는 회사에 대하여 개인정보 처리정지 요구서를 회사에 제출하는 등의 방법으로 자신의 개인정보 처리의 정지를 요구할 수 있습니다.',
      txt69: '2) 회사는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 정보주체의 요구에 따라 개인정보 처리의 전부를 정지하거나 일부를 정지하고, 처리가 정지된 개인정보에 대하여 처리정지의 요구를 받은 날로부터 10일 이내에 필요한 조치를 하여야 합니다. 다만, 다음의 어느 하나에 해당하는 경우에는 정보주체의 처리정지 요구를 거절할 수 있으며, 이 경우 정보주체에게 요구를 받은 날로부터 10일 이내에 그 사유를 알려주어야 합니다.',
      txt70: '- 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우',
      txt71: '- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우',
      txt72: '- 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우',
      txt73: '',
      txt74: '라. 권리행사의 방법 및 절차',
      txt75: '회사는 개인정보의 수집 시에 요구되지 않았던 증빙서류 등을 요구하거나 추가적인 절차를 요구하지 않습니다. 정보주체는 개인정보의 열람, 정정•삭제, 처리정지 요구를 대리인(정보주체의 법정대리인, 정보주체로부터 위임을 받은 자)에게 하게 할 수 있습니다. 이 경우, 대리인은 회사에 정보주체의 위임장을 제출하여야 합니다.',
      txt76: '7. 개인정보보호를 위한 기술적•관리적 보호대책',
      txt77: '회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 안정성 확보를 위하여 다음과 같은 기술적•관리적 대책을 강구하고 있습니다.',
      txt78: '',
      txt79: '가. 기술적 대책',
      txt80: '다음의 기술적 대책에도 불구하고 해커의 침입 및 신종 바이러스 침투와 같은 사고 발생 당시의 기술로 예방, 조치할 수 없는 불가항력 사유로 인하여 발생한 개인정보의 분실, 도난, 유출, 변조 또는 훼손에 대하여는 회원에게 책임을 지지 않습니다.',
      txt81: '1) 회원의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 보호하고 있습니다.',
      txt82: '2) 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안 장치를 채택하고 있습니다.',
      txt83: '3) 해킹 등 외부 침입에 대비하여 취약점 분석 시스템 등을 이용하여 보안에 만전을 기하고 있습니다.',
      txt84: '',
      txt85: '나. 관리적 대책',
      txt86: '회사는 개인정보처리 직원을 최소한으로 제한하고 담당 직원에 대한 수시교육을 통하여 본 방침의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 시정조치하고 있습니다.',
      txt87: '1) 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.',
      txt88: '2) 그 외 내부관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 당사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.',
      txt89: '8. 개인정보보호 책임자',
      txt90: '회사는 수집된 이용자의 개인정보 관리를 위해 개인정보보호 책임자를 다음과 같이 지정하여 관리하고 있으며, 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.',
      txt91: '',
      txt92: '가. 개인정보보호 책임자',
      txt93: '1) 담당자 : 조상현',
      txt94: '2) 직책 : 개인정보보호책임자(CPO)',
      txt95: '3) 전화번호 : 080-789-8114',
      txt96: '4) e-mail : privacy.life@hanwha.com',
      txt97: '',
      txt100: '나. 개인정보보호 담당자',
      txt101: '1) 담당자 : IT 보안파트 개인정보보호 담당자',
      txt102: '2) 전화번호 : 080-789-8114',
      txt103: '3) e-mail : privacy.life@hanwha.com',
      txt104: '',
      txt105: '※ 개인정보보호처리방침 시행일자: 2020.11.23',
      txt106: '※ 개인정보처리방침 버전번호: V.2',
    },
    Terms: {
      txt01: '이용약관',
      txt02: '제 1 조  목적',
      txt03: '이 약관은 한화생명보험주식회사(이하 "회사")가 운영하는 드림플러스 홈페이지(이하 “홈페이지”)에서 회사가 제공하는 서비스(이하 “서비스”) 이용과 관련하여 회사와 이용자 간의 권리, 의무, 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.',
      txt04: '제 2 조 용어의 정의',
      txt05: '이 약관에서 사용하는 용어의 정의는 다음과 같습니다.',
      txt06: '1. "홈페이지"란 회사가 전자적 수단(컴퓨터, 정보단말기 등 전자적 장치)을 이용하여 정보∙서비스 등을 제공하고자 설정한 가상의 공간을 말합니다.',
      txt07: '2. "이용자"란 홈페이지에 접속하여 이 약관에 따라 홈페이지가 제공하는 서비스를 받는 회원 또는 비회원을 말합니다.',
      txt08: '3. "회원"이란 홈페이지에 접속하여 이 약관에 따라 홈페이지에 가입하고 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.',
      txt09: '4. "비회원"이란 회원에 가입하지 않고 홈페이지가 제공하는 서비스를 이용하는 자를 말합니다.',
      txt10: '5. "아이디(ID)"란 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 이메일 주소를 말합니다.',
      txt11: '6. "비밀번호"란 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.',
      txt12: '제 3 조 약관의 효력 및 변경',
      txt13: '1. 이 약관은 그 내용을 홈페이지에 게시함으로써 효력이 발생합니다. 다만 이 약관의 내용은 별도의 연결화면을 통하여 보도록 할 수 있습니다.',
      txt14: '2. 회사는 관계법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정사유를 명시하여 그 적용일자 7일 이전부터 적용일자 전일까지 본 조 제1항의 방법으로 공지함으로써, 그 개정의 효력이 발생합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.',
      txt15: '3. 본 조 제2항에 따라 공지된 적용일자 이후에 이용자가 서비스를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 아니하는 이용자는 언제든지 서비스 이용계약을 해지하거나 회사에게 해지를 요청할 수 있습니다.',
      txt16: '4. 이 약관이 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령의 규정에 따릅니다.',
      txt17: '제 4 조 회원가입',
      txt18: '1. 공모 및 이벤트 신청자, 이벤트홀 사용 예약자는 회사가 정한 가입 양식에 따라 회원정보를 정확히 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청할 수 있습니다.',
      txt19: '2. 회원이 등록한 허위 정보나 정보 오류로 인한 문제는 등록한 회원의 책임입니다.',
      txt20: '3. 회사는 회원의 등급에 따라 서비스의 차등을 둘 수 있으며, 회원은 그 등급에 따라 서비스 이용에 제약을 받을 수 있습니다.',
      txt21: '4. 사실과 다른 정보 또는 허위 정보를 기입하거나 추후 그러한 정보임이 밝혀질 경우 회사는 해당 회원에 대하여 서비스 이용을 일시 정지하거나 영구정지 및 이용 계약을 해지할 수 있습니다. 이로 인하여 회사 또는 제3자에게 발생한 손해는 해당 회원이 책임을 집니다.',
      txt22: '5. 회사는 회원이 되고자 하는 자가 본 조 제1항에 따라 회원정보를 정확하게 기재하고 이 약관에 동의한 경우에는 서비스의 이용을 승낙하는 것을 원칙으로 합니다.',
      txt23: '6. 회사는 다음과 같은 사유가 있는 경우, 그 제한 사유가 해소될 때까지 이용신청에 대한 승낙을 거절 또는 일부 서비스 이용을 제한할 수 있습니다.',
      txt24: '1) 이미 가입된 회원과 이름, 이메일 주소가 동일한 경우',
      txt25: '2) 다른 사람의 명의를 사용하여 신청하였을 때',
      txt26: '3) 회원가입 신청양식의 내용을 허위로 기재하였을 때',
      txt27: '4) 회사가 이용계약을 해지하여 탈퇴시킨 회원이 재이용신청을 하는 경우(단, 회원자격 상실 후 [3]년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우는 예외로 합니다.)',
      txt28: '5) 기타 위법 또는 부당한 이용신청임이 객관적으로 확인된 경우',
      txt29: '7. 회사는 다음 각 호에 해당하는 경우 그 사유가 해소될 때까지 이용신청에 대한 승낙을 유보할 수 있습니다.',
      txt30: '1) 서비스 관련 제반 설비의 용량이 부족한 경우',
      txt31: '2) 기술상 장애 사유가 있는 경우',
      txt32: '3) 기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우',
      txt33: '8. 이용계약은 이용자의 약관동의와 이용신청에 대한 회사의 승낙으로 성립합니다.',
      txt34: '제 5 조 회원정보의 변경',
      txt35: '회원은 홈페이지 내 프로필 화면 등을 통해 언제든지 자신의 정보를 열람하고, 수정할 수 있습니다.',
      txt36: '제 6 조 개인정보보호 의무',
      txt37: '회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법, 개인정보보호법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관계법령 및 회사의 개인정보처리방침이 적용됩니다.',
      txt38: '제 7 조 회원의 의무',
      txt39: '1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.',
      txt40: '2. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.',
      txt41: '3. 본 조 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.',
      txt42: '제 8 조 회원에 대한 통지',
      txt43: '1. 회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원의 이메일 주소로 할 수 있습니다.',
      txt44: '2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 홈페이지 초기화면에 게시함으로써 본 조 제1항의 통지에 갈음할 수 있습니다.',
      txt45: '제 9 조 서비스의 제공 및 변경',
      txt46: '1. 회사가 이용자에게 제공하는 서비스는 다음과 같습니다. 다만, 비회원에게는 서비스 중 일부만을 제공할 수 있습니다.',
      txt47: '1) 드림플러스 사업소개',
      txt48: '2) 스타트업 지원센터 및 코워킹스페이스 안내',
      txt49: '3) 센터 입주 투어 방문 예약 및 이벤트홀 대관 안내',
      txt50: '4) 액셀러레이팅 프로그램 소개 및 공모지원',
      txt51: '5) 드림플러스 이벤트 정보',
      txt52: '2. 회사는 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.',
      txt53: '3. 서비스의 내용, 이용방법, 이용시간 등에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 이용시간을 변경 전에 게시하여야 합니다.',
      txt54: '4. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관계법령에 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.',
      txt55: '제 10 조 서비스의 이용제한',
      txt56: '1. 이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 회사는 이용자의 서비스 이용을 일부 또는 전부 정지할 수 있으며, 이로 인해 발생하는 불이익에 대해 회사는 책임을 부담하지 아니합니다.',
      txt57: '2. 회사는 이용자가 이 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 회사는 회원의 접속을 금지할 수 있습니다.',
      txt58: '3. 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인할 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.',
      txt59: '제 11 조 지식재산권의 귀속 및 이용제한',
      txt60: '1. 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 "회사"에게 있습니다.',
      txt61: '2. 이용자는 회사가 명시적으로 승인한 경우를 제외하고는 본 조 제1항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재 라이센스, 담보권설정행위, 상업적 이용행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.',
      txt62: '제 12 조 회사의 의무',
      txt63: '1. 회사는 관계법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.',
      txt64: '2. 회사는 관계법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령을 준수하고, 개인정보처리방침을 공시하고 준수합니다.',
      txt65: '3. 회사는 서비스 이용과 관련하여 이용자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.',
      txt66: '4. 회사는 이용자가 원하지 않는 영리목적의 광고성 이메일을 발송하지 않습니다.',
      txt67: '제 13 조 이용자의 의무',
      txt68: '1. 이용자는 다음 행위를 하여서는 안 됩니다.',
      txt69: '1) 신청 또는 변경 시 허위내용의 등록',
      txt70: '2) 타인의 정보도용',
      txt71: '3) 회사가 게시한 정보의 변경',
      txt72: '4) 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시',
      txt73: '5) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해',
      txt74: '6) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위',
      txt75: '7) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위',
      txt76: '8) 회사의 동의없이 영리를 목적으로 서비스를 사용하는 행위',
      txt77: '9) 기타 불법적이거나 부당한 행위',
      txt78: '2. 이용자는 관계법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.',
      txt79: '제 14 조 회원탈퇴 및 자격상실',
      txt80: '1. 회원은 회사에 언제든지 회원탈퇴를 요청할 수 있으며, 회사는 즉시 회원탈퇴를 처리합니다.',
      txt81: '2. 회사는 회원이 다음 각 호의 사유에 해당하는 경우, 사전통지 없이 회원자격을 제한 및 정지시킬 수 있습니다.',
      txt82: '1) 가입신청 시에 허위 내용을 등록한 경우',
      txt83: '2) 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우',
      txt84: '3) 홈페이지에서 법령과 이 약관이 금지하거나 공공질서 미풍양속에 반하는 행위를 하는 경우',
      txt85: '3. 회사가 회원자격을 제한, 정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.',
      txt86: '4. 회사가 회원자격을 상실시키는 경우에는 회원에게 이를 통지하고 자격 상실 전에 소명할 기회를 부여한 후 탈퇴를 처리합니다.',
      txt87: '제 15 조 손해배상 및 책임제한',
      txt88: '1. 이용자는 본인의 귀책사유로 인하여 회사나 다른 이용자 등 제3자가 입은 손해를 배상할 책임이 있습니다. 이용자가 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 당해 이용자 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.',
      txt89: '2. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 이용자가 신상정보 및 이메일 주소 등을 부실하게 기재하여 손해가 발생한 경우 등 회사의 귀책사유가 없는 경우에는 책임을 지지 않습니다.',
      txt90: '3. 회사는 이용자나 제3자에 의해 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 의견을 승인하거나 반대하거나 수정하지 않습니다. 회사는 어떠한 경우라도 이용자가 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.',
      txt91: '4. 회사는 이용자간 또는 이용자와 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 이용자가 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.',
      txt92: '5. 서비스 이용과 관련하여 이용자가 제공한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 회사는 책임을 지지 않습니다.',
      txt93: '제 16 조 준거법 및 재판관할',
      txt94: '1. 회사와 이용자 간 제기된 소송은 대한민국법을 준거법으로 합니다.',
      txt95: '2. 회사와 이용자간 발생한 분쟁에 관한 소송은 회사의 본사 소재지를 관할하는 법원을 제1심 관할법원으로 합니다.',
      txt96: '부칙',
      txt97: '제 1 조. 이 약관은 2020년 11월 23일부터 시행합니다.',
    },
    Management: {
      txt01: '1. 개인정보 처리를 위탁한 업무의 내용 및 위탁받은 자',
      txt02: '회사는 원활한 서비스 이행을 위해 개인정보의 처리 처리를 위탁하고 있으며 개인정보 처리를 위탁 받은 업체는 위탁 받은 업무의 수행에 필요한 최소한의 범위내에서 개인정보를 처리하게 됩니다.',
      txt03: '개인정보처리를 위탁한 업무의 내용 및 위탁받은 자는 다음과 같습니다.',
      head01: '위탁하는 업무의 내용',
      head02: '위탁받은 자',
      head03: '연락처',
      txt04: '홈페이지 유지보수, 홈페이지를 통한 개인정보의 수집 · 보관, 이메일 발송, SSO 처리 등',
      txt05: '포멀웍스',
      txt06: '02-563-8763',
    },
  },
};
