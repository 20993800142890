/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    btn01: '',
  },
  Fintech: {
    txt01: `DERAMPLUS 63 FINTECH CENTER is an open innovation center that supports collaboration and growth
     between startups and partner companies<br />who are able to generate synergy effects
     in industries of fintech, healthcare, and wellness. `,
    txt02: 'DREAMPLUS 63 FINTECH CENTER, working with about 50 startups, has achieved qualitative/quantitative growth in a short time.',
    txt03: 'Please contact DREAMPLUS 63 FINTECH CENTER anytime if you have any question.',
    txt04: '6F, 50, 63-ro, Yeongdeungpo-gu, Seoul',
    txt05: 'Subway Station',
    txt06: 'Subway Line 5 Yeouido, ',
    txt07: ' Yeouinaru Station',
    txt08: 'Subway Line 9 Saetgang Station',
    txt09: 'Bus Station',
    txt10: '63 Building',
    txt11: 'Catholic University Yeouido St. Mary\'s Hospital',
    txt12: 'Yeouido-dong Community Center',
  },
  Gangnam: {
    txt01: `DREAMPLUS GANGNAM, the single largest in Korea, is a co-working space as well as a physical hub
    <br /> to support the growth of startups with a dedicated startup accelerator.`,
    txt02: `DREAMPLUS GANGNAM is a place where large corporations, startups, accelerators, venture capitalist, media and educational institutions
    <br />are connected to each other and looking for open innovation opportunities.`,
    txt03: 'OPEN KITCHEN',
    txt04: 'MEETING ROOM',
    txt05: 'OA MACHINE',
    txt06: 'PHONE BOOTH',
    txt07: 'STORAGE',
    txt08: 'WIRED/WIRELESS INTERNET',
    txt09: 'QUICK EXPRESS',
    txt10: 'FEMALE ONLY LOUNGE',
    txt11: 'PARKING & CAR SHARING',
    txt12: 'SHOWER ROOM',
    txt13: '3-STEP SECURITY SYSTEM',
    txt14: 'EVENT HALL',
    txt15: `Major players of business ecosystem including startups,
     innovative organizations of large corporations, venture capitals,
     government/education organizations
    <br /> are all brought together in DREAMPLUS GANGNAM,
     thereby enabling rapid connectivity/sharing/collaboration.`,
    txt16: 'We offer a variety of office spaces and options to choose from according to your needs and applications.',
    txt17: 'Independent Office',
    txt18: '₩417,000',
    txt19: ` Month (VAT not included)
    <br />*For 20 or more people `,
    txt20: 'A variety of independent office spaces for 1-100 people to focus on work',
    txt21: 'Facilities Provided',
    txt22: 'Wired/Wireless Network',
    txt23: 'Desk in two different sizes (1,200mm, 1,400mm)',
    txt24: 'Individual office drawer and chair',
    txt25: 'Reserved Seats',
    txt26: '₩450,000',
    txt27: 'Month(VAT not included)',
    txt28: 'Office space where you can use your own reserved seat in the open common space',
    txt29: 'Facilities Provided',
    txt30: 'Wireless Network',
    txt31: 'Individual cabinet included',
    txt32: 'Free Seats',
    txt33: '₩350,000',
    txt34: 'Month(VAT not included)',
    txt35: 'A space where you can freely use with your laptop',
    txt36: 'Facilities Provided',
    txt37: 'Wireless Network',
    txt38: 'Individual cabinet not included',
    txt39: 'DREAMPLUS GANGNAM is waiting for you.',
    txt40: '311, Gangnam-daero, Seocho-gu, Seoul, Korea',
    txt41: 'Subway Station',
    txt42: 'Subway Line 2 & Sinbundang Gangnam Station',
    txt43: 'Subway Line 3 & Sinbundang Yangjae Station',
    txt44: 'Bus Station',
    txt45: 'Woosung Apartment Complex',
  },
  GangnamReservation: {
    txt01: 'Reservation to visit (DREAMPLUS Gangnam)',
    txt02: 'Welcome to DREAMPLUS Gangnam',
    txt02_1: '강남센터 방문 예약 입력',
    txt03: 'Date/Time',
    txt03_1: 'date',
    txt03_2: 'time',
    txt03_3: 'Choose',
    txt03_4: 'Choose the time',
    txt04: 'Name',
    txt04_1: 'Enter name',
    txt05: 'Company name',
    txt05_1: 'Enter company name',
    txt06: 'Number of employees',
    txt06_1: 'Choose the number of employees',
    txt06_2: '1 person',
    txt06_3: '2 persons',
    txt06_4: '4 persons',
    txt06_5: '6 persons',
    txt06_6: '8 persons',
    txt06_7: 'over 10 persons',
    txt06_8: 'over 20 persons',
    txt07: 'Cell phone number',
    txt07_1: 'Enter cell phone number without - ',
    txt08: 'E-mail',
    txt08_1: 'Enter E-mail',
    txt09: '[Required] Collection and Use of Personal Information Agreenment',
    txt10: `<strong>Personal information to be collected</strong>
    <p>Name, phone number, e-mail.</p>`,
    txt11: `<strong>Purpose of collecting and using personal information</strong>
    <p>Guide for reservation and confirmation of visiting DREAMPLUS Gangnam.</p>`,
    txt12: `<strong>Period of retaining and using personal information</strong>
    <p>The data is retained for one year. You can disagree with this agreement and if you disagree,
     you cannot use our service noted on "Purpose of collecting and using personal information".</p>`,
    txt13: 'Reserve',
    txt14: `<p>Reservation completed.</p>
    <p>예약안내는 방문 예정일 오전에 메시지로 안내됩니다.</p>`,
    txt15: `<p>해당 시간에 방문 예약을 하실 수 없습니다.</p>
    <p>다른 시간에 예약해주세요.</p>`,
    txt16: 'VISIT',
    txt17: 'GANGNAM',
    txt18: 'All information entered will be deleted.<br/>Would you like to cancel your reservation?',
    txt19: 'Reservation is complete',

  },
  Global: {
    txt01: `DREAMPLUS operates the GLOBAL CENTER for startups aiming to go abroad.
    <br />It plans to expand to China, Vietnam, Indonesia, and Singapore following the USA and Japan.
    <br />In addition, it continues finding opportunities to collaborate with global companies.
    <br />`,
    txt02: `DREAMPLUS GLOBAL CENTER aims to become a CONNECTIVE PLATFORM that brings local and international companies together.
    <br />We will provide a global innovation hub where promising startups can go beyond borders and realize their dreams.`,
    txt03: `We provide services of market survey and market consulting
     for startups to advance into global markets. `,
    txt04: `We provide services of local community
     and network connection for startups to settle down locally.`,
    txt05: `We offer opportunities
     to attract investment for business expansion.`,
    txt06: 'DREAMPLUS GLOBAL CENTER currently in operation is as follows, and is expected to expand around the world.',
    txt07: 'Contact',
    txt08: `DREAMPLUS SAN FRANCISCO is located in Silicon Valley,
    <br />the heart of global innovation.`,
    txt09: `It cooperates with various organizations such as local startups,
     large corporations and investors for an open innovation in the Americas.`,
    txt10: `DREAMPLUS FUKUOKA is located in Fukuoka, Japan,
    <br />the hub of the content industry.`,
    txt11: `Our open innovation collaboration ranges from lifestyle,
     content, fintech, healthcare, and to many others.`,
    txt12: 'Center Benefits',
  },
};
