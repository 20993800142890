import { container, append } from '@/common/helperUtils';

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];


export default [
  {
    path: '',
    redirect: 'list',
  },
  {
    path: 'list',
    name: 'ContestList',
    component: () => import(`@/views/contest/${appendName}ContestList.vue`),
    meta: {
      layout: container,
      title: 'Contest',
    },
  },
  {
    path: 'detail/:id',
    name: 'ContestDetail',
    component: () => import(`@/views/contest/${appendName}ContestDetail.vue`),
    meta: {
      layout: container,
      title: 'Contest',
    },
  },
  {
    path: 'apply/step1',
    name: 'ContestApplyStep1',
    component: () => import(`@/views/contest/${appendName}ContestApplyStep1.vue`),
    meta: {
      layout: container,
      title: 'Contest',
    },
  },
  {
    path: 'apply/step2',
    name: 'ContestApplyStep2',
    component: () => import(`@/views/contest/${appendName}ContestApplyStep2.vue`),
    meta: {
      layout: container,
      title: 'Contest',
    },
  },
];
