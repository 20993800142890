/* eslint-disable max-len */
export default {
  common: {
    innovation_centers_desc: `Dreamplus is expanding its network to the global scope, from San Francisco to Fukuoka and as well as Korea including
    <br />Dreamplus Gangnam and Dreamplus 63.
    <br />The network is constructed in order to provide an efficient connection to
    <br />those who participating in the global innovation ecosystem.`,
    dreamplus_63_desc: `DERAMPLUS 63 FINTECH CENTER is an open innovation center that supports collaboration and growth between
    <br />startups and partner companies who are able to generate synergy effects in industries of fintech, healthcare, and wellness. `,
    dreamplus_gangnam_desc: `Dreamplus Gangnam Center is the single largest co-working space that provides a bridge between startups and
    <br />key ecosystem players as giant corporations, government, educational institutions, etc,
    <br />so that they can create synergy and come up with collaboration opportunities.`,
    go_gangnam: 'go to DREAMPLUS GANGNAM',
    go_63: 'go to DREAMPLUS 63',
    dreamplus_global_desc: `Dreamplus Global Branch supports startups to successfully enter the global market through overseas expansion programs.
    <br />Moreover, the program encourages startups to expand its global business scale in countries like the U.S. and Japan,
    <br />providing them opportunities of collaboration with global corporations.`,
    go_k_startup: 'K-STARTUP TO THE WORLD PROGRAM GO',
  },
  innovation_centers: {
    center_63: 'DREAMPLUS 63<br />FINTECH CENTER',
    center_gangnam: 'DREAMPLUS<br />GANGNAM',
    center_fukuoka: 'DREAMPLUS<br />FUKUOKA',
    center_sanfrancisco: 'DREAMPLUS<br />SAN FRANCISCO',
    center_beijing: 'DREAMPLUS<br />BEIJING',
  },
  global_centers: {
    senfrancisco: `Dreamplus San Francisco is conducting
    <br />various partnerships and projects with local
    <br />startups, large companies, investors and
    <br />other related organizations with the purpose
    <br />of developing Open Innovation in the U.S.`,
    fukuoka: `DREAMPLUS FUKUOKA is conducting open
    <br />innovation collaboration ranges from
    <br />lifestyle, content, fintech, healthcare,
    <br />and to many others.`,
    beijing: `Dreamplus Beijing is supporting Korean
    <br />startups to enter Chinese market, and at the
    <br />same time, it introduces promising Korean
    <br />Startups to Chinese VC who are intrigued to
    <br />make investments.`,
  },
};
