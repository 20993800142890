/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    txt01: '드림플러스와 파트너십 제휴를 원하거나 관련 문의사항이 있으면 <a href="mailto:partnership@dreamplus.asia">partnership@dreamplus.asia</a> 로 연락주세요.',
  },
  Domestic: {
    txt01: '업종, 분야, 방식, 규모에 제약을 두지 않고 다양한 영역의 기업 및 기관들과의 열린 파트너십을 통해 더 건강한 생태계를 만들기 위해 노력하고 있습니다.',
    txt02: 'SK 주식회사 C&C',
    txt03: '신패스 DHN',
    txt04: '교원',
    txt05: '현대',
    txt06: '오라클',
    txt07: '하나금융티아이',
    txt08: '잇츠한불',
    txt09: '(주)장진우',
    txt10: '토스',
    txt11: '온아시아',
    txt12: '하이보이스',
    txt13: '두잇컴퍼니',
    txt14: '스티비',
    txt15: '디라이트',
    txt16: '삼정KPMG',
    txt17: '모두투어',
    txt18: '중소기업진흥공단',
    txt19: '한국방송광고진흥공사',
    txt20: '원익투자파트너스㈜',
    txt21: '글로벌 브레인',
    txt22: '서울투자파트너스',
    txt23: '은행권 청년 창업 재단',
    txt24: '신한 FUTURE’S LAB',
    txt25: 'FINOLAB',
    txt26: '영등포구  ',
    txt27: '위비핀테크 Lab',
    txt28: '핀테크지원센터',
    txt29: '하이보이스',
    txt30: '법무법인 세움',
    txt31: 'BLT 특허사무소',
    txt32: '무일국제특허법률사무소',
    txt33: '서울대학교 기술지주회사',
    txt34: '서강 비즈니스 센터',
    txt35: '성균관대학교 산학협력단',
    txt36: '동국대학교 창업지원단',
    txt37: '단국대학교 산학협력단',
    txt38: '순천향대학교 창업보육센터',
    txt39: '충남대학교 산학협력단',
    txt40: '이화여자대학교',
    txt41: '한양대학교 산학협력단',
    txt42: '한국무역협회',
    txt43: '창업진흥원',
    txt44: '판교창업존',
    txt45: '롯데액셀러레이터',
    txt46: '바이엘',
    txt47: '예스24',
    txt48: '고려대의료원',
    txt49: '소니',
    txt50: '플래디',
  },
  Global: {
    txt01: '한화그룹 계열사를 비롯한 250여 개의 글로벌 네트워크 및 300여 개 이상의 스타트업 네트워크를 바탕으로 스타트업의 성장 및 확장을 지원하고 있으며, 2014년 부터 충남창조경제혁신센터와 함께 GFP 프로그램을 운영하여 우수한 국내 스타트업의 해외진출을 지원하고 있습니다.',
    txt02: '금융',
    txt03: '보험, 자산관리, 증권, 투자',
    txt04: '서비스 및 레저',
    txt05: '호텔, 리조트, 백화점 등',
    txt06: '제조&건설',
    txt07: '화학, 엔지니어링, 태양광 등',
    txt08: '글로벌 네트워크',
    txt09: '사모펀드',
    txt10: '벤처캐피탈',
    txt11: '스타트업 생태계 협력사',
    txt12: '대한민국',
    txt13: '일본',
    txt14: '중국',
    txt15: '베트남',
    txt16: '말레이시아',
    txt17: '기타',
    txt18: '대만',
    txt19: '싱가포르',
    txt20: '필리핀',
    txt21: '태국',
    txt22: '인도네시아',
    txt23: '인도',
    txt24: '디캠프',
    txt25: '퓨처플레이',
    txt26: '코오롱 액셀러레이터',
    txt27: '코스콤 액셀러레이터',
    txt28: '매쉬업엔젤스',
    txt29: '프라이머',
    txt30: '벤처스퀘어',
  },
};
