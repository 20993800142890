/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    btn1: '브로슈어(국문)',
    btn2: '브로슈어(영문)',
    btn3: '',
  },
  wwa: {
    txt01: `꿈을 이루고자 도전하는 이들에게 다양한 기회와 희망을
    <br />제공하고 이를 통해 그 꿈이 실현될 수 있도록,
    <br />그리고 그 꿈들이 서로 시너지를 이루고 지속 가능한
    <br />혁신의 선순환이 가능할 수 있도록,`,
    txt02: `DREAMPLUS는
    <br />Open Innovation Hub이자 Business Enabler로써
    <br />다양한 꿈들을 서로 연결하는 혁신의 촉매제가 되어
    <br />더 나은 세상을 만들어가는데 기여하겠습니다.`,
    txt03: `꿈을 꾸는 모든 이들이 DREAMPLUS의 가치를 만나
    <br />서로 선한 영감을 받고, 성장하고 그 꿈을 실현합니다`,
    txt04: '많은 이들이 꿈과 희망을 갖도록 하는 혁신 조력자',
    txt05: `많은 이들의 꿈이 실현되고 상호 시너지를 이루며
    <br />혁신의 선순환을 이끌어냅니다.`,
    txt06: '꿈의 실현과 혁신을 위한 다양한 ‘기회’와 ‘환경’을 제공',
    txt07: `드림플러스의 심볼 마크는 역동성과 영원함을 나타냅니다.
    <br />하나의 원이 또 다른 원을 만듭니다.
    <br />새로 만들어진 원은 본래의 원에서 분리되어
    <br />점차 독립적으로 성장하며 스스로 자립해 갑니다.`,
    txt08: `두 원은 관계를 유지하며, 각각의 새로운 삶을 만들어 갑니다.
    <br />이는 심볼의 정적인 형태 속에 존재하는 역동성뿐 아니라
    <br />생명의 유기성을 나타냅니다.`,
    txt09: `모션그래픽은 각 생명에 상호 독립적인 존재로 성장해 가는
    <br />움직임을 표현하고 있습니다. 이 움직임은 구조적이고 유기적인
    <br />형태를 통한 최적의 균형상태를 표현합니다.`,
  },
  wwd: {
    txt01: `드림플러스는 스타트업 생태계 플레이어 간의 협업과 혁신
    <br />그리고 성장을 촉진하는 Open Innovation Hub 이자,
    <br />Business Enabler로서 구성원간의 연결을 통해
    <br />건전한 스타트업 생태계 구축 및 활성화에 기여합니다.`,
    txt02: `연결(Connectivity)를 통해 협업과 혁신의 공생 관계와
    <br />가치가 지속 가능하도록, 오픈이노베이션 생태계 내
    <br />다양한 ‘기회’ 와 ‘환경’을 제공합니다.`,
    txt03: '꿈과 꿈의 연결을 통해 협업과 혁신을 돕는 오픈이노베이션 플랫폼',
    txt04: '기업',
    txt05: '스타트업',
    txt06: '개인',
    txt07: '투자자',
    txt08: `기타
    <br />(정부, 학계 등)`,
    txt09: `다양한 구성원들의 꿈이 실현될 수 있도록
    <br />오픈 이노베이션 생태계 구축 및 연결`,
    txt10: `꿈과 꿈의 연결을 통해 협업과 혁신을 돕고
    <br />지속 가능한 혁신의 공생관계 속에서
    <br />새로운 사업의 기회 지속 발굴`,
    txt11: `다양한 구성원이 연결되는 이노베이션
    <br />센터로 원활한 Connectivity
    <br />구축을 위해 국내는 물론 해외까지 글로벌 거점을 확대하며,
    <br />네트워킹을 확장하고 있습니다.`,
    txt12: `창업 초기, 성장, 확장, 엑싯(Exit)에
    <br />이르는 스타트업 생애주기 전 과정에
    <br />걸쳐 다양한 액셀러레이팅
    <br />프로그램을 지원하고 있습니다`,
    txt13: `Global Network 기반의 사업확장 및
    <br />해외진출을 지원하며,
    <br />열린 파트너십을 통해 더 건강한
    <br />혁신성장 생태계를 만들기 위해
    <br />노력하고 있습니다.`,
    txt14: `기업 및 투자자 연계를 통한 투자
    <br />기회를 마련하고 사업연계 및 협업
    <br />프로젝트를 통한 오픈이노베이션
    <br />기회를 제공합니다.`,
    txt15: `스타트업 생태계 구성원 뿐만 아니라
    <br />일반 대중이 참여할 수 있는
    <br />아카데미와 이벤트를
    <br />통해 창·취업 지원활동 및 혁신성장
    <br />생태계참여 기회를 제공합니다.`,
    txt16: `2014년 스타트업 액셀러레이터로 출발하여 코워킹 스페이스, 아카데미, 포털, 셰어하우스 등의 사업을 통해
    <br />오픈이노베이션 생태계의 구성원들의 성장을 지원하는 대표 브랜드 및 Open Innovation Hub로서 초석을 다져오고 있습니다.`,
    txt17: `스타트업 성장을 지원하기 위한 엑셀러레이팅
    <br />활동 추진 및 아시아 주요국 거점 마련
    <br />(서울, 中상하이, 日도쿄)`,
    txt18: `초기 핀테크 스타트업 성장을 지원하기 위한
    <br />CSR 기반 “63 핀테크 센터” 오픈 및
    <br />정례 프로그램 지원 시작`,
    txt19: `다양한 영역의 스타트업 생태계를 활성화하고
    <br />지원하기 위한 코워킹 오피스 오픈
    <br />(드림플러스강남 센터)`,
    txt20: `다양한 창·취업 지원 아카데미 프로그램 운영
    <br />(블록체인, 컨텐츠, 패션&굿즈, F&B 등)
    <br />With 분야별 전문 파트너`,
    txt21: `꿈을 실현하기 위한 기회와 정보를
    <br />제공하는 디지털 플랫폼 구축
    <br />(on/off line Open Innovation Hub 구축)`,
    txt22: `글로벌 네트워크 확대을 위한 해외 거점 오픈
    <br />(샌프란시스코, 후쿠오카)`,
    txt23: `배움, 주거 및 네트워킹이
    <br />모두 결합된 쉐어 하우스 오픈
    <br />(DREAM HAUS)`,
    txt24: `스타트업을 위한 네트워킹 및 연수시설오픈
    <br />(DREAM PARK)`,
  },
  op: {
    txt01: `업종, 분야, 방식, 규모에 제약을 두지 않고 다양한 영역의
    <br />스타트업, 기업 및 기관들과의 열린 파트너십을 통해
    <br />더 건강한 생태계를 만들기 위해 노력하고 있습니다.`,
    txt02: `또한, 한화그룹 계열사를 비롯한 250여개의 글로벌 네트워크
    <br />및 300여개 이상의 스타트업 네트워크를 바탕으로,
    <br />스타트업의 성장을 지원하고 있으며 우수한 국내 스타트업의
    <br />해외 진출을 지원하고 있습니다.
    <br />`,
  },
};
