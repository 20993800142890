/**
 * is empty
 * @param str
 * @returns {boolean}
 */
export function isEmpty(str) {
  return str === undefined || str === null || str.length === 0 || str === '';
}

/**
 * 문자열이 숫자로 구성되어있는지 여부
 * @param {string} str
 * @returns {boolean}
 */
export function isNumeric(str) {
  return /^-?\d+$/.test(str);
}

/**
 * 전화번호 체크
 * @param {string} phone
 * @returns {boolean}
 */
export function isValidPhone(phone) {
  const phoneRegex1 = /^\d{2,3}-\d{3,4}-\d{4}$/;
  const phoneRegex2 = /^\d{4}-\d{4}$/;
  return phoneRegex1.test(phone) || phoneRegex2.test(phone);
}

/**
 * is empty object
 * @param {Object} obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * 글자수 제한 범위내에 있는지 여부
 * @param {string} str
 * @param {number} characterLimit 제한할 글자수
 * @return {boolean}
 */
export function isWithinCharacterLimit(str, characterLimit) {
  return str.length > characterLimit;
}
