/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  SignIn: {
    txt01: 'Congratulations on becoming a member of DREAMPLUS Innovation Hub.',
    txt02: 'Welcome to DREAMPLUS Innovation Hub.',
    txt03: 'Login to DREAMPLUS Innovation Hub, an integrated online/offline platform for startups and businesses.',
    txt04: 'LOGIN',
    txt05: 'Enter email',
    txt06: 'Enter password',
    txt07: 'If you experience login errors, please contact us at <br> <a href="mailto:contact@dreamplus.asia">contact@dreamplus.asia</a>',
    txt08: 'SNS Login',
    txt09: 'Not a member of DREAM PLUS Innovation Hub yet?',
    txt10: 'Sign up',
  },
  popup: {
    SSOAlert: {
      txt01: 'Single Sign On Guide',
      txt02: 'If you join the DREAM PLUS Innovation Hub Service with the same e-mail registered in DREAM PLUS Portal, you can use both services with single log-in.',
      txt03: 'Do you want to join membership?',
      txt04: 'No for joining membership',
      txt05: 'Don\'t show again for a week.',
    },
    SSOConnector: {
      txt01: 'Single Sign On',
      txt02: 'I provide this e-mail ID to DREAM PLUS Portal for log-in connection to DREAM PLUS Portal.',
      txt03: 'Do you agree with log-in connection?',
      txt04: `1. Receiver of personal information: Hanwha Corporation<br><br>
      2. Goal of utilizing personal information of personal information receiver: providing the service of jointly using the member's log-in information which enables using the online services of DREAM PLUS Innovation Hub without log-in<br><br>
      3. Personal information items provided : e-mail ID<br><br>
      4. Period of retaing and utilizing collected personal information <br>
      - Period of providing the service starting from the date of consent  <br>
      However, even after the goal of collection of or provision is acheived, the Company may store personal information if neceesary according to code of laws including commercial laws.<br><br>
      5. Notification of rights to refuse and disadvantages for refusal<br>
      - You have the right to refuse to give consent on the items as above. You can withdraw the consent you provided at any time.<br><br>
      ※ However, if you refuse to give consent, there could be limits in using the Service (limits in Single Sign-On).`,
      txt05: 'Connect',
      txt06: 'Do not connect',
      txt07: 'Please enter password of DREAM PLUS Innovation Hub for log-in connection.',
      txt08: 'Incorrect password.',
      txt09: 'SNS log-in is necessary to complete log-in connection.',
      txt10: 'SNS log-in is necessary.',
      txt11: 'Log-in connection is completed.',
      txt12: 'Now you can use both DREAM PLUS Portal and',
      txt13: 'DREAM PLUS Innovation Hub websites with a single log-in.',
      txt14: 'You can always disable Single Sign On on My Page.',
    },
  },
};
