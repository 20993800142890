/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  gnb: {
    story: '세상을 변화시키는 혁신가들의 꿈이 현실이 되는 곳',
    news: '드림플러스에서 전하는 소식',
    gangnam01: '강남권 최대의 코워킹 스페이스',
    gangnam02: '스타트업, 액셀러레이터, 벤처캐피털, 대기업 혁신조직, 정부/교육기관 등이',
    gangnam03: '서로를 통해 성장과 혁신의 기회를 모색할 수 있는 새로운 업무 환경을 제안합니다.',
    gangnam04: '드림플러스 강남에서 세상을 긍정적으로 변화시키고자 하는 혁신가들의 꿈이 이루어지기를 기원합니다.',
    gangnam05: '<strong>입주문의 02-520-1500</strong><strong>대관문의 02-520-1504</strong>',
    square01: '국내 최고의 핀테크 액셀러레이터',
    square02: '드림플러스 63 핀테크센터는 금융의 중심지 여의도 63빌딩에 위치하고 있습니다.',
    square03: '2016년 10월 개소한 센터는 입주 스타트업에게 국내 최고 수준의 개별 사무공간과 함께',
    square04: '다양한 사업성장 기회를 제공합니다.',
    acc01: '핀테크 스타트업 사업화 연계 프로그램',
    acc02: '라이프스타일 스타트업 사업화 연계 프로그램',
    acc03: '파트너사의 목적에 맞는 특화 프로그램 설계',
    acc04: '스타트업 해외 사업화 액셀러레이팅 프로그램',
    acc05: '입주사 특화 액셀러레이팅 프로그램',
    acc06: '드림플러스만의 교육 프로그램',
    acc07: '<span class=\'hanwa-tit2\'></span>계열사 협업 프로그램',
    event: '드림플러스에서 운영하는 다양한 프로그램 및 이벤트',
    partnership01: '스타트업과 함께 성장과 혁신의 기회',
    partnership02: '국내 스타트업의 해외진출을 지원',
    Networking01: '이노베이션 생태계를 연결합니다',
    Networking02: '성장과 혁신을 모색할 수 있는 기회의 장',
    Networking03: '드림플러스에서 전하는 스타트업 뉴스',
  },
  button: {
    btn01: '임시 저장',
    btn02: '다음 단계',
    btn03: '이전 단계',
    btn04: '확인',
    btn05: '취소',
    btn06: '등록',
    btn07: '삭제',
    btn08: '목록',
    btn09: '신청',
    btn10: '저장',
    btn11: '불러오기',
    btn12: '찾아보기',
    btn13: '검색',
    btn14: '목록으로 돌아가기',
    btn15: '메시지 보내기',
    btn16: '수정',
    btn17: '다운로드',
    btn18: '추가',
    btn19: '지원서 보기',
    btn20: '더보기',
    btn21: 'BACK',
    btn22: '신청완료',
  },
  txt: {
    txt01: '검색 결과',
    txt02: '검색 결과가 없습니다.',
    txt03: '전체',
    txt04: '개',
    txt05: '등록된 게시물이 없습니다.',
    txt06: '댓글을 입력하세요.',
    txt07: '게시물 작성자와 나만 보기',
    txt08: '페이지를 찾을 수 없습니다.',
    txt09: '입력한 주소가 맞는지 다시 확인해 주세요.',
    txt10: '메인으로 이동',
    txt11: 'E-mail ID 찾기',
    txt12: 'Password 찾기',
    txt13: '검색어를 입력해 주세요',
  },
};
