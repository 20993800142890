/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  gnb: {
    story: 'Where innovators can make their dreams come true.',
    news: 'News from DREAMPLUS',
    gangnam01: 'Largest co-working space in Gangnam Area',
    gangnam02: 'We offer tools, resources and the environment to assist you in your ideas, build new business concepts, innovate and grow.',
    gangnam03: 'Connect with other startups, accelerators, venture capitalists, big corporates\' innovation organizations, governments and training institutions.',
    gangnam04: 'DREAMPLUS Gangnam is helping innovative thinkers achieve their dreams to bring about positive changes to the world.',
    gangnam05: '<strong>For inquiries/help with moving 02-520-1500</strong><strong> For inquiries/help with renting 02-520-1504</strong>',
    square01: 'Korea’s no.1 Fintech Accelerator',
    square02: 'DreamPlus 63 Fintech Center is located in Yeouido 63 Building, the financial center.',
    square03: 'Opened in October 2016, the center offers tens of thousands of individual office spaces',
    square04: 'Provide various business growth opportunities.',
    acc01: 'Fintech startup programs for business development',
    acc02: 'Lifestyle startup business programs',
    acc03: 'Programs tailored for partners\' purpose',
    acc04: 'Acceleration programs to help startups develop their businesses overseas',
    acc05: 'Acceleration programs tailored to each tenant',
    acc06: 'DREAMPLUS\' Education Programs',
    acc07: '<span class=\'hanwa-tit4\'></span> affiliate collaboration programs',
    event: 'Various programs and events held by DREAM PLUS',
    partnership01: 'Opportunities to grow and innovate with startups',
    partnership02: 'Supporting domestic startups expand their presence overseas.',
    Networking01: 'Connecting Innovation Ecosystem',
    Networking02: 'Opportunities for growth and innovation',
    Networking03: 'Startup News from DREAMPLUS',
  },
  button: {
    btn01: 'Save draft',
    btn02: 'Next',
    btn03: 'Previous',
    btn04: 'Confirm',
    btn05: 'Cancel',
    btn06: 'Register',
    btn07: 'Delete',
    btn08: 'List',
    btn09: 'Apply',
    btn10: 'Save',
    btn11: 'Load',
    btn12: 'Find',
    btn13: 'Search',
    btn14: 'List',
    btn15: 'Send message',
    btn16: 'Revision',
    btn17: 'Download',
    btn18: 'Add',
    btn19: 'Review',
    btn20: 'See more',
    btn21: 'BACK',
    btn22: 'Complete',
  },
  txt: {
    txt01: 'Search results for',
    txt02: 'No results found',
    txt03: 'Total',
    txt04: '',
    txt05: 'No Post',
    txt06: 'Enter comment.',
    txt07: 'Post can be viewed by the writer and me only.',
    txt08: 'Page cannot be found.',
    txt09: 'Please check if the entered address is correct.',
    txt10: 'Go to main',
    txt11: 'Forgot email/ID',
    txt12: 'Forgot password',
    txt13: 'Enter Keyword',
  },
};
