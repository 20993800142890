/* eslint-disable max-len */
export default {
  pc: {
    common: {
      venture_studio_desc: `DREAMPLUS is expanding its global network by securing physical hubs both domestically and internationally
      <br />to facilitate connectivity among various participants within Open Innovation ecosystem.`,
      world_program_desc: `Hanwha Life Dreamplus is seeking for promising tech-based startups in various business fields in order to create new business value.
      <br />K-Startup to the world Program is leading by global Top-tier mentors who are capable of exploring innovative startups that have
      <br />concrete business model and potential, supporting selected startups to operate successful global expansion and local commercialization.`,
      benefits_desc: `Dreamplus provides an exclusive mentoring plan to startups by introducing local and overseas accelerators and Dreamplus office.
      <br />Not only that, Dreamplus has local business advisory groups and value-up partners who conduct a very meticulous planning
      <br />such as business process validation, global soft landing, local business consultation, local network and business pipeline reviews
      <br /> for those startups who attempt to enter the global market`,
    },
    benefits: {
      business_connecting: 'Customized support with<br />dedicated ACCELERATOR',
      workspace: 'Provide local<br />DREAMPLUS OFFICE',
      founder_community: 'Local business<br />/Investment network',
    },
    process: {
      step01: 'Open recruitment<br />(All time)',
      step02: '1st Application Screening<br />(Document)',
      step03: '2nd Local<br />Advisory Group Screening<br />(document and face-to-face<br />interview /on-line)',
      step04: 'Final Selection',
      reference01: '※ Business preparation(mentoring) in Korea and initiate local company building & soft landing after final selection',
      reference02: `※ Open recruitment will be available at all times, the result of the first document review will be notified after the collective review
      <br />(the collective reivew is going to be held the following month of the first document review)`,
    },
    advisory: {
      introduction: '',
      ref_site: '',
      kkr_website: 'KKR Website',
      twitter: 'Twitter',
      kkr: `KKR & CO. Inc. is an American- based global investment company that manages multiple alternative asset classes, including capital market business, insurance solutions, etc.
      <br />KRR & CO. Inc. aims on generating high return on their investments which contain skilled investment practices, recruitment of global talents and growth support of portfolio companies and communities.
      <br />KRR & CO. Inc. is responsible for investment funds as private equity, credit, and physical assets. Also, it has numerous strategic partners who manage hedge funds.`,
      tpg_capital: `TPG is a global alternative investment company with $96 billion in assets under management.
      <br />The company mainly manages investment funds in private equity, real estate and listed stocks.
      <br />In particular, the company also possesses capabilities of establishing various growth investment startegies.
      <br />TPG explores innovative investment opportunities by utilizing global network and partners, playing a role in both a Solution Provider and Problem Solver in the investment ecosystem.`,
      altos_ventures: `Altos Ventures was founded in 1996, based in Menlo Park, California.
      <br />In regardless of business sector, the company mainly invests in various early-stage startups that have huge potential.`,
      john_nam: `John is a co-founder of Strong Ventures who focuses on investing in B2C based Fintech, Digital Media companies in South Korea and North America.
      <br />In prior to establishing Strong Ventures, John worked for a company called Diapad as a business development manager and senior product manager at Silicon Valley. After that, John cofounded Phonevite which won Internet Telephony award.
      <br />Moreover, he has work experieces in international finance department at US Bank and Comerica Bank, and IT management team at UN.
      <br />In addition, John got bachelor's degree in economics and master's degree in Columbia University.`,
      kiha_lee: `Ki-ha Lee is a successful entrepreneur and venture capitalist in the U.S., who has been playing a great role for constructing Korean startup ecosystem in Sillicon Valley for past two decades.
      <br />He also works as a partner of Primer Saje Partners, operating a startup community called 82 Startups which supports Korean entrepreneurs who willing to launch their business in the U.S.`,
      jaewon_song: `Jae-won Song is known for his multidiciplinary intellectual property practice, including strategic intellectual property counseling, patent prosecution and patent litigation.
      <br />He has plentiful experience in strategic patent counseling and has prosecuted numerous international patents in a range of different business fields such as autonomous driving, artificial intelligence(AI), blockchain technology, fintech, virtual reality(VR), computer software, health information technology and bioinformatics, semiconductor and other innovative technologies.`,
      hyunsuk_kang: 'Professor Kang Hyun-suk is an educated medical scholar in both Korea and the U.S. and has focused on clinical research in developing new treatments and biomarkers for cancer.',
      soh_kim: `So hyung Kim is the head of Standford center at Incheon global campus and a professor of engineering at Stanford University.
      <br />Her research is mainly focused on food innovation and design, and she is currently a lead of multicultural Design Thinking research at Stanfod University.
      <br />Prof. Kim studied psychology at Korea University, then she got mater's degree in mechanical engineering at London school of Economics and Political Science(LSE) and Stanford University. After that, she also got a doctor's degree in innovation managment at UC Berkeley.`,
    },
  },
  mobile: {
    common: {
      venture_studio_desc: `DREAMPLUS is expanding its global network
      <br />by securing physical hubs both domestically
      <br />and internationally to facilitate connectivity
      <br />among various participants within Open
      <br />Innovation ecosystem.`,
      world_program_desc: `Hanwha Life Dreamplus is seeking for promising
      <br />tech-based startups in various business fields
      <br />in order to create new business value.
      <br />K-Startup to the world Program is leading by
      <br />global Top-tier mentors who are capable of
      <br />exploring innovative startups that have
      <br />concrete business model and potential,
      <br />supporting selected startups to operate
      <br />successful global expansion and local
      <br />commercialization.`,
      benefits_desc: `Dreamplus provides an exclusive mentoring
      <br />plan to startups by introducing local and
      <br />overseas accelerators and Dreamplus office.
      <br />Not only that, Dreamplus has local business
      <br />advisory groups and value-up partners who
      <br />conduct a very meticulous planning such as
      <br />business process validation, global soft
      <br />landing, local business consultation,
      <br />local network and business pipeline reviews
      <br />for those startups who attempt to enter
      <br />the global market.`,
    },
    benefits: {
      business_connecting: 'Customized support with<br />dedicated ACCELERATOR',
      workspace: 'Provide local DREAMPLUS OFFICE',
      founder_community: 'Local business/Investment network',
    },
    process: {
      step01: 'Open recruitment<br />(All time)',
      step02: '1st Application<br />Screening(Document)',
      step03: '2nd Local Advisory<br />Group Screening<br />(document and<br />face-to-face interview<br />/on-line)',
      step04: 'Final Selection',
      reference01: `※ Business preparation(mentoring) in Korea
      <br />and initiate local company building
      <br />& soft landing after final selection`,
      reference02: `※ Open recruitment will be available at all times,
      <br />the result of the first document review
      <br />will be notified after the collective review
      <br />(the collective reivew is going to be held
      <br />the following month of the first document review)`,
    },
    advisory: {
      introduction: '',
      ref_site: '',
      kkr_website: 'KKR Website',
      twitter: 'Twitter',
      kkr: `KKR & CO. Inc. is an American- based global investment company that manages multiple alternative asset classes, including capital market business, insurance solutions, etc. 
      KRR & CO. Inc. aims on generating high return on their investments which contain skilled investment practices, recruitment of global talents and growth support of portfolio companies and communities. 
      KRR & CO. Inc. is responsible for investment funds as private equity, credit, and physical assets. Also, it has numerous strategic partners who manage hedge funds.`,
      tpg_capital: `TPG is a global alternative investment company with $96 billion in assets under management. 
      The company mainly manages investment funds in private equity, real estate and listed stocks. 
      In particular, the company also possesses capabilities of establishing various growth investment startegies. 
      TPG explores innovative investment opportunities by utilizing global network and partners, playing a role in both a Solution Provider and Problem Solver in the investment ecosystem.`,
      altos_ventures: `Altos Ventures was founded in 1996, based in Menlo Park, California. 
      In regardless of business sector, the company mainly invests in various early-stage startups that have huge potential.`,
      john_nam: `John is a co-founder of Strong Ventures who focuses on investing in B2C based Fintech, Digital Media companies in South Korea and North America. 
      In prior to establishing Strong Ventures, John worked for a company called Diapad as a business development manager and senior product manager at Silicon Valley. After that, John cofounded Phonevite which won Internet Telephony award. 
      Moreover, he has work experieces in international finance department at US Bank and Comerica Bank, and IT management team at UN. 
      In addition, John got bachelor's degree in economics and master's degree in Columbia University.`,
      kiha_lee: `Ki-ha Lee is a successful entrepreneur and venture capitalist in the U.S., who has been playing a great role for constructing Korean startup ecosystem in Sillicon Valley for past two decades. 
      He also works as a partner of Primer Saje Partners, operating a startup community called 82 Startups which supports Korean entrepreneurs who willing to launch their business in the U.S.`,
      jaewon_song: `Jae-won Song is known for his multidiciplinary intellectual property practice, including strategic intellectual property counseling, patent prosecution and patent litigation. 
      He has plentiful experience in strategic patent counseling and has prosecuted numerous international patents in a range of different business fields such as autonomous driving, artificial intelligence(AI), blockchain technology, fintech, virtual reality(VR), computer software, health information technology and bioinformatics, semiconductor and other innovative technologies.`,
      hyunsuk_kang: 'Professor Kang Hyun-suk is an educated medical scholar in both Korea and the U.S. and has focused on clinical research in developing new treatments and biomarkers for cancer.',
      soh_kim: `So hyung Kim is the head of Standford center at Incheon global campus and a professor of engineering at Stanford University. 
      Her research is mainly focused on food innovation and design, and she is currently a lead of multicultural Design Thinking research at Stanfod University. 
      Prof. Kim studied psychology at Korea University, then she got mater's degree in mechanical engineering at London school of Economics and Political Science(LSE) and Stanford University. After that, she also got a doctor's degree in innovation managment at UC Berkeley.`,
    },
    footer: {
      privacy_policy: 'Privacy Policy',
      terms_of_use: 'Terms of Use',
      contact: 'Contact us',
    },
  },
};
