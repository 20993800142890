const postpositions = {
  EunNeun: ['은', '는'],
  IGa: ['이', '가'],
  EulReul: ['을', '를'],
};

/**
 * 한글 조사
 */
export default class KoreanJosa {
  /**
   * 은/는
   * @return {string[]}
   */
  static get EunNeun() {
    return postpositions.EunNeun;
  }

  /**
   * 이/가
   * @return {string[]}
   */
  static get IGa() {
    return postpositions.IGa;
  }

  /**
   * 을/를
   * @return {string[]}
   */
  static get EulReul() {
    return postpositions.EulReul;
  }

  /**
   * 종성이 있는지 여부
   * AC00은 가의 code point
   * 28은 종성의 숫자
   * @param word
   */
  static hasJongSeong(word) {
    const lastWord = word.charCodeAt(word.length - 1);
    return (lastWord - 0xac00) % 28 > 0;
  }

  /**
   * @param {string} word
   * @param {string[]} josa
   * @return {string}
   */
  static getJosa(word, josa) {
    return this.hasJongSeong(word) ? josa[0] : josa[1];
  }

  /**
   * @param {string} word
   * @param {string[]} josa
   * @return {string}
   */
  static getWordWithJosa(word, josa) {
    return `${word}${this.getJosa(word, josa)}`;
  }
}
