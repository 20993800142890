/** EVENT & NEWS - Event */
import { container, append } from '@/common/helperUtils';

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

export default [
  {
    path: '',
    redirect: 'list',
  },
  {
    path: 'list',
    name: 'EventList',
    component: () => import(`@/views/event/${appendName}EventList.vue`),
    meta: {
      layout: container,
      title: 'Event',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: 'my',
    name: 'MyEvent',
    component: () => import(`@/views/event/${appendName}MyHistory.vue`),
    meta: {
      layout: container,
      title: 'Event',
      requiresAuth: true,
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: ':eventNo(\\d+)',
    name: 'EventDetailNo',
    component: () => import(`@/views/event/${appendName}EventDetailNo.vue`),
    meta: {
      layout: container,
      title: 'Event',
      headertype: 'sub',
      metaInfo: noindexMetaInfo
    },
    redirect: 'detail',
    props: true,
    children: [
      {
        path: 'detail',
        name: 'EventDetail',
        component: () => import(`@/views/event/${appendName}EventDetail.vue`),
        meta: {
          layout: container,
          title: 'Event',
          headertype: 'sub',
          metaInfo: noindexMetaInfo
        },
      },
      {
        path: 'applyandhistory',
        name: 'EventApplyAndHistory',
        component: () => import(`@/views/event/${appendName}ApplyAndHistory.vue`),
        meta: {
          layout: container,
          title: 'Event',
          headertype: 'sub',
          metaInfo: noindexMetaInfo
        },
        props: true,
        children: [
          {
            path: 'apply',
            name: 'EventApply',
            component: () => import(`@/views/event/${appendName}ApplyStep1.vue`),
            meta: {
              layout: container,
              requiresAuth: true,
              title: 'Event',
              headertype: 'sub'
            },
            props: true,
          },
          {
            path: 'apply',
            name: 'EventApplyNonMember',
            component: () => import(`@/views/event/${appendName}ApplyNonMember.vue`),
            meta: {
              layout: container,
              title: 'Event',
              headertype: 'sub'
            },
            props: true,
          },
          {
            path: 'history',
            name: 'EventHistory',
            component: () => import(`@/views/event/${appendName}History.vue`),
            meta: {
              layout: container,
              requiresAuth: true,
              title: 'Event',
              headertype: 'sub'
            },
          },
        ],
      },
    ],
  },
];
