/** Reservation - Event */
import { container, append } from '@/common/helperUtils';

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

export default [
  {
    path: '',
    redirect: 'intro',
  },
  {
    path: 'intro',
    name: 'ReservationIntro',
    component: () => import(`@/views/reservation/${appendName}Intro.vue`),
    meta: {
      layout: container,
      title: 'Reservation',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: 'application',
    name: 'ReservationApplication',
    component: () => import(`@/views/reservation/${appendName}Application.vue`),
    meta: {
      layout: container,
      requiresAuth: true,
      title: 'Reservation',
      headertype: 'sub',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: 'applyandhistory',
    name: 'ReservationApplyAndHistory',
    component: () => import(`@/views/reservation/${appendName}ApplyAndHistory.vue`),
    meta: {
      layout: container,
      requiresAuth: true,
      title: 'Reservation',
      headertype: 'sub',
      metaInfo: noindexMetaInfo
    },
    props: true,
    children: [
      {
        path: 'apply',
        name: 'ReservationApply',
        component: () => import(`@/views/reservation/${appendName}ApplyStep1.vue`),
        meta: {
          layout: container,
          title: 'Reservation',
          headertype: 'sub',
          metaInfo: noindexMetaInfo
        },
        props: true,
      },
      {
        path: 'history',
        name: 'ReservationHistory',
        component: () => import(`@/views/reservation/${appendName}History.vue`),
        meta: {
          layout: container,
          title: 'Reservation',
          headertype: 'sub',
          metaInfo: noindexMetaInfo
        },
        // props: true,
      },
    ],
  },
];
