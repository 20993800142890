import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  /**
   * 사용자 정보
   */
  userInfo: undefined,
  /**
   * (소셜 로그인) 사용자 정보
   */
  oauthUserInfo: undefined,
  height: 0,
  pagestep: 1,
  isFooter: true,
  fixBtnHeight: 0,
}

let logoutTimeout = null
function checkLogoutTimeout () {
  if (logoutTimeout) {
    clearInterval(logoutTimeout)
    logoutTimeout = null
  }
  logoutTimeout = setInterval(() => {
    // logout
    if (state.userInfo) {
      window.location.href = '/signout'
    }
  }, 3600 * 1000) // 1 hour
}

window.addEventListener('pointerdown', () => {
  checkLogoutTimeout()
})

export default new Vuex.Store({
  /**
   * Strict Mode
   * @see {@link https://vuex.vuejs.org/guide/strict.html}
   */
  strict: true,

  /** (Root) State */
  state: state,

  /** (Root) Getters */
  getters: {
    getUserInfo: (state) => () => state.userInfo,
    getHeight: (state) => state.height,
    getIsFooter: (state) => state.isFooter,
    getFixBtnHeight: (state) => state.fixBtnHeight,
    getPagestep: (state) => state.pagestep,
  },

  /** (Root) Mutations */
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },

    setHeight(state, height) {
      state.height = height;
    },

    setIsFooter(state, isFooter) {
      state.isFooter = isFooter;
    },

    setFixBtnHeight(state, fixBtnHeight) {
      state.fixBtnHeight = fixBtnHeight;
    },

    setOauthUserInfo(state, oauthUserInfo) {
      state.oauthUserInfo = oauthUserInfo;
    },

    setPagestep(state, pagestep) {
      state.pagestep = pagestep;
    },
  },

  /** (Root) Actions */
  actions: {
    setUserInfo({ state }, userInfo) {
      Object.assign(state.userInfo, userInfo);
    },
    updateLogoutTimeout({state}) {
      checkLogoutTimeout();
    }
  },

  /**
   * Modules
   * @see {@link https://vuex.vuejs.org/guide/modules.html}
   */
  modules: {
  },
});
