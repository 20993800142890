/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    txt01: '서비스 이용정보',
    txt02: '플레이어디비 메시지함',
    txt03: '개인정보',
    txt04: 'My Class',
  },
  ServiceInfo: {
    txt01: '관리자만 보기 게시물',
    txt02: '신청일',
    txt03: '신청취소',
    txt04: '행사완료',
    txt05: '문의',
    txt06: '답변완료',
    txt07: '답변대기',
    txt08: '답변보기',
    txt09: '내가 남긴 댓글',
    txt10: '비공개 ',
    txt11: '서비스 이용정보가 없습니다. ',
    txt12: '지원일 ',
    txt13: '상태',
    txt14: '지원완료',
    txt15: '지원취소',
    txt16: '(개별연락)',
    txt17: '불합격',
    txt18: '공모 완료',
    txt19: '대관취소',
    txt20: '행사취소',
  },
  MessageBox: {
    txt01: '받은메시지',
    txt02: '보낸메시지',
    txt03: '선택삭제',
    txt04: '새로고침',
    txt05: '답변',
    txt06: '받는사람',
    txt07: '메시지가 없습니다.',
  },
  UserInfo: {
    txt01: '이미지 변경',
    txt02: '비밀번호 변경',
    txt03: '비밀번호 재설정',
    txt04: '전화번호',
    txt05: '로그인 연동(Single Sign On) 동의',
    txt06: '연동',
    txt07: '연동하지 않음',
    txt08: '내용보기',
    txt09: '정보수신 동의',
    txt10: '인트라에 센터관련 새 글 등록 시 이메일로 알림을 받습니다.',
    txt11: '신규 이벤트 등록 시 이메일로 알림을 받습니다. ',
    txt12: '회원탈퇴',
    txt13: '개인정보 변경',
    txt14: 'OA 코드',
  },
  myClass: {
    txt01: '수강중',
    txt02: '총 진도율',
    txt03: '총 수강시간',
    txt04: '수강완료 강의',
    txt05: '총 강의수',
    txt06: '강',
    txt07: '강의 보러 가기',
    txt08: '수강 내역',
    txt09: '완료',
    txt10: '미수강',
    txt12: '수강시간',
    txt13: '최초수강일',
    txt14: '최종수강일',
    txt15: '수강상태',
    txt16: '제목',
    txt17: '강의 신청 하기',
    txt18: '게시물이 없습니다.',
  },
  SearchEmail: {
    txt01: '아이디 찾기',
    txt02: '닉네임 입력',
    txt03: '기존 드림플러스 강남 입주 회원인 경우',
    txt03_1: '여기',
    txt03_2: '를 눌러 ID찾기를 진행하세요.',
    txt04: '회원님의 아이디는',
    txt04_1: '입니다.',
    txt05: '회원 아이디를 찾을 수 없습니다.',
    txt06: '위의 방법으로 E-mail ID를 찾을 수 없는 경우, 관리자(<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">contact@dreamplus.asia</a>)에게 문의해주시기 바랍니다.',
    txt07: '드림플러스 강남센터나 63 핀테크센터에 입주하신 회원께서는 각 센터 관리자에게 문의해주시기 바랍니다.',
    txt08: 'SNS로 가입하신 경우 해당 SNS 서비스를 이용해주십시오.',
  },
  SearchPassword: {
    txt01: '비밀번호 찾기',
    txt02: 'E-mail ID 입력',
    txt03: '메일 전송 완료',
    txt04: '비밀번호를 재설정 할 수 있는 링크가 고객님의 E-mail',
    txt04_1: '으로 전송되었습니다.',
    txt05: '전송된 E-mail을 확인하신 후 비밀번호를 재설정 해주시기 바랍니다.',
    txt06: '발송 된 비밀번호 설정 이메일을 확인 해주세요.',
    txt07: '회원 아이디를 찾을 수 없습니다.',
    txt08: '위의 방법으로 Password를 찾을 수 없는 경우, 관리자(<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">contact@dreamplus.asia</a>)에게 문의해주시기 바랍니다.',
    txt09: '비밀번호로 재설정 메일은 암호화되어 자동으로 발송되므로 일부 이메일 서비스의 경우 스팸으로 처리 할 수도 있습니다.',
    txt10: '메일이 도착하지 않은 경우 스팸메일 폴더를 확인하시기 바랍니다.',
  },
  ResetPassword: {
    txt01: '비밀번호 리셋',
    txt02: '비밀번호 재설정',
    txt03: '새로운 비밀번호 입력',
    txt04: '새로운 비밀번호 확인',
    txt05: '비밀번호는 영문+숫자+특수문자 조합으로 8자 이상 15자 이하여야 합니다.',
    txt06: '비밀번호 재설정에 실패할 경우 <a href="mailto:contact@dreamplus.asia">관리자메일(contact@dreamplus.asia)</a>로 문의해 주세요.',
    txt07: '비밀번호 설정이 완료 되었습니다.',
    txt08: `비밀번호 재설정에 실패하였습니다.<br />
    지속적으로 재설정에 실패할 경우 <br /><a href="mailto:contact@dreamplus.asia">관리자메일(contact@dreamplus.asia)</a>로 문의해 주세요.`,
  },
  NoAuth: {
    txt01: '아직 E-mail 인증이 완료되지 않았습니다.',
    txt02: '지금 <span>[{email}]</span>으로 E-mail이 전송되었습니다.',
    txt03: '전송된 E-mail을 확인하신 후 인증 링크를 클릭해주십시오.',
    txt04: '인증메일 재전송',
    txt05: '비밀번호 재설정이 필요합니다.',
    txt06: '드림플러스 강남 센터가 드림플러스 이노베이션 허브로 리뉴얼 됨에 따라 드림플러스 이노베이션 허브 로그인을 위해 비밀번호 재설정이 필요합니다.',
    txt07: '<span>[{email}]</span>을 확인하신 후 비밀번호를 설정 해주시기 바랍니다.',
    txt08: '메일 재전송',
    txt09: '파트너 회원으로 신청한 경우 관리자 승인 후 인증메일이 발송됩니다.',
    txt10: '메일이 도착하지 않은 경우 인증메일 재전송을 이용해주십시오.',
    txt11: '메일 서비스에 따라 도착 시간이 조금 지연될 수 있습니다.',
    txt12: '메일이 오랫동안 도착하지 않은 경우, 스팸메일함 확인을 부탁드립니다.',
    txt13: '위의 방법으로도 메일이 오지 않는 경우, 관리자(<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">dreamplus@dreamplus.asia</a>)에게 문의해주시기 바랍니다.',
  },
  popup: {
    InquiryReply: {
      txt01: '문의 답변보기',
    },
    SearchTenantEmail: {
      txt01: '등록된 이름을 입력 해주세요.',
      txt02: '등록된 전화번호를 \'-\' 없이 입력 해주세요.',
      txt03: '위의 방법으로 E-mail ID를 찾을 수 없는 경우,',
      txt04: '관리자<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">(contact@dreamplus.asia)</a>에게 문의해주시기 바랍니다.',
      txt05: '회원님의 아이디는',
      txt06: '입니다.',
    },
  },
};
