/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    txt01: 'Information on service use',
    txt02: 'Player DB Inbox',
    txt03: 'Personal information',
    txt04: 'My Class',
  },
  ServiceInfo: {
    txt01: 'Admin.-only posts',
    txt02: 'Application date',
    txt03: 'Cancel',
    txt04: 'Event ended',
    txt05: 'Inquiry',
    txt06: 'Answered',
    txt07: 'Waiting for answer',
    txt08: 'See answer',
    txt09: 'My comment',
    txt10: 'Undisclosed ',
    txt11: 'There is no service record.',
    txt12: 'Application date',
    txt13: 'Status',
    txt14: 'Application completed',
    txt15: 'Cancel',
    txt16: '(will be individually contacted)',
    txt17: 'Fail',
    txt18: 'Contest closed',
    txt19: 'Rental cancelled',
    txt20: 'Event cancelled',
  },
  MessageBox: {
    txt01: 'Received message',
    txt02: 'Sent message',
    txt03: 'Delete selected',
    txt04: 'Reload',
    txt05: 'Answer',
    txt06: 'Recipient',
    txt07: 'There is no message.',
  },
  UserInfo: {
    txt01: 'Change image',
    txt02: 'Change password',
    txt03: 'Reset password',
    txt04: 'Phone Number',
    txt05: 'SSO connection',
    txt06: 'Connect',
    txt07: 'Do not connect',
    txt08: 'Content',
    txt09: 'Agree to receive information',
    txt10: 'Notification will be sent via e-mail if a new post is uploaded regarding the Center on the Intranet.',
    txt11: 'Receive email notification in case new event is registered.',
    txt12: 'Membership withdrawal',
    txt13: 'Change personal information',
    txt14: 'OA CODE',
  },
  myClass: {
    txt01: 'DURING CLASS',
    txt02: 'Total progress rate',
    txt03: 'Total class time',
    txt04: 'Completed classes',
    txt05: 'Total classes',
    txt06: 'classes',
    txt07: 'View the class',
    txt08: 'class details',
    txt09: 'complete',
    txt10: 'No class',
    txt12: 'Class time',
    txt13: 'The first day',
    txt14: 'The final day',
    txt15: 'Status',
    txt16: 'Topics',
    txt17: 'Application for class',
    txt18: 'There are no posts',
  },
  SearchEmail: {
    txt01: 'Forgot my ID',
    txt02: 'Enter nickname',
    txt03: 'If you are a DREAMPLUS Gangnam tenant, please',
    txt03_1: 'click here',
    txt03_2: 'to retrieve your ID.',
    txt04: 'Your ID is',
    txt04_1: '',
    txt05: 'Your ID cannot be found.',
    txt06: 'If you cannot retrieve your ID with the above methods, please contact the manager at(<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">contact@dreamplus.asia</a>).',
    txt07: 'If you are a tenant of DREAMPLUS Gangnam Center or DREAMPLUS 63 Fintech Center, please contact the manager of your center.',
    txt08: 'If you used your social media account to sign up, please direct your inquires to the social media platform.',
  },
  SearchPassword: {
    txt01: 'Forgot my password',
    txt02: 'Enter email/ID',
    txt03: 'Email sent.',
    txt04: 'A password reset URL has been sent to your email at ',
    txt04_1: '',
    txt05: 'Please check the email to reset your password.',
    txt06: 'Please check your email.',
    txt07: 'ID cannot be found.',
    txt08: 'If you cannot retrieve your password with the above methods, please contact the manager at(<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">contact@dreamplus.asia</a>)',
    txt09: 'Since the password reset email is encrypted and automatically sent, some email services may classify it as spam. ',
    txt10: 'If you have not received the email, please check the spam folder.',
  },
  ResetPassword: {
    txt01: 'Reset password',
    txt02: 'Reset password',
    txt03: 'Enter new password',
    txt04: 'Re-enter new password',
    txt05: 'Password must have 8-15 characters with a combination of English alphabet, numbers and special characters.',
    txt06: 'If password reset fails, please contact the manager at <a href="mailto:contact@dreamplus.asia">manager at(contact@dreamplus.asia)</a>',
    txt07: 'Password reset complete.',
    txt08: `Password reset failed.<br />
    If password reset repeatedly fails, <br />please contact the<a href="mailto:contact@dreamplus.asia">manager at(contact@dreamplus.asia)</a>`,
  },
  NoAuth: {
    txt01: 'E-mail verification has not been completed yet.',
    txt02: 'E-mail has just been sent to <span>[{email}]</span>',
    txt03: 'Please find an e-mail we\'ve just sent to you and click the verification link.',
    txt04: 'Re-send authentication email',
    txt05: 'You need to reset password.',
    txt06: 'As DREAM PLUS Gangnam Center is renewed as DREAM PLUS Innovation Hub, you need to reset password to log in to DREAM PLUS Innovation Hub.',
    txt07: 'Please find<span>[{email}]</span>and reset password.',
    txt08: 'Resend an E-mail',
    txt09: 'If you apply for partner membership, a verification e-mail will be sent after approval by manager.',
    txt10: 'If the e-mail is not sent, please click re-sending the verification e-mail.',
    txt11: 'Depending on e-mail service, the arrival of the e-mail might be delayed a bit.',
    txt12: 'If the e-mail is not sent for long, please check your spam mail box.',
    txt13: 'If the e-mail is not sent even after using the methods as above, please contact our manager (<a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">dreamplus@dreamplus.asia</a>)',
  },
  popup: {
    InquiryReply: {
      txt01: 'See the answer to your inquiry',
    },
    SearchTenantEmail: {
      txt01: 'Enter the name you registered.',
      txt02: 'Enter the registered phone number without using \'-\'',
      txt03: 'If you cannot retrieve your ID with the above methods,',
      txt04: 'please contact the manager at <a href="mailto:contact@dreamplus.asia" title="새창으로 이동" class="mail">(contact@dreamplus.asia)</a>',
      txt05: 'Your ID is',
      txt06: '',
    },
  },
};
