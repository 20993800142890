<template>
    <component :is="containerComponent" >
      <router-view />
    </component>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'App',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    PcContainer: () => import('@/views/layout/PcContainer.vue'),
    MobileContainer: () => import('@/views/layout/MobileContainer.vue'),
  },

  data() {
    return {
      containerComponent: 'div',
    };
  },
  computed: {
    metaLayout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    metaLayout: {
      handler(layout) {
        this.containerComponent = this.getComponent(layout);
      },
    },
  },
  methods: {
    getComponent(layout) {
      return this.$options.components[layout || this.$route.meta.layout];
    },
  },
};
</script>
