import Vue from 'vue';
import VModal from 'vue-js-modal'; // vue-js-modal : https://github.com/euvl/vue-js-modal
import VueMoment from 'vue-moment'; // vue-moment : https://github.com/brockpetrie/vue-moment
// import moment from 'moment'; // moment : https://momentjs.com/
import moment from 'moment-timezone'; // moment-timezone : https://momentjs.com/timezone/
import VueCookies from 'vue-cookies'; // vue-cookies : https://github.com/cmp-cc/vue-cookies
import VueI18n from 'vue-i18n'; // Vue I18n : https://kazupon.github.io/vue-i18n/
import { extend, localize } from 'vee-validate'; // https://logaretm.github.io/vee-validate
import {
  required, email, regex, required_if as requiredIf, oneOf,
} from 'vee-validate/dist/rules';
import { isValidPhone } from '@/common/Validator';

// import viewport
import VueHead from 'vue-head';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/letter.css';
import './assets/css/contest.css';

// import './assets/css/reset.css';
// import './assets/css/common.css';
// import './assets/css/contents.css';
// import './assets/css/main_new.css';

// import locale
import ko from './locales/ko';
import en from './locales/en';

// SEO
import Meta from 'vue-meta';

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

Vue.use(VueHead);

Vue.config.productionTip = false;

// vue-js-modal - Dynamic Modal : https://github.com/euvl/vue-js-modal#dynamic-modals
Vue.use(VModal, { dynamic: true });

// vue-cookies
Vue.use(VueCookies);
const locale = VueCookies.get('language') || 'ko';

// vue-moment : https://github.com/brockpetrie/vue-moment#configuration
moment.locale(locale);
Vue.use(VueMoment, { moment });

// Vue I18n
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages: {
    ko,
    en,
  },
});

// VeeValidate - Adding Rules : https://logaretm.github.io/vee-validate/guide/basics.html#adding-rules
extend('required', required);
extend('requiredIf', requiredIf);
extend('email', email);
extend('regex', regex);
extend('oneOf', oneOf);
extend('fieldRequired', {
  validate: (value) => !!value && !!value.trim(),
  computesRequired: true,
});
extend('phone', {
  validate: isValidPhone,
});

// VeeValidate - Localization : https://logaretm.github.io/vee-validate/guide/localization.html
localize({
  ko: ko.validations,
  en: en.validations,
});

localize(locale);

// html lang : https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/lang
document.documentElement.lang = locale;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
