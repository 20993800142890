/* eslint-disable max-len */
export default {
  pc: {
    common: {
      venture_studio_desc: `DREAMPLUS는 Open Innovation 생태계의 다양한 참여자들 간의 원활한 Connectivity 구축을 위해
      <br />국내는 물론 해외까지 글로벌 거점(Physical hub)들을 확보하며, 글로벌 네트워크를 확장하고 있습니다.`,
      world_program_desc: `한화생명 드림플러스는 함께 새로운 가치를 만들어 나아갈 다양한 분야의 Tech 기반 스타트업을 찾고 있습니다.
      <br />글로벌 Top-tier의 멘토단이 이끄는 한국 스타트업의 글로벌 진출 프로그램(K-Startup to the world Program)은
      <br />혁신적 사업모델과 성장성을 검증 받은 유망 스타트업을 발굴하여 빠른 해외진출 및 현지 사업화를 지원합니다.`,
      benefits_desc: `국내 및 해외 현지 전담 액셀러레이터 배정 및 현지 드림플러스 사무소 오피스 제공, 글로벌 시장진출 Feasibility 검증,
      <br />현지 Advisory Group을 통한 글로벌 소프트 랜딩 멘토링, 현지 비즈니스 자문 및 밸류업 파트너 네트워킹 제공,
      <br />글로벌 투자 파이프라인 검토 등 국내 어디에서도 볼 수 없었던 최고의 멘토 서비스를 제공합니다.`,
    },
    benefits: {
      business_connecting: '전담 ACCELERATOR를 통한<br />맞춤형 지원',
      workspace: '현지 DREAMPLUS OFFICE 제공',
      founder_community: '현지 비즈니스/투자 네트워킹',
    },
    process: {
      step01: '공개모집<br />(상시)',
      step02: '1차 국내심사<br />(서류)',
      step03: '2차 현지<br />Advisory Group 심사<br />(서류 및 대면/화상)',
      step04: '최종 선발',
      reference01: '※ 최종 선발 이후 국내 멘토링 및 현지 Company Building & Soft Landing 진행',
      reference02: '※ 상시 공개 모집 되며 1차 서류심사 결과는 지원 익월 일괄 심사 진행 후 개별 안내 예정',
    },
    advisory: {
      introduction: '소개',
      ref_site: '참고 사이트',
      kkr_website: 'KKR 웹사이트',
      twitter: '트위터',
      kkr: `KKR은 대체자산운용과 자본시장, 보험솔루션 등을 제공하는 글로벌 리딩 투자기업이다.
      <br />KKR은 매우 숙련된 투자 방식과 글로벌 탑급의 인재 채용, 그리고 포트폴리오 기업 및 커뮤니티 내에서의 성장을 지원함으로써, 매력적인 투자 수익을 창출하는 것을 목표로 한다.
      <br />KKR은 사모펀드(PE), 신용, 실물자산에 투자하는 투자 펀드를 주관하고 있으며, 헤지펀드를 운용하는 전략적 파트너들을 두고 있다.
      <br />KKR의 보험 자회사들은 The Global Atlantic Financial Group의 경영 하에 퇴직, 종신, 재보험 상품 등을 제공한다.
      <br />KKR의 투자에는 KKR의 후원 기금과 보험 자회사들의 활동도 포함된다.`,
      tpg_capital: `TPG는 운용규모 약 100조원 이상의 글로벌 대체투자 운용사로서 사모펀드(PE), 부동산 및 상장 주식 등 다양한 투자기회를 발굴하고 선도적으로 투자하는 플랫폼이다.
      <br />특히 사모펀드가 대표적인 전문분야이지만, 이와 연계된 다양한 성장투자전략에도 핵심역량을 보유하고 있다.
      <br />특히 TPG는 설립이래 새로운 혁신투자 기회를 발굴하고 이를 대표적인 투자전략으로 견인하는데 중점을 두어 투자 생태계를 선도하고 있다.
      <br />더불어 다양한 지적자산과 보유 네트워크를 활용하여 글로벌 전 지역의 피투자기업과 파트너 기업의 Solution Provider이면서 Problem Solver 로서의 역할을 적극 수행하고 있다.`,
      altos_ventures: `1996년 설립된 알토스벤처스는(Altos Ventures)는 미국 캘리포니아주 멘로파크에 본사를 둔 벤처캐피털이다.
      <br />알토스벤처스는 기업 및 소비자 전 부분의 다양한 사업을 운영하는 초기(early-stage) 기업에 투자하고자 한다.`,
      john_nam: `John은 Strong Ventures의 공동 창업자이자 파트너로서, 한국과 북미 모두에서 B2C, 핀테크, 디지털미디어 영역의 투자에 초점을 맞추고 있다.
      <br />그는 Strong Ventures 창업 전, ‘Diapad’에서 사업개발 매니저와 수석 프로덕트 매니저로서 실리콘밸리 커리어를 시작하였으며, ‘Internet Telephony’ 어워즈 수상 이력이 있는 ‘Phonevite’를 공동창업 했었다.
      <br />이 뿐만 아니라, US 은행과 Comerica 은행의 국제 금융부서, 그리고 UN에서 IT매니지먼트로서 일한 경력도 있다.
      <br />John 은 컬럼비아 대학교에서 경제학 학사 및 국제학 석사 학위를 받았다.`,
      kiha_lee: `이기하 대표는 미국에서 성공한 기업가이자 벤처캐피탈리스트로 활동하면서, 지난 20여년간 실리콘밸리의 한국 스타트업 생태계 구축에 매진하고 있다.
      <br />현재 프라이머사제파트너스의 파트너로서, 미국에서 창업하는 한국인 기업가들을 지원하기 위한 스타트업 커뮤니티인 ’82 Startups’를 운영하고 있다.`,
      jaewon_song: `송재원파트너는 지적재산권(IP), 전략 자문, 특허 심사/소송, IP 거래 등 다방면의 지적재산권 전문가로 잘 알려져 있다.
      <br />그는 자율주행, 인공지능(AI), 블록체인, 핀테크, 가상/증강현실(VR/AR), 컴퓨터 소프트웨어, 헬스케어 정보기술(HIT), 반도체, 디스플레이 테크놀로지/가전제품 등 다양한 혁신 기술 분야에서 실리콘밸리와 국내에 설립된 기성 다국적 기업은 물론 스타트업의 IP 자산을 창출하고 보호하는데 20년 이상의 경력을 보유하고 있다.
      <br />또한 , 특허와 기타 IP 자산의 허가, 판매, 구매뿐만 아니라 여러 미국 법원과 미국 국제 무역 위원회에서의 IP소송에 대한 다양한 경험이 있다.`,
      hyunsuk_kang: '강현석 교수는 한국과 미국에서 모두 교육받은 의학 학자로 암을 위한 새로운 치료법과 바이오마커의 개발에 있어 임상 연구에 집중해오고 있다.',
      soh_kim: `김소형 센터장은 인천 글로벌 캠퍼스의 스탠퍼드 센터의 총괄이사이자, 스탠퍼드 대학교의 공과대학 겸임 교수이다.
      <br />그녀의 연구 분야는 푸드 이노베이션과 디자인이며, 스탠퍼드 대학교에서 다문화 디자인 씽킹(Design Thinking) 연구를 리드하고 있다.
      <br />김소형 교수는 고려대학교에서 심리학을 전공하고, 런던 정경대학교(LSE)와 스탠퍼드 대학교에서 기계공학 석사 학위를 취득한 뒤, UC버클리에서 혁신 경영학 박사 학위를 받았다.`,
    },
  },
  mobile: {
    common: {
      venture_studio_desc: `DREAMPLUS는 Open Innovation 생태계의
      <br />다양한 참여자들 간의 원활한 Connectivity 구축을 위해
      <br />국내는 물론 해외까지 글로벌 거점(Physical hub)들을
      <br />확보하며, 글로벌 네트워크를 확장하고 있습니다.`,
      world_program_desc: `한화생명 드림플러스는 함께
      <br />새로운 가치를 만들어 나아갈 다양한 분야의
      <br />Tech 기반 스타트업을 찾고 있습니다.
      <br />글로벌 Top-tier의 멘토단이 이끄는 한국 스타트업의
      <br />글로벌 진출 프로그램(K-Startup to the world Program)은 혁신적 사업모델과 성장성을 검증 받은
      <br />유망 스타트업을 발굴하여 빠른 해외진출 및
      <br />현지 사업화를 지원합니다.`,
      benefits_desc: `국내 및 해외 현지 전담 액셀러레이터 배정 및 
      <br />현지 드림플러스 사무소 오피스 제공, 글로벌 시장진출
      <br />Feasibility 검증, 현지 Advisory Group을 통한 글로벌
      <br />소프트 랜딩 멘토링, 현지 비즈니스 자문 및 밸류업 파트너
      <br />네트워킹 제공, 글로벌 투자 파이프라인 검토 등 국내
      <br />어디에서도 볼 수 없었던 최고의 멘토 서비스를 제공합니다.`,
    },
    benefits: {
      business_connecting: '전담 ACCELERATOR를 통한<br />맞춤형 지원',
      workspace: '현지 DREAMPLUS OFFICE 제공',
      founder_community: '현지 비즈니스/투자 네트워킹',
    },
    process: {
      step01: '공개모집<br />(상시)',
      step02: '1차 국내심사<br />(서류)',
      step03: '2차 현지<br />Advisory Group 심사<br />(서류 및 대면/화상)',
      step04: '최종 선발',
      reference01: `※ 최종 선발 이후 국내 멘토링 및 현지
      <br />Company Building & Soft Landing 진행`,
      reference02: `※ 상시 공개 모집 되며 1차 서류심사 결과는
      <br />지원 익월 일괄 심사 진행 후 개별 안내 예정`,
    },
    advisory: {
      introduction: '소개',
      ref_site: '참고 사이트',
      kkr_website: 'KKR 웹사이트',
      twitter: '트위터',
      kkr: `KKR은 대체자산운용과 자본시장, 보험솔루션 등을 제공하는 글로벌 리딩 투자기업이다. 
      KKR은 매우 숙련된 투자 방식과 글로벌 탑급의 인재 채용, 그리고 포트폴리오 기업 및 커뮤니티 내에서의 성장을 지원함으로써, 매력적인 투자 수익을 창출하는 것을 목표로 한다. 
      KKR은 사모펀드(PE), 신용, 실물자산에 투자하는 투자 펀드를 주관하고 있으며, 헤지펀드를 운용하는 전략적 파트너들을 두고 있다. 
      KKR의 보험 자회사들은 The Global Atlantic Financial Group의 경영 하에 퇴직, 종신, 재보험 상품 등을 제공한다. 
      KKR의 투자에는 KKR의 후원 기금과 보험 자회사들의 활동도 포함된다.`,
      tpg_capital: `TPG는 운용규모 약 100조원 이상의 글로벌 대체투자 운용사로서 사모펀드(PE), 부동산 및 상장 주식 등 다양한 투자기회를 발굴하고 선도적으로 투자하는 플랫폼이다. 
      특히 사모펀드가 대표적인 전문분야이지만, 이와 연계된 다양한 성장투자전략에도 핵심역량을 보유하고 있다. 
      특히 TPG는 설립이래 새로운 혁신투자 기회를 발굴하고 이를 대표적인 투자전략으로 견인하는데 중점을 두어 투자 생태계를 선도하고 있다. 
      더불어 다양한 지적자산과 보유 네트워크를 활용하여 글로벌 전 지역의 피투자기업과 파트너 기업의 Solution Provider이면서 Problem Solver 로서의 역할을 적극 수행하고 있다`,
      altos_ventures: `1996년 설립된 알토스벤처스는(Altos Ventures)는 미국 캘리포니아주 멘로파크에 본사를 둔 벤처캐피털이다. 
      알토스벤처스는 기업 및 소비자 전 부분의 다양한 사업을 운영하는 초기(early-stage) 기업에 투자하고자 한다.`,
      john_nam: `John은 Strong Ventures의 공동 창업자이자 파트너로서, 한국과 북미 모두에서 B2C, 핀테크, 디지털미디어 영역의 투자에 초점을 맞추고 있다. 
      그는 Strong Ventures 창업 전, ‘Diapad’에서 사업개발 매니저와 수석 프로덕트 매니저로서 실리콘밸리 커리어를 시작하였으며, ‘Internet Telephony’ 어워즈 수상 이력이 있는 ‘Phonevite’를 공동창업 했었다. 
      이 뿐만 아니라, US 은행과 Comerica 은행의 국제 금융부서, 그리고 UN에서 IT매니지먼트로서 일한 경력도 있다. 
      John 은 컬럼비아 대학교에서 경제학 학사 및 국제학 석사 학위를 받았다.`,
      kiha_lee: `이기하 대표는 미국에서 성공한 기업가이자 벤처캐피탈리스트로 활동하면서, 지난 20여년간 실리콘밸리의 한국 스타트업 생태계 구축에 매진하고 있다. 
      현재 프라이머사제파트너스의 파트너로서, 미국에서 창업하는 한국인 기업가들을 지원하기 위한 스타트업 커뮤니티인 ’82 Startups’를 운영하고 있다.`,
      jaewon_song: `송재원파트너는 지적재산권(IP), 전략 자문, 특허 심사/소송, IP 거래 등 다방면의 지적재산권 전문가로 잘 알려져 있다. 
      그는 자율주행, 인공지능(AI), 블록체인, 핀테크, 가상/증강현실(VR/AR), 컴퓨터 소프트웨어, 헬스케어 정보기술(HIT), 반도체, 디스플레이 테크놀로지/가전제품 등 다양한 혁신 기술 분야에서 실리콘밸리와 국내에 설립된 기성 다국적 기업은 물론 스타트업의 IP 자산을 창출하고 보호하는데 20년 이상의 경력을 보유하고 있다. 
      또한 , 특허와 기타 IP 자산의 허가, 판매, 구매뿐만 아니라 여러 미국 법원과 미국 국제 무역 위원회에서의 IP소송에 대한 다양한 경험이 있다.`,
      hyunsuk_kang: '강현석 교수는 한국과 미국에서 모두 교육받은 의학 학자로 암을 위한 새로운 치료법과 바이오마커의 개발에 있어 임상 연구에 집중해오고 있다.',
      soh_kim: `김소형 센터장은 인천 글로벌 캠퍼스의 스탠퍼드 센터의 총괄이사이자, 스탠퍼드 대학교의 공과대학 겸임 교수이다. 
      그녀의 연구 분야는 푸드 이노베이션과 디자인이며, 스탠퍼드 대학교에서 다문화 디자인 씽킹(Design Thinking) 연구를 리드하고 있다. 
      김소형 교수는 고려대학교에서 심리학을 전공하고, 런던 정경대학교(LSE)와 스탠퍼드 대학교에서 기계공학 석사 학위를 취득한 뒤, UC버클리에서 혁신 경영학 박사 학위를 받았다.`,
    },
    footer: {
      privacy_policy: '개인정보처리방침',
      terms_of_use: '이용약관',
      contact: '문의하기',
    },
  },
};
