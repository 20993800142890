/* eslint-disable max-len */
export default {
  Tab: {
    SignIn: 'Log In',
    SignUp: 'Sign Up',
  },
  SignIn: {
    Title: 'Log In',
    TitleDesc: 'Welcome to DREAMPLUS. Please log in DREAMPLUS via Social Media.',
  },
  SignUp: {
    Title: 'Sign Up',
    TitleDesc: 'You can sign up to DREAMPLUS only through Social Media.',
  },
  SignUpStep4: {
    Title: 'Welcome to DREAMPLUS.',
    ButtonNext: 'Log in',
  },
  NaverLogin: {
    ButtonSignIn: 'Naver Login',
    ButtonSignUp: 'Naver Signup',
  },
  FacebookLogin: {
    ButtonSignIn: 'Facebook Login',
    ButtonSignUp: 'Facebook Signup',
  },
  GoogleLogin: {
    ButtonSignIn: 'Google Login',
    ButtonSignUp: 'Google Signup',
  },
  KakaoLogin: {
    ButtonSignIn: 'Kakao Login',
    ButtonSignUp: 'Kakao Signup',
  },
};
