/** contents - content */
import { container, append } from '@/common/helperUtils';

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

export default [
  {
    path: '',
    redirect: 'list',
  },
  {
    path: 'list',
    name: 'ContentsList',
    component: () => import(`@/views/contents/${appendName}ContentsList.vue`),
    meta: {
      layout: container,
      title: 'News',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: ':ncNo(\\d+)',
    name: 'ContentsDetail',
    component: () => import(`@/views/contents/${appendName}ContentsDetail.vue`),
    meta: {
      layout: container,
      title: 'News',
      metaInfo: noindexMetaInfo
    },
    props: true,
  },
  // 2024. 7. 19. 비활성화
  // {
  //   path: 'look/:ncNo(\\d+)',
  //   name: 'ContentsLook',
  //   meta: {
  //     layout: container,
  //     title: 'News',
  //   },
  //   component: () => import(`@/views/contents/${appendName}ContentsLook.vue`),
  //   props: true,
  // },
];
