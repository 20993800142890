/* eslint-disable max-len */
export default {
  list: {
    news_desc: 'We provide a variety of reading materials for DREAMPLUS and startup industry as a whole.',
    more: 'More',
    no_content: 'No Content',
    sort: {
      dates: 'sort by dates',
      popularity: 'sort by popularity',
      registrated: 'sort by registrated',
    },
  },
  detail: {
    link_popup: 'The link has been copied',
    confirm: 'Confirm',
    list_view: 'List view',
    contents: {
      related: 'Related contents',
      popular: 'Popular contents',
    },
  },
};
