/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    btn1: 'Brochure(KOR)',
    btn2: 'Brochure(ENG)',
    btn3: '',
  },
  wwa: {
    txt01: `Provide plentiful opportunities and hopes to those eager to achieve their dreams,
     thereby making their dreams come true.
     To function as an instrument that creates a sustainable and innovative virtuous circle,
     the DREAMPLUS will be a leader in creating a mutual benefit for everyone's dreams.`,
    txt02: `DREAMPLUS, a catalyst for both Open Innovation Hub and a Business Enabler,
     will support to connect diverse dreams,
     and will eventually become a brand that endeavors to change the world for the better.`,
    txt03: `For all dreamers, DREAMPLUS will be the ultimate hub where inspiration,
     growth and execution become a reality.`,
    txt04: `An innovation facilitator who encourages
    <br />numerous individuals to have dreams and hopes`,
    txt05: `Many will achieve their dreams and create mutual synergies
    <br />to create a virtuous cycle of true innovation`,
    txt06: `We offer various 'opportunities' and 'environments'
    <br /> to help people realize and innovate their dreams`,
    txt07: `The symbolic mark of DREAMPLUS describes dynamic and eternality.
     One circle creates another circle.
    <br />A new circle is separated from the original circle to grow independently.`,
    txt08: `Two circles maintain their relationship and shape their lives independently.
     It symbolizes not only the dynamics of the shape but organic life.`,
    txt09: `Motion graphic expresses movement that each life is growing to be mutually independent.
     This movement shows the optimal balance in structural and organic shape. `,
  },
  wwd: {
    txt01: `DREAMPLUS is an open innovation hub that promotes collaboration, innovation, and growth among players
     in the startup ecosystem and a business enabler that contributes to building and revitalizing
     a healthy startup ecosystem through connections between members.`,
    txt02: `We provide a variety of 'opportunities' and 'envronments'
    within the open innovation ecosystem so that the symbolic relationship
    and value of collaboration and innovation can be kept through connectivity.`,
    txt03: 'Open Innovation Platform supports cooperation and innovation by connecting dreams.',
    txt04: 'Company<br />(corporation)',
    txt05: 'Startup',
    txt06: 'Individual',
    txt07: 'Investor',
    txt08: `Others
    <br />(Government, Academia, etc.)`,
    txt09: `Establish and connect the innovation ecosystem
    <br />in order to help various members realize their dreams`,
    txt10: 'We connect dreams to facilitate cooperation and innovation and explore a new business opportunity from sustainable innovative relationship',
    txt11: 'DREAMPLUS Global hubs extend our networks across the globe and builds a more stable connectivity where the innovation centers connect and bring together various networks.',
    txt12: 'Our Accelerating programs support the entire stages of a startup\'s life cycle beginning from foundation, growth to expansion and exits.',
    txt13: 'It supports business expansion and global advancement based on the global network and works hard to create healthier innovation growth ecosystem through open partnership.',
    txt14: 'It explores investment opportunities by bridging companies and investors and offers open innovation opportunities through business linkage and collaboration project.',
    txt15: `We also provide education program (Academy) and events which are available for not only members of the startup ecosystem
     but the general public to help them open their own business opening and perform employment activities and join the new growth engine ecosystem.`,
    txt16: `After started as a startup accelerator in 2014, it has been engaged in co-working space, academy, portal,
    <br /> and share-house businesses to become as one of the most well-known brand and open innovation hub to support the growth of members of open innovation ecosystem.`,
    txt17: 'It establishes major Asian hubs (Seoul, Shanghai, Tokyo) and carries out accelerating activities in order to facilitate the growth of startups.',
    txt18: 'It opens up "63 Fintech Center" to support Fintech startup at initial stage and starts offering regular programs.',
    txt19: 'It builds the co-working office (DREAMPLUS Gangnam Center) to activate the startup ecosystem in diverse areas.',
    txt20: 'Operate academic programs to support business start-up and employment (blockchain, content, fashion&goods, F&B) with experts of each field.',
    txt21: 'It constructs the digital platform (on/off Open Innovation Hub) to provide information and opportunities to realize dreams.',
    txt22: `It operates international hubs to expand the global network
    <br />(San Francisco, Fukuoka).`,
    txt23: 'It opens and operates Share-House (DREAM HAUS) that combines learning, living, and networking together.',
    txt24: 'It operates the networking and training facility for startups.',
  },
  op: {
    txt01: `We are trying to create healthier ecosystem through open partnership between
    startups, corporation, and institutions of various areas regardless of business category, field, type, or scale.`,
    txt02: `In addition, we are supporting the growth of startups and help excellent Korean startups go abroad
    based on about 250 different global networks built by Hanwha affiliates and more than 300 startup networks.`,
  },
};
