<template>
  <button
    :type="nativeType"
    :class="classes"
    @click="onClick"
  >
    <!-- @slot 버튼 텍스트 -->
    <slot></slot>
  </button>
</template>

<script>
/**
 * 기본 Button 컴포넌트
 * - 더보기 button은 [MoreButton 컴포넌트](/#morebutton) 사용
 * @see https://github.com/creativetimofficial/vue-argon-design-system/blob/master/src/components/BaseButton.vue
 */
export default {
  name: 'BaseButton',
  props: {
    /**
     * 타입
     * @values basic, line, gray, prev, next
     */
    type: {
      type: String,
      default: 'basic',
      validator: (value) => [
        'basic',
        'line',
        'gray',
        'prev',
        'next',
      ].indexOf(value) !== -1,
    },
    /**
     * 원래 버튼 타입
     * @values button, submit
     */
    nativeType: {
      type: String,
      default: 'button',
      validator: (value) => [
        'button',
        'submit',
      ].indexOf(value) !== -1,
    },
    /**
     * 크기
     * @values large, medium, small
     */
    size: {
      type: String,
      default: 'large',
      validator: (value) => [
        'large',
        'medium',
        'small',
      ].indexOf(value) !== -1,
    },
  },
  computed: {
    classes() {
      return [
        { 'btn-basic': this.type === 'basic' },
        { 'btn-ghost': this.type === 'line' },
        { 'btn-gray': this.type === 'gray' },
        { 'btn-prev': this.type === 'prev' },
        { 'btn-next': this.type === 'next' },
        { 'btn-md': this.size === 'medium' },
        { 'btn-sm': this.size === 'small' },
      ];
    },
  },
  methods: {
    onClick(event) {
      /**
       * 클릭 이벤트
       * @event click
       * @property {Event} 이벤트 https://developer.mozilla.org/ko/docs/Web/API/Element/click_event
       */
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BaseButton @click="showAlert('저장되었습니다')">저장</BaseButton>
<BaseButton type="line" @click="showAlert('취소되었습니다')">취소</BaseButton>
<BaseButton type="gray">버튼</BaseButton>
<BaseButton disabled>비활성화된 버튼</BaseButton>
```
타입 사용 예시 :
```jsx
<BaseButton type="prev">prev</BaseButton>
<BaseButton type="next">next</BaseButton>
```

크기 사용 예시 :
```jsx
<BaseButton type="line">기본크기 버튼</BaseButton>
<BaseButton type="line" size="medium">중간크기 버튼</BaseButton>
<BaseButton type="line" size="small">작은크기 버튼</BaseButton>
```
</docs>
