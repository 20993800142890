<template>
  <div class="loading-bro">
    <svg class="load" x="0px" y="0px" viewBox="0 0 100 100">
      <circle class="loading-inner" cx="50" cy="50" r="45" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
};
</script>

<style scoped>
.loading-bro {width: 100px;height:100px;}

.load {width: 100px;animation: loading 3s linear infinite;}
.load .loading-inner {
  stroke-dashoffset: 0;
  stroke-dasharray: 200;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  animation: loading-circle 2s linear infinite;
  stroke: #ababab;
  fill: transparent;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-circle {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -400;
  }
}

@media all and (max-width:1023px) {
  .loading-bro {transform:scale(0.5);}
}
</style>
