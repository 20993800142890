import { gsap } from 'gsap/dist/gsap'; // https://greensock.com/gsap/
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'; // https://greensock.com/scrolltoplugin/
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'; // https://greensock.com/scrolltrigger/
import AOS from 'aos'; // https://github.com/michalsnik/aos
import 'aos/dist/aos.css'; // https://github.com/michalsnik/aos

// ScrollToPlugin : https://greensock.com/docs/v3/Installation?checked=core,scrollTo
// ScrollTrigger : https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export {
  gsap,
  ScrollTrigger,
  AOS,
};
