/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    btn01: '',
  },
  Fintech: {
    txt01: `드림플러스63 핀테크 센터는 한화생명을 중심으로 핀테크, 헬스케어, 웰니스 등 관련분야에서 시너지를 창출할 수 있는
    <br />스타트업 및 파트너사와의 협업과 성장을 지원하는 오픈 이노베이션 센터 입니다.`,
    txt02: `약 50개의 스타트업들이 DREAMPLUS 63 FINTECH CENTER와 함께 하였고,
    <br />양적/질적으로 빠른 성장을 이루어 낼 수 있었습니다.`,
    txt03: 'DREAMPLUS 63 FINTECH CENTER에 궁금하신 점이 있으시면 언제든지 문의 주십시오.',
    txt04: '서울특별시 영등포구 63로 50, 6층',
    txt05: '지하철역',
    txt06: '5호선 여의도역, ',
    txt07: ' 여의나루역',
    txt08: '9호선 샛강역',
    txt09: '버스정류장',
    txt10: '63빌딩',
    txt11: '가톨릭대학교 여의도 성모병원',
    txt12: '여의도동 주민센터',
  },
  Gangnam: {
    txt01: `국내 단일 최대 규모인 드림플러스 강남은 공간을 기반으로 하여 전담 액셀러레이터가 상주하여 스타트업의 성장을 지원하는
    <br />코워킹 스페이스이자 Physical Hub 입니다`,
    txt02: `드림플러스 강남에서는 대기업 혁신조직, 스타트업, 액셀러레이터 및 벤처 캐피털, 미디어 및 교육기관 등이 서로 연결되고,
    <br />오픈 이노베이션 기회를 모색하고 있습니다.`,
    txt03: '오픈 키친',
    txt04: '회의실',
    txt05: 'OA복합기',
    txt06: '폰부스',
    txt07: '스토리지',
    txt08: '유·무선인터넷',
    txt09: '퀵익스프레스',
    txt10: '여성전용휴게실',
    txt11: '주차&카셰어링',
    txt12: '샤워실',
    txt13: '3중 보안 시스템',
    txt14: '이벤트홀',
    txt15: `드림플러스 강남은 스타트업뿐만 아니라 대기업 혁신조직, VC, 정부/교육기관 등
    <br />생태계 주요 플레이어들이 한 공간에 모여 있어 빠른 연결/공유/협업 기회를 제공합니다.`,
    txt16: '필요와 용도에 따라 선택할 수 있는 다양한 사무공간과 가격 옵션을 제공 합니다.',
    txt17: '독립형 오피스',
    txt18: '417,000원',
    txt19: 'Month (VAT 별도) *20인 이상 기준',
    txt20: '업무에 집중할 수 있는 1-100인 규모의 다양한 독립형 사무공간',
    txt21: '제공시설',
    txt22: '유/무선 네트워크',
    txt23: '2가지 사이즈 책상(1,200mm, 1,400mm)',
    txt24: '개인별 서랍장, 사무용 의자',
    txt25: '고정석',
    txt26: '450,000원',
    txt27: 'Month (VAT 별도)',
    txt28: '오픈된 공용 공간에 나만의 정해진 좌석 이용이 가능한 사무공간',
    txt29: '제공시설',
    txt30: '무선 네트워크',
    txt31: '개인별 캐비닛 포함',
    txt32: '자유석',
    txt33: '350,000원',
    txt34: 'Month (VAT 별도)',
    txt35: '노트북만 있으면 자유롭게 이용이 가능한 사무공간',
    txt36: '제공시설',
    txt37: '무선 네트워크',
    txt38: '개인별 캐비닛 미포함',
    txt39: 'DREAMPLUS 강남은 언제나 여러분을 기다리고 있습니다.',
    txt40: `서울특별시 서초구 강남대로 311
    <br />311, Gangnam-daero, Seocho-gu, Seoul, Korea`,
    txt41: '지하철역',
    txt42: '2호선, 신분당선 강남역',
    txt43: '3호선, 신분당선 양재역',
    txt44: '버스정류장',
    txt45: '우성아파트',
  },
  GangnamReservation: {
    txt01: '강남센터 방문 예약',
    txt02: '환영합니다. 방문자 정보를 입력해주세요.',
    txt02_1: '강남센터 방문 예약 입력',
    txt03: '방문 예정일/시간',
    txt03_1: '방문 예정일',
    txt03_2: '방문 시간',
    txt03_3: '선택',
    txt03_4: '방문 시간을 선택해주세요.',
    txt04: '이름',
    txt04_1: '이름을 입력해주세요.',
    txt05: '회사명',
    txt05_1: '회사명을 입력해주세요.',
    txt06: '입주인원',
    txt06_1: '인원을 선택해주세요.',
    txt06_2: '1인',
    txt06_3: '2인',
    txt06_4: '4인',
    txt06_5: '6인',
    txt06_6: '8인',
    txt06_7: '10인이상',
    txt06_8: '20인이상',
    txt07: '휴대폰 번호',
    txt07_1: '-없이 숫자만 입력해주세요.',
    txt08: '이메일',
    txt08_1: '이메일을 입력해주세요.',
    txt09: '[필수] 정보수집 및 이용동의',
    txt10: `<strong>수집하는 개인 정보 항목</strong>
    <p>성명, 전화번호, 이메일</p>`,
    txt11: `<strong>개인 정보의 수집/이용 목적</strong>
    <p>드림플러스 강남센터 방문자 예약 및 회신 서비스</p>`,
    txt12: `<strong>개인 정보의 보유 및 이용 기간</strong>
    <p>귀하의 개인 정보의 보유 및 이용기간은 등록한 시점을 기준으로 1년간 보관되며, 귀하는 동의를 거절할 수 있는 권리를 보유하며 동의를 거절하는 경우 당사는 상기 “수집/이용 목적”란에 명시된 서비스를 귀하에게 제공할 수 없습니다.</p>`,
    txt13: '방문 예약하기',
    txt14: `<p>방문 예약이 접수되었습니다.</p>
    <p>예약안내는 방문 예정일 오전에 <br/> 메시지로 안내됩니다.</p>`,
    txt15: `<p>해당 시간에 방문 예약을 하실 수 없습니다.</p>
    <p>다른 시간에 예약해주세요.</p>`,
    txt16: '강남센터',
    txt17: '방문 예약',
    txt18: '입력한 모든 정보가 삭제됩니다.<br/>방문 예약을 취소하시겠습니까?',
    txt19: '예약이 완료 되었습니다.',

  },
  Global: {
    txt01: `DREAMPLUS는 해외 진출 스타트업을 위한 Global Center를 운영하고 있습니다.
    <br />미국, 일본에 이어 중국, 베트남, 인도네시아, 싱가포르 등으로 센터를 확대해 나갈 예정입니다.
    <br />또한 글로벌 기업들과의 협업 기회를 지속적으로 모색합니다.
    <br />`,
    txt02: `DREAMPLUS GLOBAL CENTER는 국내 및 해외를 연결하는 CONNECTIVE PLATFORM을 지향 합니다.
    <br />유망한 스타트업들이 국경을 초월하여 꿈을 펼쳐나갈 수 있는 글로벌 이노베이션 허브를 제공하겠습니다.`,
    txt03: `현지 진출을 위한 시장조사, 마켓 컨설팅 등
    <br />서비스를 제공 합니다.`,
    txt04: `현지 커뮤니티 및 네트워크 연결을 통해
    <br />사업 안정화를 지원합니다.`,
    txt05: `사업 확장을 위한 투자 유치 기회를
    <br />제공 합니다.`,
    txt06: '현재 운영 중인 DREAMPLUS Global Center는 다음과 같으며, 전 세계로 확장될 예정입니다.',
    txt07: '문의',
    txt08: `DREAMPLUS SAN FRANCISCO는 글로벌 이노베이션의 중심지인
    <br />미국 실리콘 밸리에 위치하고 있습니다.`,
    txt09: `미주 지역의 오픈 이노베이션 협업을 위해 현지 스타트업, 대기업, 투자자 등
    <br />다양한 기관들과 프로젝트를 진행하고 있습니다.`,
    txt10: `DREAMPLUS FUKUOKA는 콘텐츠 산업의 중심지인 일본 후쿠오카에
    <br />위치하고 있습니다.`,
    txt11: `라이프스타일, 콘텐츠, 핀테크, 헬스케어 등 다양한 영역에서
    <br />오픈 이노베이션 협업을 진행하고 있습니다.`,
    txt12: '센터 제공 혜택',
  },
};
