/* eslint-disable max-len */
export default {
  list: {
    news_desc: 'DREAMPLUS와 스타트업 업계 전반에 대한 다양한 읽을거리를 제공합니다.',
    more: '더보기',
    no_content: '등록된 콘텐츠가 없습니다.',
    sort: {
      dates: '최신순',
      popularity: '인기순',
      registrated: '등록순',
    },
  },
  detail: {
    link_popup: '링크가 복사되었습니다.',
    confirm: '확인',
    list_view: '목록보기',
    contents: {
      related: '연관 콘텐츠',
      popular: '인기 콘텐츠',
    },
  },
};
