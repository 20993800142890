import Alert from '@/components/popup/Alert.vue';
import Confirm from '@/components/popup/Confirm.vue';
import Toast from '@/components/popup/Toast.vue';
import LoadingBar from '@/components/popup/LoadingBar.vue';
import axios from 'axios'; // https://github.com/axios/axios
// import sha512 from 'sha512'; // http://cryptocoinjs.com/modules/crypto/sha512/
import { v1 as uuidv1 } from 'uuid'; // https://github.com/uuidjs/uuid
import Fingerprintjs2 from 'fingerprintjs2'; // https://github.com/Valve/fingerprintjs2
import UAParser from 'ua-parser-js'; // https://github.com/faisalman/ua-parser-js
import moment from 'moment';
import routeTypes from '@/router/routeTypes';
import MobileDetect from 'mobile-detect';
import { gsap } from './motions';
import {
  // APIs,
  FileAPIs, FilePaths, getPortalApiClient,
} from './portalApi';

/**
 * 내부 (Assets) 이미지 require.context
 * @see https://v4.webpack.js.org/guides/dependency-management/#requirecontext
 * @see https://medium.com/@godban/loading-static-and-dynamic-images-with-webpack-8a933e82cb1e
 */
const ASSETS_IMAGES_REQUIRE_CONTEXT = require.context('@/assets/images/', true);
const ASSETS_IMG_REQUIRE_CONTEXT = require.context('@/assets/img/', true);
const ASSETS_M_IMAGES_REQUIRE_CONTEXT = require.context('@/assets/m_images/', true);

const POPUP_OPTION_DEFAULT = {
  adaptive: true,
  width: 450,
  height: 'auto',
  scrollable: true,
  clickToClose: false,
};

const CONTENT_CATEGORY = {
  ALL: null,
  PEOPLE: 20101,
  "BUSINESS INSIGHT": 20102,
  TECH: 20104,
  FINANCE: 20105,
  LIFESTYLE: 20106,
}

const TOKEN_COOKIE_NAME = 'ecohubtoken';

/**
 * 기다리기
 * @param {number} 밀리초
 */
function sleep(m) {
  return new Promise((resolve) => setTimeout(resolve, m));
}

/**
 * Fingerprint (Promise)
 * @type {Promise<string>}
 */
let fingerprintPromise;

/**
 * Fingerprint 생성하기
 * @type {Promise<string>}
 */
function generateFingerprint() {
  return new Promise((resolve) => {
    Fingerprintjs2.get({
      // https://github.com/Valve/fingerprintjs2#preprocessor
      preprocessor(key, value) {
        if (key === 'userAgent') {
          // https://github.com/faisalman/ua-parser-js
          const parser = new UAParser(value);
          return `${parser.getOS().name},${parser.getOS().version},${parser.getBrowser().name},${
            parser.getDevice().vendor},${parser.getDevice().model}`;
        }
        return value;
      },
      // https://github.com/Valve/fingerprintjs2/issues/468
      audio: {
        timeout: 3000,
      },
      // https://github.com/Valve/fingerprintjs2/wiki/Stable-components
      excludes: {
        userAgent: false,
        canvas: true,
        webgl: true,
        plugins: true,
        enumerateDevices: true,
        pixelRatio: true,
        screenResolution: true,
        availableScreenResolution: true,
        doNotTrack: true,
        fontsFlash: true,
        webdriver: false, // v2.1.0부터 추가 : https://github.com/Valve/fingerprintjs2/releases/tag/2.1.0
      },
    }, (components) => {
      const find = components.map((v) => v.value);
      resolve(Fingerprintjs2.x64hash128(find.join(''), 31));
    });
  });
}

/**
 * Fingerprint 가져오기
 * @type {Promise<string>}
 */
async function getFingerprint() {
  if (fingerprintPromise === undefined) {
    fingerprintPromise = sleep(500).then(generateFingerprint);
  }

  return new Promise((resolve) => {
    fingerprintPromise.then(resolve);
  });
}

/**
 * Client Data (Promise)
 * @type {Promise<AxiosResponse>}
 */
let clientDataPromise;

/**
 * Client Data 가져오기
 * @type {Promise<>}
 */
function getClientData() {
  if (clientDataPromise === undefined) {
    clientDataPromise = axios.get('https://jsonip.com/');
  }

  return new Promise((resolve) => {
    clientDataPromise.then((res) => {
      resolve(res.data);
    });
  });
}

/**
 * 부모 element중에서 fixed position인 부모 element가 있는지 여부
 * @param {HTMLElement} e
 * @returns
 *
 * @see https://stackoverflow.com/a/43834605
 */
function isAnyFixedPositionedParents(e) {
  for (let p = e && e.parentElement; p; p = p.parentElement) {
    if (window.getComputedStyle(p).getPropertyValue('position').toLowerCase() === 'fixed') return true;
  }

  return false;
}

/**
 * 공통 Mixin
 * @see {@link https://vuejs.org/v2/guide/mixins.html Vue.js mixin}
 */
const mixin = {
  data() {
    return {
      isFooter: true,
      fixBtnHeight: 0,
    };
  },
  /**
   * beforeRouteEnter 가드
   * @see https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
   */
  beforeRouteEnter(to, from, next) {
    if (to.hash) {
      next((vm) => {
        // hash 파라미터 변경시 처리 메소드 호출
        if (vm.onChangeRouteHash) {
          vm.onChangeRouteHash(to.hash);
        }
      });
    } else {
      // 최상단으로 스크롤 이동
      // * VueRouter의 scrollBehavior에 의해서 최상단으로 스크롤 이동하나,
      //   모션(Transition) 설정 이후에 스크롤이 되어서, ScrollTrigger관련 모션이 미리 발생하는 경우가 생김
      //   그에 따라서, 라우팅전에 최상단으로 스크롤 이동하도록 함.
      window.scrollTo(0, 0);

      next();
    }
  },

  /**
   * beforeRouteUpdate 가드
   * @see https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
   */
  beforeRouteUpdate(to, from, next) {
    // hash 파라미터가 있는 경우
    if (to.hash) {
      // hash 파라미터 변경시 처리 메소드 호출
      if (this.onChangeRouteHash) {
        this.onChangeRouteHash(to.hash);
      }
    } else {
      // 최상단으로 스크롤 이동
      window.scrollTo(0, 0);
    }

    next();
  },

  methods: {
    /**
     * 내부 (Assets) 이미지 가져오기
     * @param {string} fileName
     */
    requireAssetsImage(fileName) {
      // url이 잘못된 경유 예외 처리
      if (fileName === undefined) return '';
      return ASSETS_IMAGES_REQUIRE_CONTEXT(`./${fileName}`);
    },
    /**
     * 내부 (Assets) 이미지 가져오기
     * @param {string} fileName
     */
    requireAssetsImg(fileName) {
      // url이 잘못된 경유 예외 처리
      if (fileName === undefined) return '';
      return ASSETS_IMG_REQUIRE_CONTEXT(`./${fileName}`);
    },
    /**
     * 내부 모바일 (Assets) 이미지 가져오기
     * @param {string} fileName
     */
    requireAssetsMobileImage(fileName) {
      // url이 잘못된 경유 예외 처리
      if (fileName === undefined) return '';
      return ASSETS_M_IMAGES_REQUIRE_CONTEXT(`./${fileName}`);
    },
    /**
     * 로딩 바 표시하기
     */
    showLoading() {
      this.$modal.show(LoadingBar, '', {
        name: 'loading-bar',
        adaptive: true,
        width: 100,
        height: 100,
        styles: 'box-shadow: none; background-color: transparent;',
        clickToClose: false,
      });
    },
    /**
     * 로딩 바 숨기기
     */
    hideLoading() {
      this.$modal.hide('loading-bar');
    },

    /**
     * element로 스크롤 이동
     * @param {HTMLElement} el
     * @param {number} duration
     * @param {number} offset
     * @see {@link https://greensock.com/docs/v3/Plugins/ScrollToPlugin GSAP ScrollToPlugin}
     */
    scrollTo(el, duration = 1, offset = 150) {
      gsap.to(
        window,
        {
          duration,
          scrollTo: {
            y: el,
            offsetY: offset,
          },
        },
      );
    },

    /**
     * gsap.fromTo()
     * @deprecated 대신 motions.js의 gsap.fromTo()를 사용
     */
    animateFromTo(targets, fromVars, toVars) {
      gsap.fromTo(targets, fromVars, toVars);
    },
    /**
     * gsap.from()
     * @deprecated 대신 motions.js의 gsap.from()를 사용
     */
    animateFrom(targets, vars) {
      gsap.from(targets, vars);
    },
    /**
     * gsap.to()
     * @deprecated 대신 motions.js의 gsap.to()를 사용
     */
    animateTo(targets, vars) {
      gsap.to(targets, vars);
    },

    /**
     * 팝업 표시
     * @param {*} component 팝업 컴포넌트
     * @param {string|object} name 팝업 이름 또는 팝업 컴포넌트의 props
     * @param {object} params vue-js-modal의 파라미터
     * @param {function|object} before-close 이벤트의 callback 또는 modal 이벤트 리스너
     *
     * @see {@link https://github.com/euvl/vue-js-modal vue-js-modal 문서}
     * @see {@link http://vue-js-modal.yev.io/ vue-js-modal 데모}
     */
    showPopup(component, name, params, cb) {
      let opt;
      if (typeof name === 'object') {
        opt = { ...POPUP_OPTION_DEFAULT, ...name };
      } else {
        opt = { ...POPUP_OPTION_DEFAULT, name };
      }

      params = { ...params, name: opt.name };

      switch (typeof cb) {
        case 'function': // only 'before-close' event's callback
          this.$modal.show(component, params, opt, { 'before-close': cb });
          break;
        case 'object': // events // https://github.com/euvl/vue-js-modal/issues/415#issuecomment-479468709
          this.$modal.show(component, params, opt, cb);
          break;
        default:
          this.$modal.show(component, params, opt);
      }
    },
    /**
     * 팝업 숨기기
     * @param {string} name 팝업 이름
     *
     * @see {@link https://github.com/euvl/vue-js-modal vue-js-modal 문서}
     * @see {@link http://vue-js-modal.yev.io/ vue-js-modal 데모}
     */
    hidePopup(name) {
      this.$modal.hide(name);
    },

    /**
     * Alert 표시하기
     * @param {string} message
     * @param {function} cb
     */
    showAlert(message, cb) {
      this.showPopup(
        Alert,
        'alert',
        {
          message,
        },
        cb,
      );
    },
    /**
     * Confirm 표시하기
     * @param {string} message
     * @param {function|object} cbOrParams
     */
    showConfirm(message, callbackOrParams) {
      let callback;
      let params;

      switch (typeof callbackOrParams) {
        case 'function':
          // callback
          params = { message };
          callback = callbackOrParams;
          break;
        case 'object':
          // params
          params = { ...callbackOrParams, message };
          break;
        default:
      }

      this.showPopup(
        Confirm,
        'confirm',
        params,
        (e) => {
          // 확인 (ok)
          if (e.params && callback) {
            callback();
          }
        },
      );
    },
    showToast(message, time, cb) {
      this.showPopup(
        Toast,
        {
          name: 'toast',
          classes: 'toast',
        },
        {
          message,
          time,
        },
        cb,
      );
    },
    /**
     * br치환
     * @returns
     */
    strReplace(str, pattern, text = '') {
     const reAll = new RegExp(pattern, 'gi');
     return str.replace(reAll, text);
    },
    /**
     * UUID 생성하기
     * @returns
     */
    uuid() {
      return uuidv1();
    },

    /**
     * Token 가져오기
     * @see {@link https://github.com/cmp-cc/vue-cookies vue-cookies}
     */
    getToken() {
      return this.$cookies.get(TOKEN_COOKIE_NAME);
    },

    /**
     * API 클라이언트 실패시 처리
     * @param {*} error
     *
     * @see {@link https://github.com/axios/axios#response-schema axios - Response Schema}
     * @see {@link https://github.com/axios/axios#handling-errors axios - Handling Errors}
     */
    onApiClientError(error) {
      // console.error(`Request Failed: ${error.config}`);

      if (error.response) {
        // console.error(
        //   `Status: ${error.response.status} Data: ${error.response.data} Headers: ${error.response.headers}`,
        // );

        if (error.response.status === 999 || error.response.status === 625) {
          this.showAlert(this.$t(`errorMessage.code${error.response.status}`), () => {
            this.$router.push({
              name: routeTypes.ROUTE_NAME_SIGNOUT,
            });
          });
        }
      } else {
        // console.error(`Error Message: ${error.message}`);
      }

      return Promise.reject(error.response || error.message);
    },

    /**
     * 포털 API 클라이언트
     * @param {import('axios').AxiosRequestConfig} options
     * @returns {import('axios').AxiosPromise}
     * @see {@link https://github.com/axios/axios axios}
     */
    async portalApiClient(options) {
      this.showLoading();

      const token = this.getToken();
      const fingerprint = await getFingerprint();
      const apiClient = getPortalApiClient(
        token,
        fingerprint,
        this.handlePortalApiError,
      );

      return apiClient(options)
        .catch(this.onApiClientError)
        .finally(() => {
          this.hideLoading();
        });
    },

    /**
     * 포털 API 클라이언트 (?)
     * @param {import('axios').AxiosRequestConfig} options
     * @returns {import('axios').AxiosPromise}
     * @see {@link https://github.com/axios/axios axios}
     */
    async portalHeadersApiClient(options) {
      this.showLoading();

      const fingerprint = await getFingerprint();
      const headers = {
        ...options.headers,
        curpath: this.$route.name,
        fingerprint,
      };

      return axios({
        baseURL: process.env.VUE_APP_PORTAL_API_URI,
        method: 'post',
        ...options,
        headers,
        withCredentials: true,
      })
        .catch(this.onApiClientError)
        .finally(() => {
          this.hideLoading();
        });
    },

    /**
     * 외부 API 클라이언트
     * @param {import('axios').AxiosRequestConfig} options
     * @returns {import('axios').AxiosPromise}
     * @see {@link https://github.com/axios/axios axios}
     */
    async externalApiClient(options) {
      this.showLoading();

      const headers = { ...options.headers };
      const token = this.getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      return axios({
        method: 'post',
        ...options,
        headers,
      })
        .catch(this.onApiClientError)
        .finally(() => {
          this.hideLoading();
        });
    },

    /**
     * 파일 업로드하기
     * - CDN (AWS CloudFront, S3)
     */
    async uploadFile(categoryId, columnId, parentId, file) {
      return await this.uploadCompetitionFile(categoryId, columnId, parentId, file, -1, -1, -1)
    },
    async uploadCompetitionFile(categoryId, columnId, parentId, file, competitionId, itemId, infoId) {
      const fingerprint = await getFingerprint();

      const formData = new FormData(); // https://developer.mozilla.org/ko/docs/Web/API/FormData
      formData.append('categoryId', categoryId);
      formData.append('columnId', columnId);
      formData.append('parentId', parentId);
      // formData.append('path', path);
      // formData.append('fileName', fileName);
      // formData.append('contentType', contentType);
      formData.append('file', file);
      // formData.append('finger_print', fingerprint);
      if (competitionId != -1) {
        formData.append('competitionId', competitionId);
        formData.append('itemId', itemId);
        formData.append('infoId', infoId);
      }

      if (file.size > 10481520) {
        this.showAlert(`[${file.name}]파일의 크기를 10메가 이하로 올려주세요.`);
        return;
      }

      this.showLoading();

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      };

      const token = this.getToken();
      if (token) {
        headers.Authorization = token;
      }

      return await axios({
        url: `/api2/file`,
        method: 'post',
        headers,
        // withCredentials: true,
        data: formData,
      }).then(({ data, status }) => {
        console.log('uploadFile', data, status)
        return data
      })
        .catch(this.onApiClientError)
        .finally(() => {
          this.hideLoading();
        });
    },
    /**
     * 파일 삭제하기
     * - CDN (AWS CloudFront, S3)
     */
    async removeFile(path, fileName) {
      this.showLoading();

      return this.portalApiClient({
        url: FileAPIs.REMOVE_FILE,
        data: {
          path,
          fileName,
        },
      })
        .catch(this.onApiClientError)
        .finally(() => {
          this.hideLoading();
        });
    },
    /**
     * 파일 URL 가져오기
     * - CDN (AWS CloudFront, S3)
     */
    getFileURL(path, fileName) {
      return `${process.env.VUE_APP_CDN_URL}${path}/${fileName}`;
    },
    /**
     * 미디어파일 URL 가져오기
     * - CDN (AWS CloudFront, S3)
     */
    getMediaFileURL(fileName) {
      return this.getFileURL(FilePaths.MEDIA, fileName);
    },
    /**
     * 파일 다운로드하기
     * - CDN (AWS CloudFront, S3)
     */
    downloadFile(url, name) {
      this.showLoading();
      if (window.navigator.appVersion.match('Macintosh') || window.navigator.appVersion.match('iPhone')) {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideLoading();
      } else {
        axios({
          method: 'get',
          url,
          responseType: 'blob',
        }).then(({ data }) => {
          if (document.body.classList.contains('ie')) {
            window.navigator.msSaveBlob(new Blob([data]), name);
          } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([data]), name);
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          this.hideLoading();
        });
      }
    },
    /**
     * 파일 다운로드하기
     * - CDN (AWS CloudFront, S3)
     */
    downloadFileV2(url, name) {
      axios({
        method: 'get',
        url,
        responseType: 'blob',
      }).then(({ data }) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data]), name);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    /**
     * 폼 유효성 검사 (VeeValidate)
     * @param {import('vee-validate').ValidationObserver} validationObserver
     * @return {Promise}
     * @see {@link https://logaretm.github.io/vee-validate/api/validation-observer.html ValidationObserver}
     */
    validateForm(validationObserver) {
      if (validationObserver) {
        return new Promise((resolve, reject) => {
          validationObserver.validate()
            .then((success) => {
              if (success) {
                resolve();
              } else {
                // https://github.com/logaretm/vee-validate/issues/2781 이슈로 우선 setTimeout 사용함
                setTimeout(() => {
                  const firstErrorVid = Object.keys(validationObserver.errors)
                    .find((key) => validationObserver.errors[key].length > 0);
                  if (firstErrorVid) {
                    // https://vuejs.org/v2/guide/components-edge-cases.html
                    //   #Accessing-Child-Component-Instances-amp-Child-Elements
                    const ref = Array.isArray(this.$refs[firstErrorVid])
                      ? this.$refs[firstErrorVid][0]
                      : this.$refs[firstErrorVid];
                    const target = (ref && ref.$el) || validationObserver.refs[firstErrorVid].$el;
                    this.scrollTo(target);
                  }

                  reject(new Error(firstErrorVid));
                }, 100);
              }
            });
        });
      }
      return Promise.resolve();
    },

    /**
     * Google Analytics 이벤트 전송
     * @see {@link https://developers.google.com/analytics/devguides/collection/gtagjs/events}
     */
    sendGaEvent(label, category, action = '클릭') {
      if (label) {
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
        });
      }
    },
    bodyScrollLock(v) {
      if (v === true) {
        document.body.classList.add('vm--block-scroll');
      } else {
        document.body.classList.remove('vm--block-scroll');
      }
    },
    setFooter(v) {
      this.isFooter = v;
      this.$store.commit('setIsFooter', v);
    },
    setFixedBtn(v) {
      this.fixBtnHeight = v;
      this.$store.commit('setFixBtnHeight', v);
    },
    kakaoLink(subject, imgUrl = '') {
      // eslint-disable-next-line
      Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: subject,
          imageUrl: imgUrl,
          link: {
            webUrl: window.location.href,
            mobileWebUrl: window.location.href,
          }
        }
      });
      // Kakao.Link.sendDefault({
      //   objectType: 'feed',
      //   content: {
      //     title: subject,
      //     imageUrl: imgUrl,
      //     link: {
      //       mobileWebUrl: window.location.href,
      //       webUrl: window.location.href,
      //     },
      //   },
      // });
    },
    facebookLink() {
      window.open(`http://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);
    },
    copyLink() {
      const copyIpt = document.createElement('input');
      copyIpt.value = window.location.href;
      document.body.appendChild(copyIpt);
      copyIpt.select();
      document.execCommand('copy');
      this.showAlert(this.$t('content.news.detail.link_popup'));
    },
  },
  created() {
    this.setFooter(true);
    this.setFixedBtn(0);
  },
};

/**
 * 모션 관련 Mixin
 * @deprecated
 * @see {@link https://greensock.com/docs/v3 GSAP v3}
 * @see {@link https://blog.usejournal.com/vue-js-gsap-animations-26fc6b1c3c5a Vue.js + GSAP}
 * @see {@link https://github.com/michalsnik/aos AOS}
 * @see {@link https://vuejs.org/v2/guide/mixins.html Vue.js mixin}
 */
const mixinMotion = {
  mounted() {
    // @deprecated
    // SplitTitleTransition 컴포넌트 사용으로 대체
    // gsap.from('.split-title > span', {
    //   scrollTrigger: '.split-title',
    //   duration: 1,
    //   opacity: 0,
    //   stagger: 0.1,
    //   ease: 'Back.easeOut',
    // });

    // @deprecated
    // TextUpTransition 컴포넌트 사용으로 대체
    gsap.from('.ani-text-up', {
      scrollTrigger: '.ani-text-up',
      y: 30,
      autoAlpha: 0,
      stagger: 1.4,
      duration: 1.4,
      delay: 0.5,
      ease: 'Back.easeOut',
    });

    // @deprecated
    // StaggerTransition 컴포넌트 사용으로 대체
    gsap.from('.ani-stagger > li', {
      scrollTrigger: '.ani-stagger',
      autoAlpha: 0,
      y: 50,
      stagger: 0.15,
      duration: 0.66,
      delay: 0.15,
      ease: 'Back.easeOut',
    });

    // @deprecated
    // StaggerTransition 컴포넌트 사용 (direction="right")으로 대체
    // gsap.from('.ani-stagger-right > li', {
    //   scrollTrigger: '.ani-stagger-right ',
    //   autoAlpha: 0,
    //   x: -50,
    //   stagger: 0.15,
    //   duration: 0.66,
    //   delay: 0.15,
    //   ease: 'Back.easeOut',
    // });

    // @deprecated
    // App.vue에서 처리
    // AOS.init({
    //   duration: 1200,
    //   once: true,
    //   delay: 0,
    // });
  },
};

const AUTH_REDIRECT_FROM_COOKIE_NAME = 'authRedirectFrom';

/**
 * 인증 관련 Mixin
 * @see {@link https://vuejs.org/v2/guide/mixins.html Vue.js mixin}
 */
const mixinAuth = {
  methods: {
    /**
     * 로그인/회원가입 후에 다시 돌아갈 경로(redirectFrom)를 쿠키에 저장
     * @param {string} redirectFrom
     */
    setAuthRedirectFrom(redirectFrom) {
      if (redirectFrom) {
        this.$cookies.set(AUTH_REDIRECT_FROM_COOKIE_NAME, redirectFrom, '1h'); // 쿠키는 1시간 동안 유지
      }
    },
    /**
     * 로그인/회원가입 후에 다시 돌아갈 경로(redirectFrom)를 쿠키에서 가져오고, 삭제
     * @return {string} redirectFrom
     */
    getAndRemoveAuthRedirectFrom() {
      let redirectFrom = '/'; // 디폴트는 메인 화면
      if (this.$cookies.isKey(AUTH_REDIRECT_FROM_COOKIE_NAME)) {
        redirectFrom = this.$cookies.get(AUTH_REDIRECT_FROM_COOKIE_NAME);
        this.$cookies.remove(AUTH_REDIRECT_FROM_COOKIE_NAME);
      }
      return redirectFrom;
    },
    /**
     * 로그인 (소셜 로그인)
     * @param {*} oauthUserInfo
     */
    async signIn(oauthUserInfo) {
      axios({
        method: 'POST',
        url: `/auth/sns/signin`,

        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        data: {
          email: oauthUserInfo.email,
          accessToken: oauthUserInfo.access_token,
          codeRegisterType: oauthUserInfo.apply_path,
        },
      }).then(({ data }) => {
        if (data.result) {
          const user = data.data;
          this.$store.commit('setOauthUserInfo', oauthUserInfo);
          this.$store.commit('setUserInfo', user);
          this.$cookies.set(TOKEN_COOKIE_NAME, user.jwtToken, 0, '/');
          this.$router.push(this.getAndRemoveAuthRedirectFrom());
        } else {
          this.showAlert(data.message);
          console.log('msg: ', data.message);

          if (data.code == '907') {
            this.$router.replace({
              name: routeTypes.ROUTE_NAME_SIGNUP,
            });
          } 

          if (data.code == '906') {
            this.$router.replace({
              name: routeTypes.ROUTE_NAME_SIGNIN,
            });
          } 

          // 로그인 페이지 이동


          // let SignUpStep1 = true;
          // if (data.code) {
          //   if (data.code*1 === 401) {
          //     SignUpStep1 = false;
          //     this.$router.replace({
          //       name: 'SignUpStep2',
          //       params: {
          //         userInfo: oauthUserInfo,
          //         isDuplicateEmail: ((data.code === 403)),
          //       },
          //     });
          //   }
          // }
          // if (SignUpStep1) {
          //   this.$router.replace({
          //     name: 'SignUpStep1',
          //     params: {
          //       loginStauts: data.code,
          //     },
          //   });
          // }
        }
      });
    },
    /**
     * 회원가입 (소셜 가입)
     * @param {*} oauthUserInfo
     */
    async signUp(oauthUserInfo) {
      this.portalApiClient({
        url: `/auth/sns/check`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        data: {
          email: oauthUserInfo.email,
          accessToken: oauthUserInfo.access_token,
          codeRegisterType: oauthUserInfo.apply_path,
        },
      }).then(({ data }) => {
        if (data.code * 1 === 200 || data.code === 403) {
          this.$router.replace({
            name: 'SignUpStep2',
            params: {
              userInfo: oauthUserInfo,
              isDuplicateEmail: ((data.code === 403)),
            },
          });
        } else {
          this.$router.replace({
            name: routeTypes.ROUTE_NAME_SIGNUP,
          });
          this.showAlert(data.message);
        }
      });
    },
    /**
     * 로그아웃 (소셜 로그인)
     */
    signOut() {
      const userInfo = this.$store.getters.getUserInfo();
      if (userInfo) {
        this.portalApiClient({
          url: `/auth/logout`,
          method: 'POST',
          data: {
            id: userInfo.id
          }
        }).then(({ data }) => {});
      }
      
      this.$store.commit('setOauthUserInfo', undefined);

      this.$store.commit('setUserInfo', undefined);

      this.$cookies.remove(TOKEN_COOKIE_NAME, '/');
    },
  },
};

/**
 * 형식 포멧
 *
 */
const format = {
  dateLocale(yyyymmdd, locale) {
    const date = moment(yyyymmdd);
    const day = date.day();
    if (locale === 'en') {
      const DAY_KOR = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
      return `${date.format('MM.DD')} (${DAY_KOR[day]})`;
    } else {
      const DAY_KOR = ['일', '월', '화', '수', '목', '금', '토'];
      return `${date.format('MM.DD')} (${DAY_KOR[day]})`;
    }
  },
  date(yyyymmdd, type) {
    const DAY_KOR = ['일', '월', '화', '수', '목', '금', '토'];
    const date = moment(yyyymmdd);
    const day = date.day();
    return `${date.format(type || 'MM.DD')} (${DAY_KOR[day]})`;
  },
  time(hhmm) {
    const str = String(hhmm);
    return `${str.substr(0, 2)}:${str.substr(2)}`;
  },
  money(money) {
    money = Number.isInteger(money) ? String(money) : '0';
    return money.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  /**
   * 전화번호 포맷팅 : https://cublip.tistory.com/326
   */
  phone(value) {
    if (!value) return '';
    value = value.toString();
    return value.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3').replace('--', '-');
  },
};

const md = new MobileDetect(window.navigator.userAgent);
const widthMobile = window.innerWidth < 1024;
const container = widthMobile || md.mobile() ? 'MobileContainer' : 'PcContainer';
const isMobile = !!(widthMobile || md.mobile());
const append = isMobile ? 'Mobile' : '';

/*
  if( ) {
    appendName+= "abc"
  }
*/

function getDateString(startDate, endDate, locale) {
  if (startDate) {
    startDate = startDate.replace(/\./g,'-');
  }
  if (endDate) {
    endDate = endDate.replace(/\./g,'-');
  }
  // 이벤트 기간 타이틀 문자열
  if (format.date(startDate) == format.date(endDate)) {
    return `${format.dateLocale(startDate, locale)} ${format.time(this.$moment(startDate).format('HHmm'))} 
    - ${format.time(this.$moment(endDate).format('HHmm'))}`;
  } else {
    return `${format.dateLocale(startDate, locale)} ${format.time(this.$moment(startDate).format('HHmm'))} 
    - ${format.dateLocale(endDate, locale)} ${format.time(this.$moment(endDate).format('HHmm'))}`;
  }
}

export {
  mixin, mixinMotion, mixinAuth, isAnyFixedPositionedParents,
  format, getFingerprint, getClientData, TOKEN_COOKIE_NAME, md, container, isMobile, append, CONTENT_CATEGORY, getDateString
};
