/** Programs */
import { container, append } from '@/common/helperUtils';

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

export default [
  {
    path: '',
    name: 'Programs',
    redirect: {
      path: 'programs',
      query: {
        with: 'cover', // Cover 표시
      },
    },
  },
  /** Programs > 해커톤  공모 지원하기 */
  // 2024. 7. 19. 비활성화
  // {
  //   path: 'hackathon',
  //   name: 'hackathon',
  //   meta: {
  //     layout: container,
  //     title: 'hackathon',
  //   },
  //   component: () => import(`@/views/programs/${appendName}Hackathon.vue`),
  // },
  {
    path: 'programs',
    name: 'NewPrograms',
    component: () => import(`@/views/programs/${appendName}NewPrograms.vue`),
    meta: {
      layout: container,
      title: 'WITH HANWHA | WITH PARTNER | INTERNSHIP ',
      metaInfo: noindexMetaInfo
    },
  },
  // 2024. 7. 19. 비활성화
  // {
  //   path: 'with-hanwha',
  //   name: 'ProgramsWithHanwha',
  //   component: () => import(`@/views/programs/${appendName}WithHanwha.vue`),
  //   meta: {
  //     title: 'With Hanwha | Programs',
  //   },
  // },
  // 2024. 7. 19. 비활성화
  // {
  //   path: 'with-partner',
  //   name: 'ProgramsWithPartner',
  //   component: () => import(`@/views/programs/${appendName}WithPartner.vue`),
  //   meta: {
  //     title: 'With Partner | Programs',
  //   },
  // },
  {
    path: 'to-global',
    name: 'ProgramsToGlobal',
    component: () => import(`@/views/programs/${appendName}ToGlobal.vue`),
    meta: {
      title: 'To Global | Programs',
    },
  },
  {
    path: 'academy-and-internship',
    name: 'ProgramsAcademyAndInternship',
    component: () => import(`@/views/programs/${appendName}AcademyAndInternship.vue`),
    meta: {
      title: 'Academy & Internship | Programs',
    },
  },
  {
    path: 'application',
    component: () => import('@/views/programs/application/Application.vue'),
    meta: {
      layout: container,
      title: '공모 지원하기 | Programs',
    },
    children: [
      {
        path: '',
        name: 'ProgramsApplication',
        redirect: 'list',
      },
      // /** Programs > 공모 지원하기 - 목록 */
      // {
      //   path: 'list',
      //   name: 'ProgramsApplicationList',
      //   component: () => import('@/views/programs/application/ApplicationList.vue'),
      //   meta: {
      //     layout: container,
      //   },
      //   children: [
      //     {
      //       /** Programs > 공모 지원하기 - 목록 - 탭 */
      //       path: ':accPgmMngNoString(\\d+)',
      //       props: true,
      //       name: 'ProgramsApplicationListTab',
      //       component: () => import('@/views/programs/application/ApplicationListTab.vue'),
      //       meta: {
      //         layout: container,
      //       },
      //     },
      //   ],
      // },
      /** Programs > 공모 지원하기 > 공모전 (공모지원 & 공모지원내역) */
      {
        path: ':accPgmMngNoString(\\d+)',
        props: true,
        component: () => import('@/views/programs/application/ApplicationDetail.vue'),
        meta: {
          /** 로그인 필요 */
          layout: container,
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            redirect: 'apply/form',
          },
          {
            /** Programs > 공모 지원하기 > 공모전 > 공모 지원(탭) */
            path: 'apply/form',
            name: 'ProgramsApplyForm',
            component: () => import('@/views/programs/application/apply/ApplyForm.vue'),
            meta: {
              layout: container,
              title: '공모 지원 | Programs',
            }
          },
          {
            /** Programs > 공모 지원하기 > 공모전 > 공모 지원(탭) */
            path: 'apply/completed',
            name: 'ProgramsApplyCompleted',
            component: () => import('@/views/programs/application/apply/ApplyComplete.vue'),
            meta: {
              layout: container,
            },
          },
          {
            /** Programs > 공모 지원하기 > 공모전 > 공모 지원(탭) */
            path: 'apply',
            component: () => import('@/views/programs/application/apply/Apply.vue'),
            // name: 'applyBase',
            meta: {
              layout: container,
              title: '공모 지원 | Programs',
            },
            children: [
              {
                path: '',
                name: 'ProgramsApply',
                redirect: 'step1',
              },
              {
                /** 공모 지원(탭) - 1단계 : 회사정보 */
                path: 'step1',
                name: 'ProgramsApplyStep1',
                component: () => import('@/views/programs/application/apply/ApplyStep1.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** 공모 지원(탭) - 2단계 : 멤버정보 */
                path: 'step2',
                name: 'ProgramsApplyStep2',
                component: () => import('@/views/programs/application/apply/ApplyStep2.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** 공모 지원(탭) - 3단계 : 대표서비스 */
                path: 'step3',
                name: 'ProgramsApplyStep3',
                component: () => import('@/views/programs/application/apply/ApplyStep3.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** 공모 지원(탭) - 4단계 : 투자내용 */
                path: 'step4',
                name: 'ProgramsApplyStep4',
                component: () => import('@/views/programs/application/apply/ApplyStep4.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** 공모 지원(탭) - 5단계 : 기타입력 */
                path: 'step5',
                name: 'ProgramsApplyStep5',
                component: () => import('@/views/programs/application/apply/ApplyStep5.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** 공모 지원(탭) - 6단계 : 지원서확인 */
                path: 'step6',
                name: 'ProgramsApplyStep6',
                component: () => import('@/views/programs/application/apply/ApplyStep6.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** 공모 지원(탭) - 지원 완료 */
                path: 'complete',
                name: 'ProgramsApplyComplete',
                component: () => import('@/views/programs/application/apply/ApplyComplete.vue'),
                meta: {
                  layout: container,
                },
              },
            ],
          },
          {
            /** Programs > 공모 지원하기 > 공모전 > 공모 지원 내역 (탭) */
            path: 'my-apply',
            component: () => import('@/views/programs/application/my-apply/MyApply.vue'),
            meta: {
              layout: container,
              title: '공모 지원 내역 | Programs',
            },
            children: [
              {
                path: '',
                name: 'ProgramsMyApply',
                redirect: 'list',
              },
              {
                /** Programs > 공모 > 공모전 > 공모 지원 내역 (탭) - 목록 */
                path: 'list',
                name: 'ProgramsMyApplyList',
                component: () => import('@/views/programs/application/my-apply/MyApplyList.vue'),
                meta: {
                  layout: container,
                },
              },
              {
                /** Programs > 공모 > 공모전 > 공모 지원 내역 (탭) - 지원서 보기 */
                path: ':anotherAccPgmMngNoString(\\d+)/:anotherAccNoString(\\d+)',
                props: true,
                name: 'ProgramsMyApplyDetail',
                component: () => import('@/views/programs/application/my-apply/MyApplyDetail.vue'),
                meta: {
                  layout: container,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
