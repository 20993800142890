/* eslint-disable max-len */
export default {
  common: {
    dreamplus_desc: `Dreamplus is an exclusive Open Innovation brand that provides plentiful opportunities and hopes to those who eager to achieve
    <br />their ultimate dreams, also enabling them to create synergy and virtuous cycle of sustatinable innovation.
    <br />Dreamplus will function as a catalyst that connects diverse dreams to each other, eventually becoming as a brand
    <br />that contribute to making the world for the better.`,
    signature_desc01: `The symbolic mark of Dreamplus depicts dynamic and eternality. 
    <br />A circle creates another and new created circle gradually get away
    <br />from where it was, growing as a fully independent circle.`,
    signature_desc02: `Two circles keep a distance and shape their lives respectively.
    <br />These two circles symbolize the dynamics in very stabilizing form and
    <br />the relation of inseparable lives simultaneously.`,
    innovation_ecosystem_desc: `DREAMPLUS is an open innovation hub that promotes collaboration, innovation, and growth among players in the startup ecosystem.
    <br />It also play as a business enabler that contributes to building and revitalizing a healthy startup ecosystem through connections between members.`,
    partnership_desc: `We are trying to create healthier ecosystem through open partnership between startups, corporation,
    <br />and institutions of various areas regardless of business category, field, type, or scale.`,
    btn_brochure: 'Brand Brochure',
    btn_brochure_area: 'Catalog',
    btn_domestic: 'Domestic',
    btn_global: 'Global',
  },
  provide: {
    centers_desc: 'DREAMPLUS Global hubs extend our networks across the globe and builds a more stable connectivity where the innovation centers connect and bring together various networks.',
    programs_desc: "Our Accelerating programs support the entire stages of a startup's life cycle beginning from foundation, growth to expansion and exits.",
    partnership_desc: 'It supports business expansion and global advancement based on the global network and works hard to create healthier innovation growth ecosystem through open partnership.',
    collaboration_desc: 'It explores investment opportunities by bridging companies and investors, offering open innovation opportunities through business linkage and collaboration project.',
    events_desc: 'We also provide education program (Academy) and events which are available for not only members of the startup ecosystem but the general public to help them open their own business opening and perform employment activities and join the new growth engine ecosystem.',
  },
};
