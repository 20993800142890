/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  PlayerDB: {
    txt01: '개의 플레이어가 함께 하고 있습니다.',
    txt02: '업체명을 입력해 주세요.',
    txt03: '등록된 플레이어가 없습니다.',
    txt04: '※ 2개까지 선택할 수 있습니다.',
    txt05: '※ 5개까지 선택할 수 있습니다.',
    txt06: '선택',
    txt07: '사업 분류',
    txt08: '위치',
    txt09: '대표전화',
    txt10: '홈페이지',
    txt11: '상세정보',
    txt12: '직원수',
    txt13: '금액(억원)',
    txt14: '투자업체',
    txt15: '로그인 후 확인가능한 정보입니다.',
    txt16: '대표자',
  },
  CompanyInfo: {
    txt01: '플레이어 등록 요청',
    txt02: '회사 정보',
    txt03: '대표 서비스',
    txt04: '기타 정보',
    txt05: '투자 정보',
    txt06: '정보 제공 동의',
    txt07: '모든 정보는 안전하게 저장되며, 회원 권한 별로 다른 정보가 제공됩니다.<br />해당 정보는 플레이어 디비를 이용하는 회원들과의 연락, 네트워킹, 투자, 정보 공유를 위해 사용되니 가급적 정확하고 최신 정보를 입력해주시기를 바라며 변경되는 내용이 있을 경우 업데이트 바랍니다.',
    txt08: '회사 정보 입력',
    txt09: '기업명',
    txt10: '필수 항목',
    txt11: '기업 영문명',
    txt12: '한글, 영문 각 최대 20자까지 입력 가능합니다.',
    txt13: '회사 로고',
    txt14: '회사 로고 선택',
    txt15: '이미지 파일만 가능합니다. 파일을 확인해 주세요.',
    txt16: 'JPG, JPEG, PNG, GIF 확장자의 이미지 파일만 가능하며 160*160 사이즈에 최적화되어 보입니다.<span>로고 이미지 미 등록 시, 드림플러스 기본 이미지가 표시됩니다.</span>',
    txt17: '회사 소개',
    txt18: '회사 소개 입력',
    txt19: '40자까지 입력 가능합니다.',
    txt20: '사업 분류 선택',
    txt21: '사업 분류는 필수 입력 사항입니다. 최소 1개 선택해 주세요.',
    txt22: '사업 분류는 최소 1개, 최대 2개까지 선택 가능합니다.',
    txt23: '입주 정보',
    txt24: '입주사',
    txt25: '강남',
    txt26: '졸업사',
    txt27: 'ACC 참여 정보',
    txt28: 'ACC 참여사',
    txt29: 'ACC 졸업사',
    txt30: '설립 연도',
    txt31: '연도 입력',
    txt32: '월 입력',
    txt33: '임직원 수',
    txt34: '임직원 수 선택',
    txt35: '전화번호',
    txt36: '국가 번호',
    txt37: '지역 번호',
    txt38: '이메일',
    txt39: '이메일 입력',
    txt40: '주소',
    txt41: '우편번호 입력',
    txt42: '주소 입력',
    txt43: '홈페이지',
    txt44: '홈페이지 URL 입력',
    txt45: 'SNS URL 입력',
    txt46: '페이스북 URL 입력',
    txt47: '기업명 한글 입력',
    txt48: '기업명 영문 입력',
    txt49: '전화번호를 - 없이 입력',
    txt50: '선택 없음',
    txt51: '국가',
    txt52: '도시',
    txt53: '는 필수 입력 항목입니다.',
    txt54: '사업자 등록번호',
    txt55: '사업자 등록번호 – 없이 숫자만 입력',
    txt56: '사업자 등록번호는 공개되지 않으며 사업자 등록번호가 없을 경우 000 으로 입력해 주세요.',
    txt57: '대표자',
    txt58: '대표자명 입력',
    txt59: '국가 번호 선택',
    txt60: '사업자 등록번호를 입력해 주세요.',
    txt61: '홈페이지 입력은 필수 항목입니다.',
  },
  ServiceInfo: {
    txt01: '서비스명',
    txt02: '회사명과 서비스명이 동일합니다.',
    txt03: '서비스명 한글 입력',
    txt04: '서비스명 영문 입력',
    txt05: '서비스 영문명',
    txt06: '서비스 로고',
    txt07: '서비스 로고 선택',
    txt08: '서비스 소개',
    txt09: '서비스 소개 입력',
    txt10: '서비스 분류 선택',
    txt11: '서비스 분류는 필수 입력 사항입니다. 최소 1개 선택해 주세요.',
    txt12: '서비스 분류는 최소 1개, 최대 5개까지 선택 가능합니다.',
    txt13: '다운로드',
    txt14: '애플 앱스토어 다운로드 URL 입력',
    txt15: '구글 플레이스토어 다운로드 URL 입력',
    txt16: '대표서비스 추가',
  },
  ContactInfo: {
    txt01: '대표자 추가',
    txt02: '대표자 입력',
    txt03: '대표자를 컨택포인트로 지정',
    txt04: '{name}명 입력',
    txt05: '첫번째 번호 입력',
    txt06: '가운데 번호 입력',
    txt07: '마지막 번호 입력',
    txt08: '연락처',
    txt09: '내선번호',
    txt10: '휴대폰 번호는 한화 직원에게만 공개됩니다.',
    txt11: '{name} 이메일 입력',
    txt12: '{name}명, {name} 연락처, {name} 이메일은 필수 입력 항목입니다.',
    txt13: '컨택포인트를 입력하시면 다른 플레이어와 메세지를 주고 받을 수 있습니다.',
    txt14: '받은 메세지는 당담자 My Page에서 확인 가능합니다.',
  },
  InvestmentInfo: {
    txt01: '투자 정보 입력',
    txt02: '투자 정보',
    txt03: '투자 단계',
    txt04: '년도',
    txt05: '월',
    txt06: '투자 금액 입력',
    txt07: '비공개',
    txt08: '억단위 금액으로 입력해 주세요',
    txt09: '투자 회사 입력',
    txt10: '투자 회사가 여러 곳인 경우 쉽표(,)로 구분해서 입력해 주세요.',
    txt11: '투자 정보 추가',
    txt12: '1월',
    txt13: '2월',
    txt14: '3월',
    txt15: '4월',
    txt16: '5월',
    txt17: '6월',
    txt18: '7월',
    txt19: '8월',
    txt20: '9월',
    txt21: '10월',
    txt22: '11월',
    txt23: '12월',
  },
  AgreementInfo: {
    txt01: '개인정보 수집 및 이용 동의',
    txt02: '<strong>1. 수집하는 개인 정보 항목</strong><ul><li>- 컨택포인트 담당자 이름, 연락처, 이메일 주소</li></ul><strong>2. 개인 정보의 수집/이용 목적</strong><ul><li>- 회원 간 드림플러스 플레이어 디비 서비스 이용</li></ul><strong>3. 개인 정보의 보유 및 이용 기간</strong><ul><li>- 서비스 종료 시까지</li><li>* 플레이어 디비를 삭제하는 즉시 해당 정보는 삭제됩니다.</li></ul>',
    txt03: '플레이어 디비 서비스 이용을 위한 개인정보 수집 및 이용에 동의합니다.',
    txt04: '등록요청',
  },
  PlayerRegistry: {
    txt01: '플레이어 등록 요청이 완료되었습니다.',
    txt02: '플레이어 등록은 플레이어 디비 담당자의 검토가 필요합니다.<br> 담당자 검토 후 개별 연락 드립니다.',
    txt03: 'Player DB 목록페이지로',
  },
  PlayerTemporary: {
    txt01: '임시 저장된 플레이어 저장 요청이 있습니다.',
    txt02: '임시저장된 플레이어 등록 요청을 완료한 후, <br>새로운 플레이어 등록이 가능합니다.',
  },
  Opportunities: {
    txt01: '드림플러스 멤버와 함께 사업적 성장을 위해 사업협력, 투자, 해외진출 등을 이야기하는 공간입니다.',
    txt02: '요청문의 등록',
    txt03: '분류선택',
    txt04: '제목을 입력 주세요.',
    txt05: '관리자만 보기 게시물',
    txt06: '서비스 분류',
  },
  OpportunityRegistry: {
    txt01: '요청문의 수정',
    txt02: '분류',
    txt03: '공개여부',
    txt04: '공개',
    txt05: '관리자만 보기',
    txt06: '제목',
    txt08: '제목은 필수입력 항목입니다.',
    txt09: '5개까지 선택할 수 있습니다.',
    txt10: '파일 첨부',
    txt11: '파일 선택',
    txt12: '파일첨부에 실패했습니다. 파일 용량을 확인해 주세요.',
    txt13: '파일은 3개까지 첨부 가능 하며, 파일 1개의 용량은 20MB이하로 첨부해 주세요.',
    txt14: '내용',
    txt15: '내용은 필수입력 항목입니다.',
  },
  Message: {
    txt01: '수신',
    txt02: '메시지',
    txt03: '메시지는 300자까지 입력 가능합니다.',
    txt04: '메시지를 입력해 주세요.',
    txt05: '파일 확장자를 확인해 주세요.',
    txt06: '파일 첨부에 실패했습니다. 파일 용량을 확인해 주세요.',
    txt07: 'ppt, pptx, pdf, hwp, doc, docx, xls 확장자의 문서파일만 가능합니다.',
    txt08: '파일 용량은 10MB 이하로 첨부해 주세요.',
    txt09: '보내기',
  },
};
