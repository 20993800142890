import { container } from '@/common/helperUtils';

const ChangeLocale = () => import('@/views/i18n/ChangeLocale.vue');
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

/** Redirect */
export default [
  /** 한국어 (ko, kr) */
  {
    path: '/ko',
    component: ChangeLocale,
    meta: {
      layout: container,
      toLocale: 'ko',
      metaInfo: noindexMetaInfo
    },
  },
  {
    path: '/kr',
    component: ChangeLocale,
    meta: {
      layout: container,
      toLocale: 'ko',
      metaInfo: noindexMetaInfo
    },
  },

  /** 영어 (en) */
  {
    path: '/en',
    component: ChangeLocale,
    meta: {
      layout: container,
      toLocale: 'en',
      metaInfo: noindexMetaInfo
    },
  },

  {
    path: '/signin',
    redirect: '/user/signin',
  },
  {
    path: '/signup',
    redirect: '/user/signup',
  },
  {
    path: '/signout',
    redirect: '/user/signout',
  },
  
  // 2024. 7. 18. 비활성화
  // {
  //   path: '/63',
  //   redirect: '/center/square',
  // },
  // {
  //   path: '/gangnam',
  //   redirect: '/center/gangnam',
  // },

  // {
  //   path: '/acc/fintech',
  //   redirect: '/accelerating/fintech/programs/AC101',
  // },
  // {
  //   path: '/acc/healthcare',
  //   redirect: '/accelerating/lifestyle/programs/AC102',
  // },
  // {
  //   path: '/acc/education',
  //   redirect: '/accelerating/lifestyle/programs/AC103',
  // },
  // {
  //   path: '/acc/fandb',
  //   redirect: '/accelerating/lifestyle/programs/AC104',
  // },
  // {
  //   path: '/acc/contents',
  //   redirect: '/accelerating/lifestyle/programs/AC105',
  // },
  // {
  //   path: '/acc/fashionandgoods',
  //   redirect: '/accelerating/lifestyle/programs/AC106',
  // },
  // {
  //   path: '/acc/corporate',
  //   redirect: '/accelerating/corporateacc/programs/AC107',
  // },
  // {
  //   path: '/acc/gep',
  //   redirect: '/accelerating/gep/programs/AC108',
  // },
  // {
  //   path: '/acc/gangnam',
  //   redirect: '/accelerating/acc/programs/AC109',
  // },
  // {
  //   path: '/acc/63',
  //   redirect: '/accelerating/acc/programs/AC110',
  // },

  /** CENTER
   * 2024. 7. 18. 비활성화
   */
  // {
  //   path: '/acc/*',
  //   redirect: '/programs',
  // },

  // {
  //   path: '/accelerating/*',
  //   redirect: '/programs',
  // },
];
