/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Common: {
    txt01: 'For inquqiries on partnership with DREAMPLUS or other questions, please contact us at <a href="mailto:partnership@dreamplus.asia">partnership@dreamplus.asia</a>.',
  },
  Domestic: {
    txt01: 'DREAMPLUS creates healthier ecosystem through partnerships with various startups, corporations and agencies regardless of their respective industry, sector, method or scale.',
    txt02: 'SK Holdings C&C',
    txt03: 'Synpath DHN',
    txt04: 'Kyowon',
    txt05: 'Hyundai',
    txt06: 'Oracle',
    txt07: 'Hana TI',
    txt08: 'Itshanbul',
    txt09: 'Chang\'s Inc.',
    txt10: 'Toss',
    txt11: 'Onasia',
    txt12: 'Hivoice',
    txt13: 'Doit Company Inc.',
    txt14: 'Slowalk',
    txt15: 'D\'light Law Group',
    txt16: 'KPMG',
    txt17: 'Mode Tour',
    txt18: 'Small & Medium Business Corporation',
    txt19: 'Kobaco',
    txt20: 'Wonik Investment Partners',
    txt21: 'GlobalBrain',
    txt22: 'Seoul IP',
    txt23: 'D.CAMP',
    txt24: 'Shinhan Future\'s Lab',
    txt25: 'FINOLAB',
    txt26: 'Yeongdeungpo-gu',
    txt27: 'Wibee Fintech Lab',
    txt28: 'Fintech Center Korea',
    txt29: 'Hivoice',
    txt30: 'Seum',
    txt31: 'BLT Patent & Law Firm',
    txt32: 'Mooil International IP Law Firm',
    txt33: 'Seoul Techno Holdings',
    txt34: 'Sogang Business Center',
    txt35: 'Sungkyunkwan University UNIC',
    txt36: 'Dongguk University STEP ',
    txt37: 'Dankook University Industry-University Collaboration Foundation',
    txt38: 'Soonchunhyang University Business Incubator',
    txt39: 'Chungnam University Industry-University Collaboration Foundation',
    txt40: 'Ewha Women\'s University',
    txt41: 'Hanyang University ERICA IUCF',
    txt42: 'Korean Trade Association',
    txt43: 'Business Agency',
    txt44: 'Pangyo Creation Zone',
    txt45: 'Lotte Accelerator',
    txt46: 'Bayer',
    txt47: 'Yes24',
    txt48: 'Korea University Medical Center',
    txt49: 'Sony',
    txt50: 'Pladi',
  },
  Global: {
    txt01: 'Working with more than 250 global networks and 300 startup networks, including Hanwha Group affiliates, we help startups grow and expand their business. Since 2014, we are operating the GFP together with Chungnam Creative Economy and Innovation Center to help outstanding Korean startups expand their presence in overseas markets.',
    txt02: 'Finance',
    txt03: 'Insurance, asset management, securities and investment',
    txt04: 'Service and leisure',
    txt05: 'Hotel, resort, department store, etc.',
    txt06: 'Manufacturing & construction',
    txt07: 'Chemistry, engineering, solar power, etc.',
    txt08: 'Global network',
    txt09: 'Private equity fund',
    txt10: 'Venture capital',
    txt11: 'Startup ecosystem partners',
    txt12: 'Republic of Korea',
    txt13: 'Japan',
    txt14: 'China',
    txt15: 'Vietnam',
    txt16: 'Malaysia',
    txt17: 'Other',
    txt18: 'Taiwan',
    txt19: 'Singapore',
    txt20: 'Philippines',
    txt21: 'Thailand',
    txt22: 'Indonesia',
    txt23: 'India',
    txt24: 'D.CAMP',
    txt25: 'FuturePlay',
    txt26: 'Kolon Accelerator',
    txt27: 'Koscom Accelerator',
    txt28: 'MashUp Angels',
    txt29: 'Primer',
    txt30: 'Venture Square',
  },
};
