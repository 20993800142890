import routeTypes from '@/router/routeTypes';
import { container, append } from '@/common/helperUtils';

const NaverLoginCallback = () => import('@/views/auth/NaverLoginCallback.vue');
const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

/** Auth */
export default [

  /** 네이버 아이디로 로그인 - 콜백 (로그인) */
  {
    path: routeTypes.ROUTE_PATH_NAVER_CALLBACK_SIGNIN,
    component: NaverLoginCallback,
    props: { type: 'signIn' },
  },
  /** 네이버 아이디로 로그인 - 콜백 (회원가입) */
  {
    path: routeTypes.ROUTE_PATH_NAVER_CALLBACK_SIGNUP,
    component: NaverLoginCallback,
    props: { type: 'signUp' },
  },
  {
    path: '/user',
    component: () => import(`@/views/auth/${appendName}Auth.vue`),
    props: true,
    children: [
      {
        path: '',
        redirect: 'signin',
      },
      /** 로그인 (탭) */
      {
        path: 'signin',
        name: routeTypes.ROUTE_NAME_SIGNIN,
        component: () => import(`@/views/auth/${appendName}SignIn.vue`),
        meta: {
          layout: container,
          title: '로그인',
          metaInfo: noindexMetaInfo
        },
      },
      /** 회원가입 (탭) */
      {
        path: 'signup',
        component: () => import(`@/views/auth/${appendName}SignUp.vue`),
        meta: {
          layout: container,
          title: '회원가입',
        },
        children: [
          {
            path: '',
            name: routeTypes.ROUTE_NAME_SIGNUP,
            redirect: 'step1',
          },
          {
            path: 'step1',
            name: 'SignUpStep1',
            component: () => import(`@/views/auth/${appendName}SignUpStep1.vue`),
            meta: {
              layout: container,
              metaInfo: noindexMetaInfo
            },
            props: true,
          },
          {
            path: 'step2',
            name: 'SignUpStep2',
            component: () => import(`@/views/auth/${appendName}SignUpStep2.vue`),
            meta: {
              layout: container,
              metaInfo: noindexMetaInfo
            },
            props: true,
          },
          {
            path: 'step3',
            name: 'SignUpStep3',
            component: () => import(`@/views/auth/${appendName}SignUpStep3.vue`),
            meta: {
              layout: container,
              metaInfo: noindexMetaInfo
            },
            props: true,
          },
          {
            path: 'step4',
            name: 'SignUpStep4',
            component: () => import(`@/views/auth/${appendName}SignUpStep4.vue`),
            meta: {
              layout: container,
              metaInfo: noindexMetaInfo
            },
            props: true,
          },
        ],
      },
      /** 로그아웃 */
      {
        path: 'signout',
        name: routeTypes.ROUTE_NAME_SIGNOUT,
        component: () => import(`@/views/auth/${appendName}SignOut.vue`),
        meta: {
          layout: container,
          title: '로그아웃',
          metaInfo: noindexMetaInfo
        },
      },
    ],
  },

];
