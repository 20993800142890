<template>
  <div>
    <div class="layer-wrap" style="display: block;">
      <div class="popup">
        <div class="popup-contents">
          <p
            v-html="message"
            class="message"
          ></p>
          <div class="btn-wrap">
            <template v-if="!buttons || buttons.length === 0">
              <BaseButton
                type="line"
                size="medium"
                @click="$emit('close')"
              >{{$t('content.common.button.btn05')}}</BaseButton>
              <BaseButton
                @click="ok"
                size="medium"
              >{{$t('content.common.button.btn04')}}</BaseButton>
            </template>

            <template v-for="(button, index) in buttons">
              <BaseButton
                :key="index"
                :type="button.type"
                @click="emitEvent(button.handler)"
                :class="[...button.class]"
                size="medium"
              >{{button.title}}</BaseButton>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';

/**
 * 선택(Confirm)  팝업 컴포넌트
 */
export default {
  name: 'ConfirmPopup',
  props: {
    name: {
      type: String,
      default: 'confirm',
    },
    message: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BaseButton,
  },
  methods: {
    ok() {
      this.$modal.hide(this.name, true);
    },
    emitEvent(handler) {
      this.$emit('close');
      handler();
    },
  },
};
</script>

<style scoped>
.popup.sm-pop {
  width: 460px;
  height: auto;
}
.popup.sm-pop .pop_cnt {
  padding: 40px !important;
}
.popup.sm-pop .pop_cnt .msg {
  font-size: 20px;
  color: #333;
  line-height: 26px;
}
.popup.sm-pop .btns-wrap {
  position: relative;
  text-align: center;
  bottom: 0 !important;
}

@media all and (max-width: 1023px) {
  .popup.sm-pop .pop_cnt {
    padding: 20px !important;
  }
  .popup.sm-pop .btns-wrap {
    text-align: center;
    padding-top: 0;
    margin: 20px 0 0 0;
  }
}

@media all and (max-width: 767px) {
  .popup.sm-pop {
    width: 90%;
    height: auto;
  }
  .popup.sm-pop .pop_cnt {
    padding: 20px !important;
  }
  .popup.sm-pop .pop_cnt .msg {
    font-size: 15px;
    line-height: 21px;
  }

  .popup.sm-pop .btns-wrap {
    text-align: center;
    padding-top: 0;
    margin: 20px 0 0 0;
  }
}
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 :
```jsx
<BaseButton @click="showConfirm('결정해주세요', () => showAlert('감사합니다'))">Confirm</BaseButton>
```

팝업 표시 예시 :
```jsx
<ConfirmPopup message="결정해주세요"></ConfirmPopup>
```

```jsx
<ConfirmPopup
  message="결정해주세요"
  :buttons="[
    { title: '나중에', type: 'line', handler: () => { this.$emit('close') } },
    { title: '결정', handler: () => { this.showAlert('결정하셨습니다.'); } },
  ]"
></ConfirmPopup>
```
</docs>
