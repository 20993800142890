/* eslint-disable max-len */
export default {
  common: {
    innovation_centers_desc: `드림플러스는 혁신성장 생태계의 다양한 참여자들 간의 원할한 CONNECTIVITY 구축을 위해
    <br />드림플러스 강남 및 드림플러스63을 포함한 국내는 물론 글로벌 거점인 샌프란시스코와 후쿠오카를 시작으로 글로벌 네트워크를 확장하고 있습니다.`,
    dreamplus_63_desc: `드림플러스63 핀테크 센터는 한화생명을 중심으로 핀테크, 헬스케어, 웰니스 등 관련분야에서 시너지를 창출할 수 있는
    <br />스타트업 및 파트너사와의 협업과 성장을 지원하는 오픈 이노베이션 센터 입니다.`,
    dreamplus_gangnam_desc: `드림플러스 강남센터는 국내 단일 최대 규모로 스타트업 뿐만 아니라 대기업 혁신조직, VC, 정부/교육기관 등 생태계 주요 플레이어들이
    <br />한 공간에 모여 협업 기회를 제공하여 성장을 지원하는 코워킹 스페이스입니다.`,
    go_gangnam: '드림플러스 강남센터 바로가기',
    go_63: '드림플러스 63센터 바로가기',
    go_k_startup: 'K-STARTUP TO THE WORLD PROGRAM 바로가기',
    dreamplus_global_desc: `드림플러스 GLOBAL은 해외 진출 프로그램을 통해 스타트업을 지원하고 미국, 일본에 이어 해외 주요국가 등으로
    <br />거점을 확대해 나아가며 글로벌 기업들과의 협업 기회를 지속적으로 모색합니다.`,
    m_innovation_centers_desc: `드림플러스는 혁신성장 생태계의 다양한 참여자들 간의 원할한 CONNECTIVITY 구축을 위해
    드림플러스 강남 및 드림플러스63을 포함한 국내는 물론 글로벌 거점인 샌프란시스코와 후쿠오카를 시작으로 글로벌 네트워크를 확장하고 있습니다.`,
    m_dreamplus_63_desc: `드림플러스63 핀테크 센터는 한화생명을 중심으로 핀테크, 헬스케어, 웰니스 등 관련분야에서 시너지를 창출할 수 있는
    스타트업 및 파트너사와의 협업과 성장을 지원하는 오픈 이노베이션 센터 입니다.`,
    m_dreamplus_gangnam_desc: `드림플러스 강남센터는 국내 단일 최대 규모로 스타트업 뿐만 아니라 대기업 혁신조직, VC, 정부/교육기관 등 생태계 주요 플레이어들이
    한 공간에 모여 협업 기회를 제공하여 성장을 지원하는 코워킹 스페이스입니다.`,
    m_dreamplus_global_desc: `드림플러스 GLOBAL은 해외 진출 프로그램을 통해 스타트업을 지원하고 미국, 일본에 이어 해외 주요국가 등으로
    거점을 확대해 나아가며 글로벌 기업들과의 협업 기회를 지속적으로 모색합니다.`,
    m_go_k_startup: 'K-STARTUP TO THE WORLD PROGRAM',
  },
  innovation_centers: {
    center_63: '드림플러스63<br />핀테크센터',
    center_gangnam: '드림플러스<br />강남',
    center_fukuoka: '드림플러스<br />후쿠오카',
    center_sanfrancisco: '드림플러스<br />샌프란시스코',
    center_beijing: '드림플러스<br />베이징',
  },
  global_centers: {
    senfrancisco: `드림플러스 샌프란시스코는 미주 지역의 오픈 이노베이션
    <br />협업을 위해 현지 스타트업, 대기업, 투자자 등 다양한
    <br />기관들과 프로젝트를 진행하고 있습니다.`,
    m_senfrancisco: '드림플러스 샌프란시스코는 미주 지역의 오픈 이노베이션 협업을 위해 현지 스타트업, 대기업, 투자자 등 다양한 기관들과 프로젝트를 진행하고 있습니다.',
    fukuoka: `드림플러스 후쿠오카는 라이프스타일, 콘텐츠, 핀테크,
    <br />헬스케어 등 다양한 영역에서 오픈 이노베이션 협업을
    <br />진행하고 있습니다.`,
    m_fukuoka: '드림플러스 후쿠오카는 라이프스타일, 콘텐츠, 핀테크, 헬스케어 등 다양한 영역에서 오픈 이노베이션 협업을 진행하고 있습니다.',
    beijing: `드림플러스 베이징은 국내 스타트업의 중국 진출 지원
    <br />및 한국에 투자를 원하는 중국 VC들을 연결하고
    <br />있습니다.`,
    m_beijing: '드림플러스 베이징은 국내 스타트업의 중국 진출 지원 및 한국에 투자를 원하는 중국 VC들을 연결하고 있습니다.',

  },
};
