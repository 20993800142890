/* eslint-disable max-len */
export default {
  Tab: {
    SignIn: '로그인',
    SignUp: '회원가입',
  },
  SignIn: {
    Title: '로그인',
    TitleDesc: '드림플러스에 오신 것을 환영합니다. SNS를 통해 드림플러스에 로그인해 주세요.',
  },
  SignUp: {
    Title: '회원가입',
    TitleDesc: '드림플러스는 SNS로만 회원가입 하실 수 있습니다.',
  },
  SignUpStep4: {
    Title: '드림플러스 가입을 환영합니다.',
    ButtonNext: 'Login',
  },
  NaverLogin: {
    ButtonSignIn: '네이버 로그인',
    ButtonSignUp: '네이버 회원가입',
  },
  FacebookLogin: {
    ButtonSignIn: '페이스북 로그인',
    ButtonSignUp: '페이스북 회원가입',
  },
  GoogleLogin: {
    ButtonSignIn: '구글 로그인',
    ButtonSignUp: '구글 회원가입',
  },
  KakaoLogin: {
    ButtonSignIn: '카카오 로그인',
    ButtonSignUp: '카카오 회원가입',
  },
};
